import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import ReactFlow, { Handle } from 'reactflow';
import FlowchartSection1 from './MERPTemplate/FlowchartSection1';
import FlowchartSection2 from './MERPTemplate/FlowchartSection2';
import FlowchartSection3 from './MERPTemplate/FlowchartSection3';
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
const CustomNode1 = ({ data }) => (
  <div
    style={{
      padding: 10,
      border: '1px solid #777',
      borderRadius: 5,
      background: '#fff',
    }}
  >
    <div>{data.value}</div>
    <Handle type="source" position="left" />
    <Handle type="target" position="right" />
  </div>
);

const nodeTypes1 = {
  customNode: CustomNode1,
};

const CustomNode2 = ({ data }) => (
  <div
    style={{
      padding: 10,
      border: '1px solid #777',
      borderRadius: 5,
      background: '#fff',
    }}
  >
    <div>{data.value}</div>
    <Handle type="source" position="left" />
    <Handle type="target" position="right" />
  </div>
);

const nodeTypes2 = {
  customNode: CustomNode2,
};

export default function MERPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);
  const [downloadingPosters, setDownloadingPosters] = React.useState(false);

  const handleDownload = (name) => {
    let path = `/documents/${name}`;
    window.electron.ipcRenderer.downloadFile(path);
  };

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF('p', 'px', [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          '',
          'FAST'
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Medical-Emergency-Response-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Medical-Emergency-Response-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const exportPosters = async () => {
    setDownloadingPosters(true);
    const downloadPoster = async (posterId) => {
      const input = document.getElementById(posterId);
      console.log(`Poster element with ID ${posterId}:`, input); // Debugging line
      if (!input) {
        console.error(`Element with ID ${posterId} not found.`);
        return;
      }
      return html2canvas(input, { useCORS: true }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        const fileName = `Poster-${posterId}`;
        link.download = `${fileName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    };

    await downloadPoster('combinedPoster');
    setDownloadingPosters(false);
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== 'undefined') {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === 'undefined') {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    accountability,
    emergencyContacts,
    firstAidEquipment,
    medicalFacilities,
    medicalFacilitiesMapping,
    medicalEvacuation,
    medevac,
    insurance,
    medicalEmergency,
    showFlowchart2,
    diplomaticResources,
    hrDocumentation,
    personnelDoc,
    localHealthRisks,
    annexes,
  } = assesmentResponse;

  const Tabledata = [
    {
      docname: 'Medical Evacuation Guidelines',
      file: 'MEDEVACGuidelines.docx',
    },
    {
      docname: 'First Aid Guidelines',
      file: 'FirstAidGuidelines.docx',
    },
  ];

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{'Medical-Emergency-Response'}-
            {planInformation?.agency?.value || planInformation?.agency || ''}-
            {planInformation?.countryFacility || ''}-
            {moment(assesment.assessmentDate).format('DD-MM-YYYY') || ''}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button onClick={exportPdf}>
            Download PDF{' '}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{' '}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
          &nbsp;
          <Button onClick={exportPosters}>
            Download Poster
            {downloadingPosters && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
          &nbsp;
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>
                      PREMISES SECURITY PLAN (MEDICAL EMERGENCY RESPONSE):
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Country</td>
                    <td>{planInformation?.country}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Current Version::</td>
                    <td>{planInformation?.currentVersion}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>INTRODUCTION</h4>
              <Col xs={12} className="mt-4">
                <table className="w-full colored-table summary-table half-table">
                  <thead>
                    {/* <tr>
                    <th colSpan={2}></th>
                  </tr> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        This Medical Emergency Response Plan (MERP) details
                        roles, responsibilities and actions of in the event of a
                        medical emergency.
                      </td>
                      <td>{introduction?.introduction}</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </Col>
              <div className="mt-2">
                <h4>Purpose</h4>
                <p className="mb-2">
                  The aim of the MERP is to capture relevant details required to
                  assist in the management of a medical emergency and lay out
                  all the steps that should be taken when a medical emergency
                  arises i.e., it is a roadmap for how to transport a patient
                  from their point of injury or illness to a definitive care
                  facility.
                </p>
              </div>
              <div>
                <h4>Revisions</h4>
                <p className="mb-2">
                  The MERP is a “living” document and should be routinely
                  reviewed and revised as necessary (at minimum every six months
                  and after any medical emergency involving AKDN staff).
                </p>
              </div>
              <div>
                <h4>Application</h4>
                <p className="mb-2">
                  This plan is applicable to all AKDN staff, local staff during
                  work hours, volunteers, consultants and any personnel
                  operating under the Agency umbrella in the operational area.
                  For the purposes of this plan, the term “staff” is used to
                  refer to the above personnel. This plan should be taken into
                  immediate use.
                </p>
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>ACCOUNTABILITY AND RESPONSIBILITY</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '35%' }}>
                      <b>Responsibility</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accountability &&
                    accountability.map(
                      (item, index) =>
                        (item.designation ||
                          item.name ||
                          item.contact ||
                          item.responsibilities) && (
                          <tr>
                            <td>{item?.designation}</td>
                            <td>{item?.name}</td>
                            <td>{item?.contact}</td>
                            <td>{item?.responsibilities} </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>EMERGENCY CONTACTS </h4>
              <h4>Internal Organisational Support </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Additional Info</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts.fFAiders.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td>Facility First Aiders</td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                  {emergencyContacts.keyStaff.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td> Key Staff </td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                  {emergencyContacts.transportationResources.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td> Transportation Resources</td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>External Medical Support</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Information</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts.ambulance.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td> Ambulance Services</td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                  {emergencyContacts.emergencyServices.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td> Other Emergency Services</td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                  {emergencyContacts.transportationSupport.map(
                    (item, index) =>
                      (item.name || item.contact || item.additionalinfo) && (
                        <tr key={index}>
                          <td> Other Transportation Support</td>
                          <td>{item.name}</td>
                          <td>{item.contact}</td>
                          <td>{item.additionalinfo}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <div id="combinedPoster">
                <div id="poster1">
                  <h4>FACILITY FIRST AID </h4>
                  <h4>First Aid Equipment</h4>
                  <table className="w-full colored-table summary-table">
                    <thead>
                      <tr>
                        <th style={{ width: '33%' }}>
                          <b>First Aid Equipment</b>
                        </th>
                        <th style={{ width: '33%' }}>
                          <b>Location</b>
                        </th>
                        <th style={{ width: '33%' }}>
                          <b>Comment</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {firstAidEquipment.map(
                        (item, index) =>
                          (item.equipment || item.location || item.comment) && (
                            <tr key={index}>
                              <td>{item.equipment}</td>
                              <td>{item.location}</td>
                              <td>{item.comment}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
                <div id="poster2">
                  <h4>Facility First Aiders</h4>
                  <table className="w-full colored-table summary-table">
                    <thead>
                      <tr>
                        <th style={{ width: '25%' }}>
                          <b>Designation</b>
                        </th>
                        <th style={{ width: '25%' }}>
                          <b>Name </b>
                        </th>
                        <th style={{ width: '25%' }}>
                          <b>Contact Number </b>
                        </th>
                        <th style={{ width: '25%' }}>
                          <b>Additional Info </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {emergencyContacts.fFAiders.map(
                        (item, index) =>
                          (item.name ||
                            item.contact ||
                            item.additionalinfo) && (
                            <tr key={index}>
                              <td>First Aiders</td>
                              <td>{item.name}</td>
                              <td>{item.contact}</td>
                              <td>{item.additionalinfo}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Medical Facilities </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Map reference</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Facility Name </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Address</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Grid (MGRS, Lat/Long)</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Emergency Contact Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Capability</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {medicalFacilities.map(
                    (item, index) =>
                      (item.facilityName ||
                        item.address ||
                        item.grid ||
                        item.emergencyContact ||
                        item.capability) && (
                        <tr key={index}>
                          <td>{String.fromCharCode(65 + index)}</td>
                          <td>{item.facilityName}</td>
                          <td>{item.address}</td>
                          <td>{item.grid}</td>
                          <td>{item.emergencyContact}</td>
                          <td>{item.capability}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Medical Facilities Mapping</h4>
              <table className="w-full colored-table summary-table">
                <thead></thead>
                <tbody>
                  <tr>
                    <td>
                      {' '}
                      <img
                        style={{
                          width: '100%',
                          height: 'auto',
                        }}
                        src={medicalFacilitiesMapping?.MedicalFacilitiesMap}
                      ></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>MEDICAL EVACUATION</h4>
              <h4>MEDEVAC Providers </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Emergency Contact Information </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Policy Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Additional Info</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {medevac.map(
                    (item, index) =>
                      (item.designation ||
                        item.emergencyContact ||
                        item.policyNumber ||
                        item.additionalInfo) && (
                        <tr key={index}>
                          <td>{item.designation}</td>
                          <td>{item.emergencyContact}</td>
                          <td>{item.policyNumber}</td>
                          <td>{item.additionalInfo}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>Insurance Providers</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Emergency Contact Information </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Policy Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>MEDEVAC Provider </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Additional Info</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {insurance.map(
                    (item, index) =>
                      (item.designation ||
                        item.emergencyContact ||
                        item.policyNumber ||
                        item.medevacProvider ||
                        item.additionalInfo) && (
                        <tr key={index}>
                          <td>{item.designation}</td>
                          <td>{item.emergencyContact}</td>
                          <td>{item.policyNumber}</td>
                          <td>{item.medevacProvider}</td>
                          <td>{item.additionalInfo}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>MEDEVAC Process </h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  {/* <tr>
                    <th colSpan={2}></th>
                  </tr> */}
                </thead>
                <tbody>
                  <tr>
                    <td>The process to initiate MEDEVAC for is as follows:</td>
                    <td>{medicalEvacuation?.agency}</td>
                  </tr>
                  <tr></tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-2">
              <h4>MEDICAL EMERGENCY PROCESS </h4>
              <FlowchartSection1
                medicalEmergency={medicalEmergency}
                nodeTypes1={nodeTypes1}
              />
              {showFlowchart2 && (
                <FlowchartSection2
                  medicalEmergency={medicalEmergency}
                  nodeTypes2={nodeTypes2}
                />
              )}
              <FlowchartSection3 />
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>ADDITIONAL INFORMATION</h4>
              <h4>Diplomatic Resources </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Embassy / Consulate </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Contact Info.</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Level of Support</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Comment </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {diplomaticResources.map(
                    (res, index) =>
                      (res.embassy ||
                        res.contactInfo ||
                        res.levelOfSupport ||
                        res.comment) && (
                        <tr key={index}>
                          <td>{res.embassy}</td>
                          <td>{res.contactInfo}</td>
                          <td>{res.levelOfSupport}</td>
                          <td>{res.comment}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>HR Documentation</h4>
              <p>
                All personnel documentation is managed and located as follows:{' '}
              </p>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Custodian Name</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Department</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Location of files</b>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {hrDocumentation.map(
                    (doc, index) =>
                      (doc.custodianName ||
                        doc.department ||
                        doc.contactNumber ||
                        doc.location) && (
                        <tr key={index}>
                          <td>{doc.custodianName}</td>
                          <td>{doc.department}</td>
                          <td>{doc.contactNumber}</td>
                          <td>{doc.location}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>Personnel Documentation</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th>
                      The following HR documentation is held for all staff:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {personnelDoc?.map(
                    (item, index) =>
                      item.doc && (
                        <tr>
                          <td>{item.doc}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-2">
              <h4>Local Health Risks </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Type</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Signs and Symptoms </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Prevention</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Treatment</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {localHealthRisks.map(
                    (risk, index) =>
                      (risk.type ||
                        risk.signsAndSymptoms ||
                        risk.prevention ||
                        risk.treatment) && (
                        <tr key={index}>
                          <td>
                            {risk.type === 'OTHER'
                              ? `${risk.customType} (Other)`
                              : risk.type}
                          </td>
                          <td>{risk.signsAndSymptoms}</td>
                          <td>{risk.prevention}</td>
                          <td>{risk.treatment}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Tabledata &&
                    Tabledata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.docname}</td>
                        <td>
                          <i
                            style={{ fontSize: '20px', marginLeft: '10px' }}
                            role="button"
                            className={`la la-download me-2`}
                            onClick={() => handleDownload(item.file)}
                          />
                        </td>
                      </tr>
                    ))}
                  {annexes.annexes &&
                    annexes.annexes.map(
                      (ann, index) =>
                        (ann.documentName || ann.location) && (
                          <tr key={index}>
                            <td>{index + 4}</td>
                            <td>{ann.documentName}</td>
                            <td>{ann.location}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
