import moment from "moment";
import React, { useMemo, useState } from "react";
import { Badge, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import swal from "sweetalert";
import {
  getAssesmentsCompleted,
  SaveAssesment,
} from "../../services/AssesmentService";
import { getApplicationUsers } from "../../services/UserService";
import PageTitle from "../layouts/PageTitle";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
import { getModifiedData } from "../../utils/completedUtils";

export const AssesmentCompleted = () => {
  const [assesments, setAssesments] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [addCard, setAddCard] = React.useState(false);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "assesmentUser",
        Cell: ({ value }) => {
          if (value) return <span>{value.name}</span>;
          else return "";
        },
      },
      {
        Header: "Template",
        accessor: "assessmentTemplate",
        Cell: ({ value }) => {
          if (value) return <span>{value.name}</span>;
          else return "";
        },
      },
      {
        Header: "Completion Date",
        accessor: "assessmentDate",
        Cell: ({ value }) => {
          if (value) return <span>{moment(value).format("DD-MM-YYYY")}</span>;
          else return "";
        },
      },
      {
        Header: "Action",
        accessor: "id",
        Cell: (data) => {
          const { value } = data;
          return (
            <span>
              <Badge variant="success color-white">
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "1" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "2" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary2/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "3" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary3/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "5" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary5/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "6" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary6/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "7" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary7/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "8" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary8/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "9" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary9/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                  {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "10" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary10/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                  {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "11" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary11/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                  {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "12" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary12/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                  {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "13" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary13/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
                  {data.row.original &&
                  data.row.original.assessmentTemplate &&
                  data.row.original.assessmentTemplate.ID === "14" && (
                    <div className="d-flex">
                      <Link
                        style={{ color: "#fff" }}
                        to={{
                          pathname: `/assesmentSummary14/${data.row.original.assessmentTemplate.ID}/${data.row.original.id}`,
                          state: { assesment: data.row.original },
                        }}
                      >
                        View
                      </Link>
                    </div>
                  )}
              </Badge>
              &nbsp;
              <Badge
                onClick={(e) => handleEditClick(e, value)}
                variant="primary color-white pointer"
              >
                Edit Ownership
              </Badge>
            </span>
          );
        },
      },
    ],
    [assesments]
  );
  const data = useMemo(() => [...assesments], [assesments]);

  const getAssesmentCompleted = () => {
    getAssesmentsCompleted().then((data) => {
      setAssesments(data);
    });
  };

  React.useEffect(() => {
    getAssesmentCompleted();
    getApplicationUsers().then((res) => setUsers(res.results));
  }, []);

  const tableInstance = useTable(
    {
      columns,
      data: assesments,
    },
    useGlobalFilter,
    useSortBy
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    state,
  } = tableInstance;

  const { globalFilter } = state;

  const [editAssesmentForm, setEditAssesmentForm] = React.useState({});
  const [editContentId, setEditContentId] = React.useState(undefined);

  const handleSelectChange = (field, value) => {
    const newFormData = { ...editAssesmentForm };
    newFormData[field] = value;
    setEditAssesmentForm(newFormData);
  };

  const handleEditClick = (event, value) => {
    const content = assesments.find((assesment) => assesment.id === value);
    event.preventDefault();
    setEditContentId(content.id);
    setEditAssesmentForm(content);
    setAddCard(true);
  };

  const handleupdatedFormSubmit = (e) => {
    e.preventDefault();
    const body = {
      ...editAssesmentForm,
      assesmentUser: editAssesmentForm.assesmentUser.id,
      assessmentTemplate: editAssesmentForm.assessmentTemplate._id,
    };
    SaveAssesment(editContentId, body).then(() => {
      setAddCard(false);
      swal("Success", "Ownership Updated successfully", "success");
      getAssesmentCompleted();
    });
  };

  return (
    <>
      <PageTitle activeMenu="Assesment Completed" motherMenu="Assesment" />
      <Modal className="modal fade" show={addCard} onHide={setAddCard}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Change Ownership</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setAddCard(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">User</label>
                      <div className="contact-name">
                        <ReactSelect
                          name="agency"
                          fieldName="agency"
                          onChange={(e) =>
                            handleSelectChange("assesmentUser", e)
                          }
                          options={users}
                          value={editAssesmentForm.assesmentUser}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleupdatedFormSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setAddCard(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Assesment Completed</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
              {assesments && assesments.length > 0 ? (
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                            <span className="ml-1">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-arrow-up" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* This is only for footer if u require */}
                  <tfoot>
                    {footerGroups.map((footerGroup) => (
                      <tr {...footerGroup.getFooterGroupProps()}>
                        {footerGroup.headers.map((column) => (
                          <td {...column.getFooterProps()}>
                            {column.render("Footer")}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              ) : (
                "No data found"
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssesmentCompleted;
