import React from "react";

const category = [
  "Armed conflict",
  "Terrorism",
  "Crime",
  "Civil unrest",
  "Hazard/Natural Disaster",
  "Other",
];
const threatType = [
  "Abduction/hostage taking",
  "Armed incident – AKDN / Jamat targeted",
  "Armed incident – incidental",
  "Arrest/detention",
  "Arson",
  "Assault",
  "Burglary",
  "Facility intrusion",
  "Harassment/intimidation/threat of violence",
  "Hostile surveillance or reconnaissance",
  "Missing person",
  "Movement restriction",
  "Public gathering – non-violent",
  "Public gathering – violent",
  "Robbery",
  "Seizure",
  "Sexual assault",
  "Theft",
  "Vandalism",
  "Accident – aircraft",
  "Accident – fire",
  "Accident – other transportation",
  "Accident – vehicular",
  "Accidents – other",
  "Earthquake",
  "Flood",
  "Landslide/mudslide/avalanche",
  "Destructive weather system",
  "Tsunami",
  "Volcanic eruption",
  "Industrial disaster",
  "Explosive remnant of war",
  "Medical Epidemic",
  "Medical Emergency",
  "Other",
];
export default function ThreatSummary({
  threat,
  removeThreat,
  addNewThreat,
  updateThreat,
  updateThreatperformanceIndex,
  disabled,
}) {
  if (!threat) {
    return <></>;
  }

  if (!threat) {
    return <></>;
  }
  const getTotal = (item) => {
    const { performanceIndex } = item;
    const showLastThree =
      item.Category &&
      !(
        item.Category.value === "Armed conflict" ||
        item.Category.value === "Terrorism" ||
        item.Category.value === "Crime" ||
        item.Category.value === "Civil unrest"
      );
    if (showLastThree) {
      return (
        parseInt(performanceIndex.Severity) +
        parseInt(performanceIndex.History) +
        parseInt(performanceIndex.EarlyWarningAndPreparedness)
      );
    }
    return (
      parseInt(performanceIndex.Capability) +
      parseInt(performanceIndex.History) +
      parseInt(performanceIndex.Intention)
    );
  };

  return (
    <>
      <div className="col-xl-12 col-lg-12">
        {!disabled && (
          <button className="btn btn-primary ml-25" onClick={addNewThreat}>
            <i className="la la-plus" /> &nbsp; Add New Threat
          </button>
        )}
      </div>
      <div className="col-xl-12 col-lg-12">
        <div className="row">
          <div className="form-group mb-3 col-md-12">
            <table className="min-w-full w-full colored-table user_detail  layout-fixed">
              <tbody>
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Category
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Capability
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    History
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Intention
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    History (Hazard)
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Intensify / Severity (Hazard)
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Early Warning or Preparedness (Hazard)
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4 text-left border-r"
                  >
                    Total
                  </th>
                </tr>
                {threat.map((item, index) => {
                  const showLastThree =
                    item.Category &&
                    !(
                      item.Category.value === "Armed conflict" ||
                      item.Category.value === "Terrorism" ||
                      item.Category.value === "Crime" ||
                      item.Category.value === "Civil unrest"
                    );

                  return (
                    <tr
                      key={`Threat_summary_New_${index}`}
                      className="border-b border-r"
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {item.Type.value}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap border-r">
                        {item.Category.value}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {!showLastThree && item.performanceIndex.Capability}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {!showLastThree && item.performanceIndex.History}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {!showLastThree && item.performanceIndex.Intention}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {showLastThree && item.performanceIndex.History}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {showLastThree && item.performanceIndex.Severity}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {showLastThree &&
                          item.performanceIndex.EarlyWarningAndPreparedness}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 border-r">
                        {getTotal(item)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <br /> <br />
          </div>
        </div>
      </div>
    </>
  );
}
