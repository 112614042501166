import React from "react";
import { Alert, Col, Nav, Row, Tab } from "react-bootstrap";

export default function ImpactSummary({ threadData, updateImpact }) {
  const getTotalScore = (Impact) => {
    if (Impact === undefined) {
      return 0;
    }
    let score = 0;
    Object.keys(Impact).forEach((key) => {
      if (!Number.isNaN(parseInt(Impact[key]))) {
        score += parseInt(Impact[key]);
      }
    });
    return score;
  };

  const getPerformanceText = (Impact) => {
    const score = parseInt(Impact);
    if (score === 1) {
      return "Negligible";
    }
    if (score === 2) {
      return "Minor";
    }
    if (score === 3) {
      return "Moderate";
    }
    if (score === 4) {
      return "Severe";
    }
    return "Very Critical";
  };

  const getbackgroundClass = (Impact) => {
    const score = getTotalScore(Impact);
    if (score <= 6) {
      return "bg-green-400";
    }
    if (score === 7) {
      return "bg-blue-400";
    }
    if (score === 8) {
      return "bg-yellow-400";
    }
    if (score > 8 && score <= 12) {
      return "bg-orange-400";
    }
    return "bg-red-400";
  };
  return (
    <Row>
      <Col xs={12}>
        <table className="min-w-full w-full table colored-table user_detail">
          <tbody>
            <tr>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Threat Description
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Type
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Category
              </th>

              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                People
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Information
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Property(Asset)
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Capability
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Economic
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Reputation
              </th>
            </tr>
            {threadData.map((data, index) => (
              <tr key={`impact_summary_${index}`} className="border-b">
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {data.Description}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {data.Type.value}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                  {data.Category.value}
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.People)}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.Information)}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.Property)}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.Capability)}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.Economic)}
                      </label>
                    </div>
                  </div>
                </td>
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  <div>
                    <div className="form-check">
                      <label className="form-check-label inline-block text-gray-800">
                        {getPerformanceText(data.Impact.Reputation)}
                      </label>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
