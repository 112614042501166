import axios from "axios";
import { BACKEND_URL } from "../baseUrl";
import { store } from "../store/store";

const axiosInstance = axios.create({
  baseURL: BACKEND_URL,
});

axiosInstance.interceptors.request.use((config) => {
  if (config.url.includes("v1/")) {
    const state = store.getState();
    const token = state.auth.auth.idToken;
    config.headers["Authorization"] = `bearer ${token}`;
  }
  return config;
});

export default axiosInstance;
