import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";

//Redux
import Users from "./pages/Users";
import ReduxForm from "./components/Forms/ReduxForm/ReduxForm";
/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import { ThemeContext } from "../context/ThemeContext";
import AssesmentTemplate from "./pages/AssesmentTemplate";
import Assesment from "./pages/Assesment";
import { AssesmentCompleted } from "./pages/AssesmentCompleted";
import ContactQuery from "./pages/ContactQuery";
import AssesmentSummary from "./pages/AssesmentSummary";
import AppSetting from "./components/Forms/ReduxForm/AppSetting";
import Asssesment2Summary from "./pages/AssesmentSummary2";
import Asssesment3Summary from "./pages/Assesment3Summary";
import Assessment4Summary from "./pages/Assesment4Summary";
import Assessment5Summary from "./pages/Assesment5Summary";
import Assessment6Summary from "./pages/Asssesment6Summary";
import Assessment7Summary from "./pages/Assesment7Summary";
import PSPPlanSummary from "./pages/PSPlanSummary";
import ContingencyPlanSummary from "./pages/ContingencyPlanSummary";
import Assesment5Summary from "./pages/Assesment5Summary";
import Assesment7Summary from "./pages/Assesment7Summary";
import FireSafetyPlanSummary from "./pages/FireSafetyPlanSummary";
import HREPlanSummary from "./pages/HREPlanSummary";
import JourneyManagementPlanSummary from "./pages/JourneyManagementPlanSummary";
import LockdownPlanSummary from "./pages/LockdownPlanSummary";
import MERPlanSummary from "./pages/MERPlanSummary";


const Markup = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Users },
    { url: "dashboard", component: Users },
    ///Redux
    { url: "users", component: Users },
    { url: "contact-form", component: ReduxForm },
    { url: "assesment/completed", component: AssesmentCompleted },
    { url: "assement/assigned", component: Assesment },
    { url: "assementTemplate", component: AssesmentTemplate },
    { url: "contact-query", component: ContactQuery },
    { url: "app-setting", component: AppSetting },
    { url: "assesmentSummary/:assesmentId", component: AssesmentSummary },
    { url: "assesmentSummary2/2/:assesmentId", component: Asssesment2Summary },
    { url: "assesmentSummary3/3/:assesmentId", component: Asssesment3Summary },
    { url: "assesmentSummary4/4/:assesmentId", component: Assessment4Summary },
    { url: "assesmentSummary5/5/:assesmentId", component: Assesment5Summary },
    { url: "assesmentSummary6/6/:assesmentId", component: Assessment6Summary },
    { url: "assesmentSummary7/7/:assesmentId", component: Assesment7Summary },
    { url: "assesmentSummary8/8/:assesmentId", component: PSPPlanSummary },
    {
      url: "assesmentSummary9/9/:assesmentId",
      component: ContingencyPlanSummary,
    },
    {
      url: "assesmentSummary10/10/:assesmentId",
      component: FireSafetyPlanSummary,
    },
    {
      url: "assesmentSummary11/11/:assesmentId",
      component: LockdownPlanSummary,
    },
    {
      url: "assesmentSummary12/12/:assesmentId",
      component: JourneyManagementPlanSummary,
    },
    {
      url: "assesmentSummary13/13/:assesmentId",
      component: MERPlanSummary,
    },
    {
      url: "assesmentSummary14/14/:assesmentId",
      component: HREPlanSummary,
    }
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default Markup;
