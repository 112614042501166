import axiosInstance from "./AxiosInstance";

export function getApplicationUsers() {
  return axiosInstance.get(`v1/users`).then((res) => res.data);
}

export function addApplicationUser(user) {
  return axiosInstance.post(`v1/users`, user).then((res) => res.data);
}

export function deleteApplicationUser(userId) {
  return axiosInstance.delete(`v1/users/${userId}`).then((res) => res.data);
}

export function editApplicationUser(userId, user) {
  return axiosInstance
    .patch(`v1/users/${userId}`, user)
    .then((res) => res.data);
}
