export const getScoreFromAnswer = (value) => {
  if (value == "") {
    return "-";
  }
  if (
    value === "Jamat use only" ||
    value === "Community owned (Jamati institutions)" ||
    value === "Stand alone building with 1-2 floors" ||
    value ===
      "Concrete (main structure in concrete, mixed with bricks or other materials)" ||
    value === "Yes; completely acceptable and/or completely secure" ||
    value ===
      "Yes; control measures are in place and are completely proportionate, effective and appropriate." ||
    value === "Yes"
  ) {
    return 1;
  }

  if (
    value === "Shared use - within private dwelling" ||
    value === "Community owned - private individuals" ||
    value === "Masonry (mostly bricks without structural concrete)" ||
    value === "Yes; highly acceptable and/or highly secure" ||
    value ===
      "Yes; control measures are in place and are highly proportionate, effective and appropriate."
  ) {
    return 2;
  }
  if (
    value === "Shared use - within commercial premises" ||
    value === "Rented / leased" ||
    value === "Timber (wood)" ||
    value === "Yes; generally acceptable and/or generally secure" ||
    value ===
      "Yes; control measures are in place and are generally proportionate, effective and appropriate."
  ) {
    return 3;
  }
  if (
    value === "Other" ||
    value === "Within housing colony" ||
    value === "Modular structure (container, temporary structure)" ||
    value === "Yes; but unacceptable and/or insecure" ||
    value ===
      "Yes; control measures are in place but are not proportionate, not effective and/or not appropriate"
  ) {
    return 4;
  }
  if (
    value === "Other" ||
    value ===
      "Iron (wrought iron, cast iron, steel; may be filled with bricks and partitions)" ||
    value === "No; completely unacceptable and/or completely insecure" ||
    value ===
      "No; control measures are not in place and/or not proportionate, not effective and not appropriate" ||
    value === "No"
  ) {
    return 5;
  }
};

export const getScore = (data) => {
  let total = 0;
  let totalScore = 0;
  let unanswered = 0;
  Object.keys(data).forEach((key) => {
    const { value } = data[key].value;
    total += 1;
    if (data[key].isNA && data[key].isNA.flag === true) {
      unanswered += 1;
      return;
    }
    if (value === "" || value === "null" || value === "undefined") {
      unanswered += 1;
      return;
    }

    if (
      value === "Jamat use only" ||
      value === "Community owned (Jamati institutions)" ||
      value === "Stand alone building with 1-2 floors" ||
      value ===
        "Concrete (main structure in concrete, mixed with bricks or other materials)" ||
      value === "Yes; completely acceptable and/or completely secure" ||
      value ===
        "Yes; control measures are in place and are completely proportionate, effective and appropriate." ||
      value === "Yes"
    ) {
      totalScore += 100;
    }

    if (
      value === "Shared use - within private dwelling" ||
      value === "Community owned - private individuals" ||
      value === "Masonry (mostly bricks without structural concrete)" ||
      value === "Yes; highly acceptable and/or highly secure" ||
      value ===
        "Yes; control measures are in place and are highly proportionate, effective and appropriate."
    ) {
      totalScore += 75;
    }
    if (
      value === "Shared use - within commercial premises" ||
      value === "Rented / leased" ||
      value === "Timber (wood)" ||
      value === "Yes; generally acceptable and/or generally secure" ||
      value ===
        "Yes; control measures are in place and are generally proportionate, effective and appropriate."
    ) {
      totalScore += 50;
    }
    if (
      value === "Other" ||
      value === "Within housing colony" ||
      value === "Modular structure (container, temporary structure)" ||
      value === "Yes; but unacceptable and/or insecure" ||
      value ===
        "Yes; control measures are in place but are not proportionate, not effective and/or not appropriate"
    ) {
      totalScore += 25;
    }
    if (
      value === "Other" ||
      value ===
        "Iron (wrought iron, cast iron, steel; may be filled with bricks and partitions)" ||
      value === "No; completely unacceptable and/or completely insecure" ||
      value ===
        "No; control measures are not in place and/or not proportionate, not effective and not appropriate" ||
      value === "No"
    ) {
      totalScore += 0;
    }
  });

  return {
    total,
    totalScore,
    unanswered,
  };
};

export const getTotalScore = (response) => {
  let total = 0;
  let totalScore = 0;
  let unanswered = 0;
  Object.keys(response).forEach((key) => {
    if (
      key === "assesmentDetails" ||
      key === "jamatkhanaDetails" ||
      key === "jamatkhanaProfile" ||
      key === "riskRegister"
    ) {
      return;
    }
    Object.keys(response[key]).forEach((key2) => {
      total += 1;
      if (
        (response[key][key2] &&
          response[key][key2].isNA &&
          response[key][key2].isNA.flag === true) ||
        (response[key][key2].value.value &&
          (response[key][key2].value.value.trim() === "" ||
            response[key][key2].value.value === "null" ||
            response[key][key2].value.value === "undefined" ||
            response[key][key2].value.value === null ||
            response[key][key2].value.value === undefined))
      ) {
        unanswered += 1;
        return;
      }
      if (
        response[key][key2] &&
        response[key][key2].isNA &&
        response[key][key2].isNA.flag === true
      ) {
        unanswered += 1;
        return;
      }
      if (!response[key][key2].value) {
        unanswered += 1;
        return;
      }
      if (
        response[key][key2].value.value === "Jamat use only" ||
        response[key][key2].value.value ===
          "Community owned (Jamati institutions)" ||
        response[key][key2].value.value ===
          "Stand alone building with 1-2 floors" ||
        response[key][key2].value.value ===
          "Concrete (main structure in concrete, mixed with bricks or other materials)" ||
        response[key][key2].value.value ===
          "Yes; completely acceptable and/or completely secure" ||
        response[key][key2].value.value ===
          "Yes; control measures are in place and are completely proportionate, effective and appropriate." ||
        response[key][key2].value.value === "Yes"
      ) {
        totalScore += 100;
      }
      if (
        response[key][key2].value.value ===
          "Shared use - within private dwelling" ||
        response[key][key2].value.value ===
          "Community owned - private individuals" ||
        response[key][key2].value.value ===
          "Masonry (mostly bricks without structural concrete)" ||
        response[key][key2].value.value ===
          "Yes; highly acceptable and/or highly secure" ||
        response[key][key2].value.value ===
          "Yes; control measures are in place and are highly proportionate, effective and appropriate."
      ) {
        totalScore += 75;
      }
      if (
        response[key][key2].value.value ===
          "Shared use - within commercial premises" ||
        response[key][key2].value.value === "Rented / leased" ||
        response[key][key2].value.value === "Timber (wood)" ||
        response[key][key2].value.value ===
          "Yes; generally acceptable and/or generally secure" ||
        response[key][key2].value.value ===
          "Yes; control measures are in place and are generally proportionate, effective and appropriate."
      ) {
        totalScore += 50;
      }
      if (
        response[key][key2].value.value === "Other" ||
        response[key][key2].value.value === "Within housing colony" ||
        response[key][key2].value.value ===
          "Modular structure (container, temporary structure)" ||
        response[key][key2].value.value ===
          "Yes; but unacceptable and/or insecure" ||
        response[key][key2].value.value ===
          "Yes; control measures are in place but are not proportionate, not effective and/or not appropriate"
      ) {
        totalScore += 25;
      }
      if (
        response[key][key2].value.value === "Other" ||
        response[key][key2].value.value ===
          "Iron (wrought iron, cast iron, steel; may be filled with bricks and partitions)" ||
        response[key][key2].value.value ===
          "No; completely unacceptable and/or completely insecure" ||
        response[key][key2].value.value ===
          "No; control measures are not in place and/or not proportionate, not effective and not appropriate" ||
        response[key][key2].value.value === "No"
      ) {
        totalScore += 0;
      }
    });
  });
  return {
    totalScore,
    total,
    unanswered,
  };
};
