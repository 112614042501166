import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table";
import { getAssesmentsTemplates } from "../../services/AssesmentTemplateService";
import PageTitle from "../layouts/PageTitle";
//import './table.css';

export const AssesmentTemplate = () => {
  const [assesmentTemplate, setAssesmentTemplate] = React.useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Image",
        accessor: "image",
      },
      {
        Header: "Static File",
        accessor: "static_file",
      },
    ],
    []
  );
  const data = useMemo(() => [...assesmentTemplate], [assesmentTemplate]);

  const tableInstance = useTable({ columns, data }, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const getTemplates = () => {
    getAssesmentsTemplates().then((res) => {
      setAssesmentTemplate(res);
    });
  };

  React.useEffect(() => {
    getTemplates();
  }, []);

  return (
    <>
      <PageTitle activeMenu="Assesment Templates" motherMenu="Assesment" />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Assesment Templates</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <div className="dataTables_wrapper">
              {assesmentTemplate && assesmentTemplate.length > 0 ? (
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span className="ml-1">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-arrow-up" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* This is only for footer if u require */}
                  <tfoot>
                    {footerGroups.map((footerGroup) => (
                      <tr {...footerGroup.getFooterGroupProps()}>
                        {footerGroup.headers.map((column) => (
                          <td {...column.getFooterProps()}>
                            {column.render("Footer")}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssesmentTemplate;
