import React from "react";
import { Alert, Col, Nav, Row, Tab } from "react-bootstrap";

export default function LikelihoodSummary({ threadData }) {
  const getText = (score) => {
    if (score === 1 || score === "1") {
      return "Very Unlikely";
    }
    if (score === 2 || score === "2") {
      return "Unlikely";
    }
    if (score === 3 || score === "3") {
      return "Possible";
    }
    if (score === 4 || score === "4") {
      return "Likely";
    }
    return "Very Likely";
  };

  const getProbablity = (score) => {
    if (score === 1) {
      return "< 5%";
    }
    if (score === 2) {
      return "< 35%";
    }
    if (score === 3) {
      return "> 35%";
    }
    if (score === 4) {
      return "> 65%";
    }
    return "> 95%";
  };

  return (
    <Row>
      <Col xs={12}>
        <table className="min-w-full w-full table colored-table user_detail">
          <thead className="border-b" />
          <tbody>
            <tr>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Threat Description
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Likelihood
              </th>
            </tr>
            {threadData &&
              threadData.map((threat, index) => (
                <tr key={`likelihood_summary_${index}`} className="border-b">
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {threat.Description ? threat.Description : ""}
                  </td>
                  <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="form-check">
                        <label className="form-check-label inline-block text-gray-800">
                          {getText(threat.Likelihood.chance)}
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
