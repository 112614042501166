import React, { useState, useCallback } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  Handle,
} from 'reactflow';
import 'reactflow/dist/style.css';
import './custom.css';

const initialNodes = [
  {
    id: '1',
    data: { label: 'DEATH' },
    position: { x: 300, y: 50 },
    className: 'grey-node',
  },
  {
    id: '2',
    data: { label: 'SFP informs Accountable Person (if not done so already)' },
    position: { x: 600, y: 50 },
    className: 'black-node',
  },
  {
    id: '3',
    data: { label: 'SFP Completes Incident Report' },
    position: { x: 50, y: 200 },
    className: 'green-node',
  },
  {
    id: '4',
    data: { label: 'Contact Local Authorities' },
    position: { x: 300, y: 250 },
  },
  {
    id: '5',
    data: { label: 'Nominated Person Notifies Next of Kin' },
    position: { x: 600, y: 200 },
    className: 'black-node',
  },
  {
    id: '6',
    data: {
      label: 'SFP to Inform Embassy of the Casualty (if International Staff)',
    },
    position: { x: 700, y: 280 },
    className: 'black-node',
  },
  {
    id: '7',
    data: {
      label: 'Complete required processes as Per local laws and customs',
    },
    position: { x: 300, y: 380 },
  },
  {
    id: '8',
    data: { label: 'Repatriation(if required)' },
    position: { x: 300, y: 500 },
  },
  {
    id: '9',
    data: { label: 'SFP updates Incident Report' },
    position: { x: 50, y: 500 },
    className: 'green-node',
  },
];

const initialEdges = [
  {
    id: 'e1-3',
    source: '1',
    target: '3',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e1-4',
    source: '1',
    target: '4',
    type: 'straight',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    type: 'smoothstep',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e2-5',
    source: '2',
    target: '5',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e4-6',
    source: '4',
    target: '6',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e4-7',
    source: '4',
    target: '7',
    type: 'straight',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e6-7',
    source: '6',
    target: '7',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e7-8',
    source: '7',
    target: '8',
    type: 'straight',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e7-9',
    source: '7',
    target: '9',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e8-9',
    source: '8',
    target: '9',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
];

export default function FlowchartSection3() {
  const [nodes, setNodes, onNodesChange] = useState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <div style={{ width: '100%', height: '70vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        panOnScroll={false}
        panOnDrag={false}
        zoomOnScroll={false}
        zoomOnPinch={false}
        zoomOnDoubleClick={false}
        attributionPosition={null}
        interactionMode="nodeDrag"
        preventScrolling={false}
      >
      </ReactFlow>
    </div>
  );
}
