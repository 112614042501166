import React, { useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import ReactSelect from "react-select";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from "react-table";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
import PageTitle from "../layouts/PageTitle";
//import './table.css';
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {
  addApplicationUser,
  deleteApplicationUser,
  editApplicationUser,
  getApplicationUsers,
} from "../.././services/UserService";
import { getAssesmentsTemplates } from "../../services/AssesmentTemplateService";
import { addAssignment } from "../../services/AssigmentService";
import { ColumnFilter } from "../components/table/FilteringTable/ColumnFilter";
import "../components/table/FilteringTable/filtering.css";
import Editable from "./Editable";
export const Users = () => {
  const COLUMNS = [
    {
      Header: "name",
      Footer: "name",
      accessor: "name",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "email",
      Footer: "email",
      accessor: "email",
      Filter: ColumnFilter,
    },
    {
      Header: "role",
      Footer: "role",
      accessor: "role",
      Cell: ({ value }) => {
        if (value) {
          const role = roles.find((role) => role.value === value);
          return (
            <span
              style={{ backgroundColor: "#da0a2a" }}
              className="badge badge-warning"
            >
              {role?.label}
            </span>
          );
        } else return "";
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Country Agency",
      Footer: "Country Agency",
      accessor: "countryAgency",
      Cell: ({ value }) => {
        if (value) return value?.name;
        else return "";
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Agency",
      Footer: "Agency",
      accessor: "agency",
      Cell: ({ value }) => {
        if (value) return value?.name;
        else return "";
      },
      Filter: ColumnFilter,
    },
    {
      Header: "action",
      Footer: "action",
      accessor: "action",
      Cell: (data) => {
        return (
          <div className="d-flex">
            <Link
              className="btn btn-secondary	 shadow btn-xs sharp me-2"
              onClick={(event) => handleEditClick(event, data.row.original)}
              to="#"
            >
              <i className="fas fa-pen"></i>
            </Link>
            <Link
              className="btn btn-danger shadow btn-xs sharp"
              onClick={() => handleDeleteClick(data.row.original.id)}
              to="#"
            >
              <i className="fa fa-trash"></i>
            </Link>
          </div>
        );
      },
      Filter: ColumnFilter,
    },
  ];
  const user = useSelector((state) => state.auth.auth);
  const [roles, setRoles] = React.useState([
    { value: "agent", label: "Agent" },
    { value: "agency", label: "Agency" },
    { value: "countryAgency", label: "Country Agency" },
    { value: "admin", label: "Admin" },
  ]);
  const [users, setUsers] = useState([]);

  // edit  data
  const [editFormData, setEditFormData] = useState({
    name: "",
    department: "",
    gender: "",
    education: "",
    mobile: "",
    email: "",
  });
  //Modal box
  const [addCard, setAddCard] = useState(false);

  //Edit start
  //const [editModal, setEditModal] = useState(false);
  // Edit function editable page loop
  const [editContentId, setEditContentId] = useState(null);
  const [assesmentTemplate, setAssesmentTemplate] = useState([]);
  const [selectedAssesmentTemplate, setSelectedAssesmentTemplate] = useState(
    []
  );

  const [countryAgency, setCountryAgency] = useState([]);
  const [agency, setAgency] = useState([]);

  const columns = useMemo(() => COLUMNS, []);

  React.useEffect(() => {
    if (user && user.role) {
      if (user.role === "countryAgency") {
        setRoles([
          { value: "agent", label: "Agent" },
          { value: "agency", label: "Agency" },
        ]);
      } else if (user.role === "agency") {
        setRoles([{ value: "agent", label: "Agent" }]);
      }
    }
  }, [user]);
  // const data = useMemo(() => MOCK_DATA, []);
  const tableInstance = useTable(
    {
      columns,
      data: users,
      initialState: { pageIndex: 0, pageSize: 50000 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;
  // delete data
  const handleDeleteClick = (userId) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this user!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        deleteApplicationUser(userId)
          .then(() => {
            getAllUsers();
            swal("Poof! Your user has been deleted!", {
              icon: "success",
            });
          })
          .catch(() => {
            swal("Something went Wrong..!", {
              icon: "error",
            });
          });
      } else {
        swal("Your user is safe!");
      }
    });
  };

  //Add data
  const [addFormData, setAddFormData] = useState({
    name: "",
    email: "",
    role: "",
    countryAgency: "",
    agency: "",
    password: "",
    assessmentTemplate: [],
  });

  // Add contact function
  const handleAddFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...addFormData };
    newFormData[fieldName] = fieldValue;
    setAddFormData(newFormData);
  };

  const handleSelectChange = (field, value) => {
    const newFormData = { ...addFormData };
    newFormData[field] = value;
    setAddFormData(newFormData);
  };

  //Add Submit data
  const handleAddFormSubmit = async (event) => {
    event.preventDefault();
    var error = false;
    var errorMsg = "";
    if (addFormData.name === "") {
      error = true;
      errorMsg = "Please fill  name";
    } else if (addFormData.role === "") {
      error = true;
      errorMsg = "Please Select role.";
    } else if (addFormData.password === "") {
      error = true;
      errorMsg = "Please Enter Password.";
    } else if (addFormData.email === "") {
      error = true;
      errorMsg = "please fill Email";
    }
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must not contain Whitespaces.";
    }

    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must have at least one Uppercase Character.";
    }

    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must have at least one Lowercase Character.";
    }

    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must contain at least one Digit.";
    }

    const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must contain at least one Special Symbol.";
    }

    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(addFormData.password)) {
      error = true;
      errorMsg = "Password must be 8-16 Characters Long.";
    }
    if (!error) {
      const newContent = {
        name: addFormData.name,
        email: addFormData.email,
        password: addFormData.password,
        role: addFormData?.role?.value,
        countryAgency:
          addFormData?.role?.value === "agent"
            ? addFormData?.agency?.countryAgency.id
            : addFormData?.countryAgency?.id,
        agency: addFormData?.agency?.id,
      };

      await addApplicationUser(newContent)
        .then(async (user) => {
          if (
            addFormData.assessmentTemplate &&
            addFormData.assessmentTemplate.length > 0
          ) {
            for (let i = 0; i < addFormData.assessmentTemplate.length; i++) {
              let assementItem = addFormData.assessmentTemplate[i];
              const newContent = {
                assesmentUser: user.id,
                assessmentTemplate: assementItem._id,
                notes: "",
              };
              await addAssignment(newContent).catch((err) => {
                swal(err.response.data.message, { icon: "error" });
              });
            }
          }
          getAllUsers();
          setAddCard(false);
          swal("User added successfully!", {
            icon: "success",
          });
          setAddCard(false);
          setAddFormData({
            name: "",
            email: "",
            role: "",
            countryAgency: "",
            agency: "",
            password: "",
          });
        })
        .catch((err) => {
          swal(err.response.data.message, { icon: "error" });
        });
    } else {
      swal("Oops", errorMsg, "error");
    }
  };

  // Edit function button click to edit
  const handleEditClick = (event, content) => {
    event.preventDefault();
    setEditContentId(content.id);
    const formValues = {
      name: content.name,
      role: roles.find((item) => item.value === content.role),
      countryAgency: content.countryAgency,
      agency: content.agency,
      email: content.email,
    };
    setEditFormData(formValues);
  };

  //update data function
  const handleEditFormChange = (event) => {
    event.preventDefault();
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;
    const newFormData = { ...editFormData };
    newFormData[fieldName] = fieldValue;
    setEditFormData(newFormData);
  };

  const handleEditSelectChange = (field, value) => {
    const newFormData = { ...editFormData };
    newFormData[field] = value;
    setEditFormData(newFormData);
  };

  // edit form data submit
  const handleEditFormSubmit = (event) => {
    event.preventDefault();
    const editedContent = {
      id: editContentId,
      name: editFormData.name,
      email: editFormData.email,
      role: editFormData?.role?.value || undefined,
      countryAgency: editFormData?.countryAgency?.id || undefined,
      agency: editFormData?.agency?.id || undefined,
    };
    editApplicationUser(editedContent.id, editedContent)
      .then((res) => {
        getAllUsers();
        swal("Good job!", "Successfully Updated", "success");
        setEditContentId(null);
        setEditFormData({
          name: "",
          email: "",
          role: "",
          countryAgency: "",
          agency: "",
        });
      })
      .catch((err) => {
        swal(err.response.data.message, { icon: "error" });
      });
  };

  //Cencel button to same data
  const handleCancelClick = () => {
    setEditContentId(null);
  };

  const getAllUsers = () => {
    getApplicationUsers()
      .then((res) => {
        setUsers(res.results.filter((item) => item.id !== user.userId));
        setCountryAgency(
          res.results.filter((item) => item.role === "countryAgency")
        );
        setAgency(res.results.filter((item) => item.role === "agency"));
      })
      .catch((e) => {
        swal(e.response.data.message, { icon: "error" });
      });
  };

  React.useEffect(() => {
    getAllUsers();
    getAssesmentsTemplates().then((res) => setAssesmentTemplate(res));
  }, []);

  return (
    <>
      <PageTitle activeMenu="All Users" motherMenu="Users" />
      <Modal className="modal fade" show={addCard} onHide={setAddCard}>
        <div className="" role="document">
          <div className="">
            <form>
              <div className="modal-header">
                <h4 className="modal-title fs-20">Add User</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setAddCard(false)}
                  data-dismiss="modal"
                >
                  <span></span>
                </button>
              </div>
              <div className="modal-body">
                <i
                  className="flaticon-cancel-12 close"
                  data-dismiss="modal"
                ></i>
                <div className="add-contact-box">
                  <div className="add-contact-content">
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Name</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="name"
                          required="required"
                          onChange={handleAddFormChange}
                          placeholder="name"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="email"
                          required="required"
                          onChange={handleAddFormChange}
                          placeholder="email"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Password</label>
                      <div className="contact-name">
                        <input
                          type="text"
                          className="form-control"
                          autoComplete="off"
                          name="password"
                          required="required"
                          onChange={handleAddFormChange}
                          placeholder="Enter Password"
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label className="text-black font-w500">Role</label>
                      <div className="contact-name">
                        <ReactSelect
                          onChange={(e) => handleSelectChange("role", e)}
                          options={roles}
                          value={addFormData.role}
                          style={{
                            lineHeight: "40px",
                            color: "#7e7e7e",
                            paddingLeft: " 15px",
                          }}
                        />
                        <span className="validation-text"></span>
                      </div>
                    </div>
                    {addFormData.role &&
                      addFormData.role.value !== "countryAgency" &&
                      addFormData.role.value !== "admin" &&
                      addFormData.role.value !== "agent" && (
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">
                            Country Agency
                          </label>
                          <div className="contact-name">
                            <ReactSelect
                              name="countryAgency"
                              fieldName="countryAgency"
                              onChange={(e) =>
                                handleSelectChange("countryAgency", e)
                              }
                              options={countryAgency}
                              value={addFormData.countryAgency}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                              }}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      )}
                    {addFormData.role &&
                      addFormData.role.value !== "admin" &&
                      addFormData.role.value !== "countryAgency" &&
                      addFormData.role.value !== "agency" && (
                        <div className="form-group mb-3">
                          <label className="text-black font-w500">Agency</label>
                          <div className="contact-name">
                            <ReactSelect
                              name="agency"
                              fieldName="agency"
                              onChange={(e) => handleSelectChange("agency", e)}
                              options={agency}
                              value={addFormData.agency}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                              style={{
                                lineHeight: "40px",
                                color: "#7e7e7e",
                                paddingLeft: " 15px",
                              }}
                            />
                            <span className="validation-text"></span>
                          </div>
                        </div>
                      )}
                    {addFormData.role.value === "agent" && (
                      <div className="form-group mb-3">
                        <label className="text-black font-w500">
                          Assement Template
                        </label>
                        <div className="contact-name">
                          <ReactSelect
                            name="assessmentTemplate"
                            fieldName="assessmentTemplate"
                            isMulti
                            onChange={(e) =>
                              handleSelectChange("assessmentTemplate", e)
                            }
                            options={assesmentTemplate}
                            value={addFormData.assessmentTemplate}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.ID}
                            style={{
                              lineHeight: "40px",
                              color: "#7e7e7e",
                              paddingLeft: " 15px",
                            }}
                          />
                          <span className="validation-text"></span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleAddFormSubmit}
                >
                  Add
                </button>
                <button
                  type="button"
                  onClick={() => setAddCard(false)}
                  className="btn btn-danger"
                >
                  {" "}
                  <i className="flaticon-delete-1"></i> Discard
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Users Datatable</h4>
          <button className="btn btn-primary" onClick={() => setAddCard(true)}>
            <i className="fa fa-plus" /> Add User
          </button>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <form onSubmit={handleEditFormSubmit}>
              <table {...getTableProps()} className="table  display">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th {...column.getHeaderProps()}>
                          {column.render("Header")}
                          {column.canFilter ? column.render("Filter") : null}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()} className="">
                  {page.map((row) => {
                    prepareRow(row);
                    if (editContentId === row.original.id) {
                      return (
                        <Editable
                          key={row.original.id}
                          users={users}
                          roles={roles}
                          editFormData={editFormData}
                          handleEditFormChange={handleEditFormChange}
                          handleCancelClick={handleCancelClick}
                          handleEditSelectChange={handleEditSelectChange}
                        />
                      );
                    } else {
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    }
                  })}
                </tbody>
              </table>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Users;
