import React from "react";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import swal from "sweetalert";
import {
  getAppSetting,
  updateAppSetting,
} from "../../../../services/AppService";

const AppSetting = (props) => {
  const [settings, setSettings] = React.useState({});
  const updatedSetting = (e) => {
    e.preventDefault();
    const body = { ...settings };
    delete body.id;
    updateAppSetting(settings.id, body).then(() => {
      swal(
        "Success",
        "Your Settings have been updated Successfully!",
        "success"
      );
    });
  };

  React.useEffect(() => {
    getAppSetting("test")
      .then((res) => {
        if (res) {
          setSettings(res);
        }
      })
      .catch((err) => {
        swal("Error", "Something went wrong!", "error");
      });
  }, []);
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12 col-xl-12">
          <MuiThemeProvider muiTheme={getMuiTheme()}>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Desktop Application Setting</h4>
              </div>
              <div className="card-body">
                <form onSubmit={updatedSetting}>
                  <div className="form-group mb-3 col-3">
                    <label htmlFor="version">Desktop App Version</label>
                    <input
                      type="text"
                      value={settings?.version}
                      onChange={(e) => {
                        setSettings({ ...settings, version: e.target.value });
                      }}
                      className="form-control"
                      id="version"
                      name="version"
                      aria-describedby="emailHelp"
                      placeholder="Enter Desktop Application Version"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </MuiThemeProvider>
        </div>
      </div>
    </>
  );
};
export default AppSetting;
