import React from "react";
import ReactSelect from "react-select";

const Editable = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleEditSelectChange,
  users,
  roles,
}) => {
  return (
    <>
      <tr>
        <td>
          <input
            type="text"
            required="required"
            placeholder="Enter a name ..."
            name="name"
            value={editFormData.name}
            onChange={handleEditFormChange}
          />
        </td>
        <td>
          <input
            type="text"
            required="required"
            placeholder="Enter your email ..."
            name="email"
            value={editFormData.email}
            onChange={handleEditFormChange}
          />
        </td>
        <td>
          <ReactSelect
            onChange={(e) => handleEditSelectChange("role", e)}
            options={roles}
            value={editFormData.role}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
            }}
          />
        </td>
        <td>
          <ReactSelect
            name="countryAgency"
            fieldName="countryAgency"
            onChange={(e) => handleEditSelectChange("countryAgency", e)}
            options={users}
            value={editFormData.countryAgency}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
            }}
          />
        </td>
        <td>
          <ReactSelect
            name="agency"
            fieldName="agency"
            onChange={(e) => handleEditSelectChange("agency", e)}
            options={users}
            value={editFormData.agency}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
            }}
          />
        </td>
        <td>
          <div className="d-flex">
            <button
              className="btn btn-warning shadow btn-xs sharp me-1"
              type="submit"
            >
              <i className="las la-check-circle scale5"></i>
            </button>
            <button
              className="btn btn-danger shadow btn-xs sharp "
              type="button"
              onClick={handleCancelClick}
            >
              <i className="las la-times-circle scale5"></i>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
export default Editable;
