import React from "react";
import { Alert, Col, Row } from "react-bootstrap";

export default function VulnerabilitySummary({ vulnerabilities }) {
  const getTotalScore = () => {
    let score = 0;
    Object.keys(vulnerabilities).forEach((key) => {
      if (!Number.isNaN(parseInt(vulnerabilities[key])) && key !== "Total") {
        score += parseInt(vulnerabilities[key]);
      }
    });
    return score;
  };

  const getPerformanceText = (score) => {
    if (score === 1 || score === "1") {
      return "Very Low Vulnerabilities";
    }
    if (score === 2 || score === "2") {
      return "Low Vulnerabilities";
    }
    if (score === 3 || score === "3") {
      return "Medium Vulnerabilities";
    }
    if (score === 4 || score === "4") {
      return "High Vulnerabilities";
    }
    return "Very High Vulnerabilities";
  };

  const getbackgroundClass = () => {
    const score = getTotalScore();
    if (score <= 8) {
      return "bg-green-400";
    }
    if (score > 8 && score <= 16) {
      return "bg-blue-400";
    }
    if (score > 16 && score <= 24) {
      return "bg-yellow-400";
    }
    if (score > 24 && score <= 32) {
      return "bg-orange-400";
    }
    return "bg-red-400";
  };
  return (
    <Row>
      <Col xs={12} />
      <Col xs={12}>
        <table className="min-w-full w-full table colored-table user_detail">
          <tbody>
            <tr>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Vulnerability Type
              </th>
              <th
                scope="col"
                className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
              >
                Value
              </th>
            </tr>
            <tr className="border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Experience :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div>
                  <div className="form-check">
                    <label className="form-check-label inline-block text-gray-800">
                      {getPerformanceText(vulnerabilities.Experience)}
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Training :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div>
                  <div className="form-check">
                    <label className="form-check-label inline-block text-gray-800">
                      {getPerformanceText(vulnerabilities.Training)}
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Awareness :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Awareness)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Planning :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Planning)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Security Culture / Compliance :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Security_Culture)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Incident Reporting :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Incident_Reporting)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Incident Management :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Incident_Management)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Acceptance :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Acceptance)}
                  </label>
                </div>
              </td>
            </tr>
            <tr className="bg-white border-b">
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                Physical Protection :
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className="form-check">
                  <label className="form-check-label inline-block text-gray-800">
                    {getPerformanceText(vulnerabilities.Physical_Protection)}
                  </label>
                </div>
              </td>
            </tr>

            <tr />
          </tbody>
        </table>
      </Col>
    </Row>
  );
}
