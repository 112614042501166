import React, { useMemo, useState } from "react";
import { useTable, useGlobalFilter, useSortBy } from "react-table";
import { GlobalFilter } from "../components/table/FilteringTable/GlobalFilter";
import { getContactQueries } from "../../services/ContactService";
import PageTitle from "../layouts/PageTitle";
//import './table.css';

export const ContactQuery = () => {
  const [queries, setContactQueries] = React.useState([]);
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "fullName",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Subject",
        accessor: "subject",
        Cell: ({ value }) => {
          if (value) return value;
          else return "-";
        },
      },
      {
        Header: "Submitted by",
        accessor: "userId",
        Cell: ({ value }) => {
          if (value) return value?.name;
          else return "";
        },
      },
      {
        Header: "Query",
        accessor: "Query",
      },
    ],
    []
  );
  const data = useMemo(() => [...queries], [queries]);

  const tableInstance = useTable({ columns, data }, useGlobalFilter, useSortBy);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const getQueries = () => {
    getContactQueries().then((res) => {
      setContactQueries(res.data);
    });
  };

  React.useEffect(() => {
    getQueries();
  }, []);

  return (
    <>
      <PageTitle activeMenu="Contact Queries" motherMenu="Contact" />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Contact Queries</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <div className="dataTables_wrapper">
              {queries && queries.length > 0 ? (
                <table {...getTableProps()} className="table dataTable display">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span className="ml-1">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <i className="fa fa-arrow-down" />
                                ) : (
                                  <i className="fa fa-arrow-up" />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {" "}
                                {cell.render("Cell")}{" "}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* This is only for footer if u require */}
                  <tfoot>
                    {footerGroups.map((footerGroup) => (
                      <tr {...footerGroup.getFooterGroupProps()}>
                        {footerGroup.headers.map((column) => (
                          <td {...column.getFooterProps()}>
                            {column.render("Footer")}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tfoot>
                </table>
              ) : (
                <div className="text-center">No Data Found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactQuery;
