import React, { useState, useCallback } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import ReactFlow, {
  addEdge,
  MiniMap,
  Controls,
  Background,
  Handle,
} from 'reactflow';
import 'reactflow/dist/style.css';
import './custom.css';
const initialNodes = [
  {
    id: '1',
    data: { label: 'Medical Facility', value: '', onChange: () => {} },
    position: { x: 200, y: 50 },
  },
  {
    id: '2',
    data: {
      label: 'Accountable Person Informs Senior Leadership SFP Informs DIS ',
      value: '',
      onChange: () => {},
    },
    position: { x: 400, y: 50 },
    className: 'black-node',
  },
  {
    id: '3',
    data: { label: 'Stabilised' },
    position: { x: 50, y: 200 },
  },
  {
    id: '4',
    data: { label: 'Not Stabilised' },
    position: { x: 300, y: 200 },
  },
  {
    id: '5',
    data: { label: 'Medical Evacuation' },
    position: { x: 400, y: 300 },
  },
  {
    id: '6',
    data: { label: 'SFP Completes Incident Report' },
    position: { x: 50, y: 300 },
    className: 'green-node',
  },
  {
    id: '7',
    type: 'customNode',
    data: { label: '' },
    position: { x: 400, y: 400 },
  },
  {
    id: '8',
    data: { label: 'Retrieve Casualty Emergency Details from HR' },
    position: { x: 700, y: 400 },
    className: 'black-node',
  },

  {
    id: '9',
    data: { label: 'Evacuate Casualty' },
    position: { x: 400, y: 500 },
  },
  {
    id: '10',
    data: { label: 'SFP Completes Incident Report' },
    position: { x: 600, y: 500 },
    className: 'green-node',
  },
];

const initialEdges = [
  {
    id: 'e1-2',
    source: '1',
    target: '2',
    type: 'smoothstep',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e1-3',
    source: '1',
    target: '3',
    type: 'straight',
    style: { stroke: 'green' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e1-4',
    source: '1',
    target: '4',
    type: 'straight',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e3-6',
    source: '3',
    target: '6',
    type: 'straight',
    style: { stroke: 'green' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e4-5',
    source: '4',
    target: '5',
    type: 'smoothstep',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e5-7',
    source: '5',
    target: '7',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e7-8',
    source: '7',
    target: '8',
    type: 'straight',
    style: { stroke: 'black' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e7-9',
    source: '7',
    target: '9',
    type: 'smoothstep',
    style: { stroke: 'red' },
    markerEnd: { type: 'arrowclosed' },
  },
  {
    id: 'e9-10',
    source: '9',
    target: '10',
    type: 'straight',
    style: { stroke: 'green' },
    markerEnd: { type: 'arrowclosed' },
  },
];

export default function FlowchartSection2({
  medicalEmergency,
  setMedicalEmergency,
  nodeTypes2,
}) {
  const handleInputChange = (id, value) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === id ? { ...node, data: { ...node.data, value } } : node
      )
    );
    setMedicalEmergency((prevState) => ({
      ...prevState,
      medicalEmergencyProvider: value,
    }));
  };
  const [nodes, setNodes, onNodesChange] = useState(
    initialNodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        value: medicalEmergency.medicalEmergencyProvider,
        onChange: (value) => handleInputChange(node.id, value),
      },
    }))
  );
  const [edges, setEdges, onEdgesChange] = useState(initialEdges);

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  return (
    <>
      <div style={{ width: '100%', height: '70vh' }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes2}
          panOnScroll={false}
          panOnDrag={false}
          zoomOnScroll={false}
          zoomOnPinch={false}
          zoomOnDoubleClick={false}
          attributionPosition={null}
          interactionMode="nodeDrag"
          preventScrolling={false}
        ></ReactFlow>
      </div>
    </>
  );
}
