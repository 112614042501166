import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { resetPassword } from "../../services/AuthService";
// image
const ResetPassword = ({ history }) => {
  const token = new URLSearchParams(history.location.search).get("token");
  const app = useSelector((state) => state.auth.appSetting);
  const [password, setPassword] = React.useState("");
  const [reenterPassword, setReenterPassword] = React.useState("");
  const [errors, setErrors] = React.useState({});
  const onSubmit = (e) => {
    e.preventDefault();
    resetPassword(password, token).then(() => {
      history.push("/login");
    });
  };

  React.useEffect(() => {
    if (password !== reenterPassword) {
      setErrors({ ...errors, reenterPassword: "Password does not match" });
    }

    if (password.length < 6) {
      setErrors({
        ...errors,
        password: "Password must be at least 6 characters",
      });
    }
  }, [password, reenterPassword]);
  return (
    <div className="authincation h-100 p-meddle">
      <div className="container h-100">
        {" "}
        <div className="row justify-content-center h-100 align-items-center">
          <div className="col-md-6">
            <div className="authincation-content">
              <div className="row no-gutters">
                <div className="col-xl-12">
                  <div className="auth-form">
                    <div className="text-center mb-3">
                      <Link to="/users">
                        {app && app.icon && <img src={`/logo/${app.icon}`} />}
                      </Link>
                      <span>
                        {" "}
                        <h2>{app?.name || "Assestment Web Admin"}</h2>
                      </span>
                    </div>
                    <h4 className="text-center mb-4 my-2">Reset Password</h4>
                    <form onSubmit={(e) => onSubmit(e)}>
                      <div className="form-group my-2">
                        <label className="">
                          <strong>New Password</strong>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Type Your New Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div className="form-group my-3">
                        <label className="">
                          <strong>Re Enter New Password</strong>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Retype Your New Password"
                          onChange={(e) => setReenterPassword(e.target.value)}
                        />
                      </div>
                      <div className="text-center my-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                        >
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
