import React from "react";
import ReactSelect from "react-select";

const EditableAssesment = ({
  editFormData,
  handleEditFormChange,
  handleCancelClick,
  handleEditSelectChange,
  users,
  assessmentTemplate,
}) => {
  return (
    <>
      <tr>
        <td>
          <ReactSelect
            onChange={(e) => handleEditSelectChange("assesmentUser", e)}
            options={users}
            name="assesmentUser"
            value={editFormData.assesmentUser}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
            }}
          />
        </td>
        <td>
          <ReactSelect
            name="assessmentTemplate"
            fieldName="assessmentTemplate"
            onChange={(e) => handleEditSelectChange("assessmentTemplate", e)}
            options={assessmentTemplate}
            value={editFormData.assessmentTemplate}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            style={{
              lineHeight: "40px",
              color: "#7e7e7e",
              paddingLeft: " 15px",
            }}
          />
        </td>
        <td>
          <input
            type="text"
            required="required"
            placeholder="Enter your Notes ..."
            name="notes"
            value={editFormData.notes}
            onChange={handleEditFormChange}
            style={{
              padding: "7.5px 10px",
            }}
          />
        </td>
        <td>
          <div className="d-flex">
            <button
              className="btn btn-warning shadow btn-xs sharp me-1"
              type="submit"
            >
              <i className="las la-check-circle scale5"></i>
            </button>
            <button
              className="btn btn-danger shadow btn-xs sharp "
              type="button"
              onClick={handleCancelClick}
            >
              <i className="las la-times-circle scale5"></i>
            </button>
          </div>
        </td>
      </tr>
    </>
  );
};
export default EditableAssesment;
