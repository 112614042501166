import React from "react";
import { Field, reduxForm } from "redux-form";
import TextField from "material-ui/TextField";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import Checkbox from "material-ui/Checkbox";
import SelectField from "material-ui/SelectField";
import MenuItem from "material-ui/MenuItem";
import asyncValidate from "./AsyncValidate";
import validate from "./Validate";
import { useSelector } from "react-redux";

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => (
  <TextField
    hintText={label}
    floatingLabelText={label}
    value={input.value}
    errorText={touched && error}
    {...input}
    {...custom}
    variant="outlined"
  />
);

const MaterialUiForm = (props) => {
  const user = useSelector((state) => state.auth.auth);
  const { handleSubmit, pristine, reset, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="validate-redux-form row">
        <div className="col-sm-12">
          <Field
            name="subject"
            className="input-field-redux"
            component={renderTextField}
            label="Subject"
            fullWidth={true}
          />
        </div>
        <div className="col-sm-12 mb-2">
          <Field
            className="input-field-redux"
            name="Query"
            component={renderTextField}
            label="Notes"
            multiLine={true}
            fullWidth
            rows={5}
          />
        </div>
        <div className="col-sm-12 text-center">
          <button
            className="btn btn-primary me-1"
            type="submit"
            disabled={pristine || submitting}
          >
            Submit
          </button>
          <button
            className="btn btn-light ms-1"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: "MaterialUiForm", // a unique identifier for this form
  validate,
  asyncValidate,
})(MaterialUiForm);
