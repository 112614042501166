import axiosInstance from "./AxiosInstance";

export function getAssesmentsTemplates() {
  return axiosInstance.get(`v1/assesmentTemplate`).then((res) => res.data);
}

export function addAssesmentTemplate(assesmentTemplate) {
  return axiosInstance
    .post(`v1/assesmentTemplate`, assesmentTemplate)
    .then((res) => res.data);
}

export function deleteAssesmentTemplate(id) {
  return axiosInstance
    .delete(`v1/assesmentTemplate/${id}`)
    .then((res) => res.data);
}

export function editAssesmentTemplate(id, assesmentTemplate) {
  return axiosInstance
    .patch(`v1/assesmentTemplate/${id}`, assesmentTemplate)
    .then((res) => res.data);
}
