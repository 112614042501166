import React from "react";
import { useParams } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import { getAssesmentById } from "../../services/AssesmentService";
import { getScore, getTotalScore } from "../../utils";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment-timezone";

export default function Asssesment6Summary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);
  const user = useSelector((state) => state.auth.auth);
  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);
  const [overallScore, setOverallScore] = React.useState(undefined);

  const [safetyResponseScore, setSafetyResponseScore] =
    React.useState(undefined);
  const [locationSecurityScore, setLocationSecurityScore] =
    React.useState(undefined);
  const [perimeterProtectionScore, setPerimeterProtectionScore] =
    React.useState(undefined);
  const [accessControlScore, setAccessControlScore] = React.useState(undefined);
  const [electronicSecurityScore, setElectronicSecurityScore] =
    React.useState(undefined);
  const [securityGuardingScore, setSecurityGuardingScore] =
    React.useState(undefined);
  const [locakdownScore, setLocakdownScore] = React.useState(undefined);
  const [jamatkhanaSecurityScore, setJamatkhanaSecurityScore] =
    React.useState(undefined);
  const [
    StructuralResistanceAndProtectionScore,
    setStructuralResistanceAndProtectionScore,
  ] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === "undefined") {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  React.useEffect(() => {
    if (assesmentResponse) {
      const data = getTotalScore(assesmentResponse);
      setOverallScore(data);
      const locationSecurityScore = getScore(
        assesmentResponse.locationSecurity
      );
      const perimeterProtectionScore = getScore(
        assesmentResponse.perimeterProtection
      );
      const accessControlScore = getScore(assesmentResponse.accessControl);
      const SafetyResponseScore = getScore(assesmentResponse.safety_response);
      const electronicSecurityScore = getScore(
        assesmentResponse.securityEquipment
      );
      const securityGuardingScore = getScore(
        assesmentResponse.securityPersonnel
      );
      const locakdownScore = getScore(assesmentResponse.lockdown);
      const jamatkhanaSecurityScore = getScore(
        assesmentResponse.locationSecurity
      );
      const StructuralResistanceAndProtectionScore = getScore(
        assesmentResponse.structuralResistanceAndProtection
      );
      setLocationSecurityScore(locationSecurityScore);
      setPerimeterProtectionScore(perimeterProtectionScore);
      setAccessControlScore(accessControlScore);
      setSafetyResponseScore(SafetyResponseScore);
      setElectronicSecurityScore(electronicSecurityScore);
      setSecurityGuardingScore(securityGuardingScore);
      setLocakdownScore(locakdownScore);
      setJamatkhanaSecurityScore(jamatkhanaSecurityScore);
      setStructuralResistanceAndProtectionScore(
        StructuralResistanceAndProtectionScore
      );
    }
  }, [assesmentResponse]);

  const getImage = (images) => {
    let text = "";
    // create link html for each image from array images
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      text += `<a target="_blank" style="color: blue; text-decoration: underline" href="${image}" > <img src=${image} height="100%" width="100%"/></a>`;
    }
    return text;
  };

  const getSafetyScore = (score) => {
    if (score.total - score.unanswered === 0) {
      return "N/A";
    }
    return parseFloat(
      score.totalScore / (score.total - score.unanswered)
    ).toFixed(2);
  };

  const getColor = (total) => {
    const score = getSafetyScore(total);
    if (score === "N/A") {
      return "linear-gradient(to right bottom, #666666 45%, #ffffff 104%)";
    }
    if (score >= 80) {
      return "#00b050";
    }
    if (score >= 60) {
      return "#67C0FF";
    }
    if (score >= 40) {
      return "#FFF200";
    }
    if (score >= 20) {
      return "#ff7b25";
    }
    return "#ff0000";
  };

  const getSecondaryText = (total) => {
    const score = getSafetyScore(total);
    if (score === "N/A") {
      return "";
    }
    if (score >= 90) {
      return "";
    }
    if (score >= 70) {
      return "Little or no improvement needed to meet acceptable risk.";
    }
    if (score >= 40) {
      return "In most cases, a plan is needed to improve security so to meet a level of acceptable risk.";
    }
    if (score >= 10) {
      return "Your premises are vulnerable, and a significant effort is required to meet acceptable risk.";
    }
    return "Your premises are very vulnerable, and a significant effort is required to meet acceptable risk.";
  };

  const getTitleText = (total) => {
    const score = getSafetyScore(total);

    if (score === "N/A") {
      return "All questions answered as Not Applicable, see notes for details.";
    }
    if (score >= 90) {
      return "All proportional and appropriate measures in place and measures are completely effective.";
    }
    if (score >= 70) {
      return "A suitable number of proportionate and appropriate measures in place and measures are highly effective (weaknesses may exist if rigorously or severely tested).";
    }
    if (score >= 40) {
      return "Some measures in place OR the measures in place are somewhat effective.";
    }
    if (score >= 10) {
      return "Very few measures in place OR measures in place are not effective.";
    }
    return "No effective measures in place.";
  };

  const exportPdf = () => {
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF("p", "px", [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          "",
          "FAST"
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `SCHOOL-PVA-${moment(assesment.assessmentDate).format(
        "DD-MM-YYYY"
      )}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    setDownloadingPDFImage(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("p", "px", [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `AKDN-${moment(assesment.assessmentDate).format(
        "DD-MM-YYYY"
      )}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  if (!loaded) {
    return <div>Loading...</div>;
  }
  const {
    riskRegister,
    jamatkhanaProfile,
    assesmentDetails,
    jamatkhanaDetails,
    safety_response,
    accessControl,
    lockdown,
    perimeterProtection,
    securityPersonnel,
    locationSecurity,
    classroomSecurity,
    structuralResistanceAndProtection,
    securityEquipment,
    policyAndProcedure,
    buildingExterior,
    buildingInterior,
    schoolCulture,
    communicationSystem,
  } = assesmentResponse;

  const getAssesmentId = () => {
    const fileName = `AKDN-${moment(assesment.assessmentDate).format(
      "DD-MM-YYYY"
    )}`;
    return fileName;
  };

  const getSecondRowText = (value, key) => {
    if (
      value &&
      value[key] &&
      value[key].isNA &&
      value[key].isNA.flag &&
      value[key].isNA?.value?.value &&
      value[key].isNA?.value?.value !== "Other"
    ) {
      return value[key].isNA?.value?.value;
    } else if (
      value &&
      value[key] &&
      value[key].isNA &&
      value[key].isNA.flag &&
      (!value[key].isNA?.value?.value ||
        value[key].isNA?.value?.value === "Other")
    ) {
      return value[key].isNA.comment;
    } else if (
      !(value && value[key] && value[key].isNA && value[key].isNA.flag) &&
      value[key] &&
      value[key].comment
    ) {
      return value[key].isNA.comment;
    } else {
      return "-";
    }
  };

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>Assessment Id: # {assesmentId} </h4>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download PDF{" "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assessment Results</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <h4>Overall Score</h4>
                  {overallScore && (
                    <div>
                      <div>{`${
                        overallScore.total - overallScore.unanswered
                      } Scored Question / ${overallScore.total} Question`}</div>
                      <div
                        style={{
                          background: getColor(overallScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage : {getSafetyScore(overallScore)}%
                        </div>
                        <div className="mt-2">{getTitleText(overallScore)}</div>
                        <div className="mt-2">
                          {getSecondaryText(overallScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Location Security</h4>
                  {locationSecurityScore && (
                    <div>
                      <div>{`${
                        locationSecurityScore.total -
                        locationSecurityScore.unanswered
                      } Scored Question / ${
                        locationSecurityScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(locationSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(locationSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(locationSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(locationSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Perimeter Protection</h4>
                  {perimeterProtectionScore && (
                    <div>
                      <div>{`${
                        perimeterProtectionScore.total -
                        perimeterProtectionScore.unanswered
                      } Scored Question / ${
                        perimeterProtectionScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(perimeterProtectionScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(perimeterProtectionScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(perimeterProtectionScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(perimeterProtectionScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Access Control</h4>
                  {accessControlScore && (
                    <div>
                      <div>{`${
                        accessControlScore.total - accessControlScore.unanswered
                      } Scored Question / ${
                        accessControlScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(accessControlScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(accessControlScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(accessControlScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(accessControlScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Structural Resistance and Protection</h4>
                  {StructuralResistanceAndProtectionScore && (
                    <div>
                      <div>{`${
                        StructuralResistanceAndProtectionScore.total -
                        StructuralResistanceAndProtectionScore.unanswered
                      } Scored Question / ${
                        StructuralResistanceAndProtectionScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(
                            StructuralResistanceAndProtectionScore
                          ),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(
                            StructuralResistanceAndProtectionScore
                          )}
                          %
                        </div>
                        <div className="mt-2">
                          {getTitleText(StructuralResistanceAndProtectionScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(
                            StructuralResistanceAndProtectionScore
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Building Security</h4>
                  {jamatkhanaSecurityScore && (
                    <div>
                      <div>{`${
                        jamatkhanaSecurityScore.total -
                        jamatkhanaSecurityScore.unanswered
                      } Scored Question / ${
                        jamatkhanaSecurityScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(jamatkhanaSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(jamatkhanaSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(jamatkhanaSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(jamatkhanaSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Lockdown</h4>
                  {locakdownScore && (
                    <div>
                      <div>{`${
                        locakdownScore.total - locakdownScore.unanswered
                      } Scored Question / ${
                        locakdownScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(locakdownScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage : {getSafetyScore(locakdownScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(locakdownScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(locakdownScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Security Personnel</h4>
                  {securityGuardingScore && (
                    <div>
                      <div>{`${
                        securityGuardingScore.total -
                        securityGuardingScore.unanswered
                      } Scored Question / ${
                        securityGuardingScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(securityGuardingScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(securityGuardingScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(securityGuardingScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(securityGuardingScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Electronic Equipment</h4>
                  {electronicSecurityScore && (
                    <div>
                      <div>{`${
                        electronicSecurityScore.total -
                        electronicSecurityScore.unanswered
                      } Scored Question / ${
                        electronicSecurityScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(electronicSecurityScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(electronicSecurityScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(electronicSecurityScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(electronicSecurityScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Safety / Response</h4>
                  {safetyResponseScore && (
                    <div>
                      <div>{`${
                        safetyResponseScore.total -
                        safetyResponseScore.unanswered
                      } Scored Question / ${
                        safetyResponseScore.total
                      } Question`}</div>
                      <div
                        style={{
                          background: getColor(safetyResponseScore),
                          color: "#000",
                          padding: "20px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          Safety Percentage :{" "}
                          {getSafetyScore(safetyResponseScore)}%
                        </div>
                        <div className="mt-2">
                          {getTitleText(safetyResponseScore)}
                        </div>
                        <div className="mt-2">
                          {getSecondaryText(safetyResponseScore)}
                        </div>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assessor and Site Info</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="w-full colored-table summary-table half-table">
                    <tbody>
                      <tr>
                        <td>Assessor Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorFirstName.value &&
                            assesmentDetails.assesorFirstName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Assessor Email</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorEmail.value &&
                            assesmentDetails.assesorEmail.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Assessor PhoneNumber</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.assesorPhoneNumber.value &&
                            assesmentDetails.assesorPhoneNumber.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="w-full colored-table summary-table mt-4 half-table">
                    <tbody>
                      <tr>
                        <td>Co-Assessor Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssessortFirstName.value &&
                            assesmentDetails.coAssessortFirstName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Co-Assessor Email</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssesorEmail.value &&
                            assesmentDetails.coAssesorEmail.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Co-Assessor PhoneNumber</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.coAssesorPhoneNumber.value &&
                            assesmentDetails.coAssesorPhoneNumber.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table className="w-full colored-table summary-table mt-4 half-table">
                    <tbody>
                      <tr>
                        <td>Premises Name</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.siteName.value &&
                            assesmentDetails.siteName.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Country</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.country.value &&
                            assesmentDetails.country.value.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Agency</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.nationalCouncil.value &&
                            assesmentDetails.nationalCouncil.value.value}
                        </td>
                      </tr>
                      <tr>
                        <td>City</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.city.value &&
                            assesmentDetails.city.value}
                        </td>
                      </tr>
                      <tr>
                        <td>Premises Detail</td>
                        <td>
                          {assesmentDetails &&
                            assesmentDetails.address.value &&
                            assesmentDetails.address.value}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Instructions</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="min-w-full w-full colored-table">
                    <thead className="border-b">
                      <tr>
                        <th
                          scope="col"
                          className="text-sm bg-green-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          1.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-blue-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          2.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-yellow-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          3.
                        </th>
                        <th
                          scope="col"
                          className="text-sm bg-orange-500 font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          4.
                        </th>
                        <th
                          scope="col"
                          className=" bg-red-500 text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          5.
                        </th>
                        <th
                          scope="col"
                          className="na-int-color bg-red-500 text-sm font-medium text-gray-900 px-6 py-4 text-left"
                        >
                          N/A.
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Yes, completely acceptable and/or completely secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, highly acceptable and/or highly secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, generally acceptable and/or generally secure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, but unacceptable and/or insecure
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          No, completely unacceptable and/or completely insecure
                        </td>
                        <td
                          rowSpan="0"
                          className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                        >
                          Utilise the N/A option whenever faced with a question
                          which is Not Applicable in your context. When you
                          select this option, a drop-down menu will appear from
                          which to select the reason it is Not Applicable. This
                          includes an 'Other' option for you to add your own
                          comment.
                        </td>
                      </tr>
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          Yes, control measures are in place and are{" "}
                          <strong> completely </strong> proportionate, effective
                          and appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place and are{" "}
                          <strong> highly </strong> proportionate. effective and
                          appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place and are{" "}
                          <strong> generally </strong> proportionate, effective
                          and appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Yes, control measures are in place ar proportionate,{" "}
                          <strong> not </strong> effective and/or not
                          appropriate
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          No, control measures are{" "}
                          <strong> not in place and/or not </strong>{" "}
                          proportionate, not effective and not appropriate
                        </td>
                      </tr>
                      <tr className="bg-white border-b">
                        <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                          No weaknesses exist
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses may exist if severely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses may exist if routinely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses do exist if severely tested
                        </td>
                        <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          Weaknesses do exist if routinely tested
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Assessment Answers</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <h4>Premises Details</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Who is occupying the premises?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana.value &&
                          jamatkhanaDetails.occupyingJamathkana.isNA &&
                          !jamatkhanaDetails.occupyingJamathkana.isNA.flag
                            ? jamatkhanaDetails.occupyingJamathkana.value.value
                            : jamatkhanaDetails.occupyingJamathkana &&
                              jamatkhanaDetails.occupyingJamathkana.isNA &&
                              jamatkhanaDetails.occupyingJamathkana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaDetails,
                            "occupyingJamathkana"
                          )}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana &&
                          jamatkhanaDetails.occupyingJamathkana.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.occupyingJamathkana.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Who owns the premises?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana.value &&
                          jamatkhanaDetails.ownerJamatkhana.isNA &&
                          !jamatkhanaDetails.ownerJamatkhana.isNA.flag
                            ? jamatkhanaDetails.ownerJamatkhana.value.value
                            : jamatkhanaDetails.ownerJamatkhana &&
                              jamatkhanaDetails.ownerJamatkhana.isNA &&
                              jamatkhanaDetails.ownerJamatkhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaDetails,
                            "ownerJamatkhana"
                          )}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana &&
                          jamatkhanaDetails.ownerJamatkhana.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.ownerJamatkhana.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What type of premises is it?</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana.value &&
                          jamatkhanaDetails.typeOfJamatKhana.isNA &&
                          !jamatkhanaDetails.typeOfJamatKhana.isNA.flag
                            ? jamatkhanaDetails.typeOfJamatKhana.value.value
                            : jamatkhanaDetails.typeOfJamatKhana &&
                              jamatkhanaDetails.typeOfJamatKhana.isNA &&
                              jamatkhanaDetails.typeOfJamatKhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaDetails,
                            "typeOfJamatKhana"
                          )}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana &&
                          jamatkhanaDetails.typeOfJamatKhana.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.typeOfJamatKhana.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What type of construction material is the premises
                          made from?
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial.value &&
                          jamatkhanaDetails.constructionMaterial.isNA &&
                          !jamatkhanaDetails.constructionMaterial.isNA.flag
                            ? jamatkhanaDetails.constructionMaterial.value.value
                            : jamatkhanaDetails.constructionMaterial &&
                              jamatkhanaDetails.constructionMaterial.isNA &&
                              jamatkhanaDetails.constructionMaterial.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaDetails,
                            "constructionMaterial"
                          )}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial &&
                          jamatkhanaDetails.constructionMaterial.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.constructionMaterial.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Premises description</td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription.value &&
                          jamatkhanaDetails.jamatkhanaDescription.isNA &&
                          !jamatkhanaDetails.jamatkhanaDescription.isNA.flag
                            ? jamatkhanaDetails.jamatkhanaDescription.value
                            : jamatkhanaDetails.jamatkhanaDescription &&
                              jamatkhanaDetails.jamatkhanaDescription.isNA &&
                              jamatkhanaDetails.jamatkhanaDescription.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaDetails,
                            "jamatkhanaDescription"
                          )}
                        </td>
                        <td>
                          {jamatkhanaDetails &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription &&
                          jamatkhanaDetails.jamatkhanaDescription.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaDetails.jamatkhanaDescription.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Site Profile</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          List all the threats relevant to this location, as
                          identified in the security risk assessment.
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats.value ? (
                            <ul>
                              {jamatkhanaProfile.listOfThreats.value.map(
                                (item, index) => {
                                  return (
                                    <li key={`list_of_threat_${index}`}>
                                      {item.id}. {item.value}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          ) : jamatkhanaProfile.listOfThreats &&
                            jamatkhanaProfile.listOfThreats.isNA &&
                            jamatkhanaProfile.listOfThreats.isNA.flag ? (
                            "Not Applicable"
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          {getSecondRowText(jamatkhanaProfile, "listOfThreats")}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats &&
                          jamatkhanaProfile.listOfThreats.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.listOfThreats.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the use/purpose of premises compliment the
                          neighbourhood?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.complimentNeighbourhood &&
                          jamatkhanaProfile.complimentNeighbourhood.value &&
                          jamatkhanaProfile.complimentNeighbourhood.isNA &&
                          !jamatkhanaProfile.complimentNeighbourhood.isNA.flag
                            ? jamatkhanaProfile.complimentNeighbourhood.value
                            : jamatkhanaProfile.complimentNeighbourhood &&
                              jamatkhanaProfile.complimentNeighbourhood.isNA &&
                              jamatkhanaProfile.complimentNeighbourhood.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "complimentNeighbourhood"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.complimentNeighbourhood &&
                          jamatkhanaProfile.complimentNeighbourhood &&
                          jamatkhanaProfile.complimentNeighbourhood.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.complimentNeighbourhood
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Does the premises have iconic status (i.e. is it a
                          well-known symbolic facility)?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.value &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.isNA &&
                          !jamatkhanaProfile.JamatkhanaIconicStatus.isNA.flag
                            ? jamatkhanaProfile.JamatkhanaIconicStatus.value
                            : jamatkhanaProfile.JamatkhanaIconicStatus &&
                              jamatkhanaProfile.JamatkhanaIconicStatus.isNA &&
                              jamatkhanaProfile.JamatkhanaIconicStatus.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "JamatkhanaIconicStatus"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus &&
                          jamatkhanaProfile.JamatkhanaIconicStatus.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.JamatkhanaIconicStatus.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Are Police/government security forces in control of
                          the area?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .value &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA &&
                          !jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .isNA.flag
                            ? jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.value
                            : jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                              jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.isNA &&
                              jamatkhanaProfile
                                .areaInControlOfPoliceAndGoverment.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "areaInControlOfPoliceAndGoverment"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment &&
                          jamatkhanaProfile.areaInControlOfPoliceAndGoverment
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .areaInControlOfPoliceAndGoverment.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Is the profile of the location appropriate for a
                          school?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool
                            .value &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool
                            .isNA &&
                          !jamatkhanaProfile.locationProfileAppropriateForSchool
                            .isNA.flag
                            ? jamatkhanaProfile
                                .locationProfileAppropriateForSchool.value
                            : jamatkhanaProfile.locationProfileAppropriateForSchool &&
                              jamatkhanaProfile
                                .locationProfileAppropriateForSchool.isNA &&
                              jamatkhanaProfile
                                .locationProfileAppropriateForSchool.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "locationProfileAppropriateForSchool"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool &&
                          jamatkhanaProfile.locationProfileAppropriateForSchool
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile
                                    .locationProfileAppropriateForSchool.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>What is the average weekday attendance?</td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayAttendance &&
                          jamatkhanaProfile.averageWeekdayAttendance.value &&
                          jamatkhanaProfile.averageWeekdayAttendance.isNA &&
                          !jamatkhanaProfile.averageWeekdayAttendance.isNA.flag
                            ? jamatkhanaProfile.averageWeekdayAttendance.value
                            : jamatkhanaProfile.averageWeekdayAttendance &&
                              jamatkhanaProfile.averageWeekdayAttendance.isNA &&
                              jamatkhanaProfile.averageWeekdayAttendance.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "averageWeekdayAttendance"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayAttendance &&
                          jamatkhanaProfile.averageWeekdayAttendance &&
                          jamatkhanaProfile.averageWeekdayAttendance.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.averageWeekdayAttendance
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What is the average weekday residential attendance?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayResAttendance &&
                          jamatkhanaProfile.averageWeekdayResAttendance.value &&
                          jamatkhanaProfile.averageWeekdayResAttendance.isNA &&
                          !jamatkhanaProfile.averageWeekdayResAttendance.isNA
                            .flag
                            ? jamatkhanaProfile.averageWeekdayResAttendance
                                .value
                            : jamatkhanaProfile.averageWeekdayResAttendance &&
                              jamatkhanaProfile.averageWeekdayResAttendance
                                .isNA &&
                              jamatkhanaProfile.averageWeekdayResAttendance.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "averageWeekdayResAttendance"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekdayResAttendance &&
                          jamatkhanaProfile.averageWeekdayResAttendance &&
                          jamatkhanaProfile.averageWeekdayResAttendance
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.averageWeekdayResAttendance
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What is the average number of people using the
                          premises on the weekend?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekendAttendance &&
                          jamatkhanaProfile.averageWeekendAttendance.value &&
                          jamatkhanaProfile.averageWeekendAttendance.isNA &&
                          !jamatkhanaProfile.averageWeekendAttendance.isNA.flag
                            ? jamatkhanaProfile.averageWeekendAttendance.value
                            : jamatkhanaProfile.averageWeekendAttendance &&
                              jamatkhanaProfile.averageWeekendAttendance.isNA &&
                              jamatkhanaProfile.averageWeekendAttendance.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "averageWeekendAttendance"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageWeekendAttendance &&
                          jamatkhanaProfile.averageWeekendAttendance &&
                          jamatkhanaProfile.averageWeekendAttendance.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.averageWeekendAttendance
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          What is the average number of people using the school
                          during evening hours?
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageEveningAttendance &&
                          jamatkhanaProfile.averageEveningAttendance.value &&
                          jamatkhanaProfile.averageEveningAttendance.isNA &&
                          !jamatkhanaProfile.averageEveningAttendance.isNA.flag
                            ? jamatkhanaProfile.averageEveningAttendance.value
                            : jamatkhanaProfile.averageEveningAttendance &&
                              jamatkhanaProfile.averageEveningAttendance.isNA &&
                              jamatkhanaProfile.averageEveningAttendance.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            jamatkhanaProfile,
                            "averageEveningAttendance"
                          )}
                        </td>
                        <td>
                          {jamatkhanaProfile &&
                          jamatkhanaProfile.averageEveningAttendance &&
                          jamatkhanaProfile.averageEveningAttendance &&
                          jamatkhanaProfile.averageEveningAttendance.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  jamatkhanaProfile.averageEveningAttendance
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Location Security</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How would you rate the safety of the area surrounding
                          the school for pedestrians during the day?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.safetySurroundingAreaPedestriansDay &&
                          locationSecurity.safetySurroundingAreaPedestriansDay
                            .value &&
                          locationSecurity.safetySurroundingAreaPedestriansDay
                            .isNA &&
                          !locationSecurity.safetySurroundingAreaPedestriansDay
                            .isNA.flag
                            ? locationSecurity
                                .safetySurroundingAreaPedestriansDay.value.value
                            : locationSecurity.safetySurroundingAreaPedestriansDay &&
                              locationSecurity
                                .safetySurroundingAreaPedestriansDay.isNA &&
                              locationSecurity
                                .safetySurroundingAreaPedestriansDay.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "safetySurroundingAreaPedestriansDay"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.safetySurroundingAreaPedestriansDay &&
                          locationSecurity.safetySurroundingAreaPedestriansDay &&
                          locationSecurity.safetySurroundingAreaPedestriansDay
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .safetySurroundingAreaPedestriansDay.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How would you rate the safety of the area surrounding
                          the school for pedestrian traffic during the night?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.safetySurroundingAreaPedestriansNight &&
                          locationSecurity.safetySurroundingAreaPedestriansNight
                            .value &&
                          locationSecurity.safetySurroundingAreaPedestriansNight
                            .isNA &&
                          !locationSecurity
                            .safetySurroundingAreaPedestriansNight.isNA.flag
                            ? locationSecurity
                                .safetySurroundingAreaPedestriansNight.value
                                .value
                            : locationSecurity.safetySurroundingAreaPedestriansNight &&
                              locationSecurity
                                .safetySurroundingAreaPedestriansNight.isNA &&
                              locationSecurity
                                .safetySurroundingAreaPedestriansNight.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "safetySurroundingAreaPedestriansNight"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.safetySurroundingAreaPedestriansNight &&
                          locationSecurity.safetySurroundingAreaPedestriansNight &&
                          locationSecurity.safetySurroundingAreaPedestriansNight
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .safetySurroundingAreaPedestriansNight.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How would you rate the safety of the area surrounding
                          the school for vehicular traffic during the day?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay
                            .value &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay
                            .isNA &&
                          !locationSecurity
                            .abilityToMoveByCarSafetlyDuringTheDay.isNA.flag
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheDay.value
                                .value
                            : locationSecurity.abilityToMoveByCarSafetlyDuringTheDay &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheDay.isNA &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheDay.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "abilityToMoveByCarSafetlyDuringTheDay"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheDay
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByCarSafetlyDuringTheDay.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How would you rate the safety of the area surrounding
                          the school for vehicular traffic during the night?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheNight &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyDuringTheNight.value &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyDuringTheNight.isNA &&
                          !locationSecurity
                            .abilityToMoveByCarSafetlyDuringTheNight.isNA.flag
                            ? locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheNight.value
                                .value
                            : locationSecurity.abilityToMoveByCarSafetlyDuringTheNight &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheNight.isNA &&
                              locationSecurity
                                .abilityToMoveByCarSafetlyDuringTheNight.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "abilityToMoveByCarSafetlyDuringTheNight"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheNight &&
                          locationSecurity.abilityToMoveByCarSafetlyDuringTheNight &&
                          locationSecurity
                            .abilityToMoveByCarSafetlyDuringTheNight.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .abilityToMoveByCarSafetlyDuringTheNight
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How would you rate the school's relationship and
                          acceptance within the surrounding community?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity &&
                          locationSecurity
                            .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                            .value &&
                          locationSecurity
                            .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                            .isNA &&
                          !locationSecurity
                            .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                            .isNA.flag
                            ? locationSecurity
                                .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                                .value.value
                            : locationSecurity.schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity &&
                              locationSecurity
                                .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                                .isNA &&
                              locationSecurity
                                .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity &&
                          locationSecurity.schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity &&
                          locationSecurity
                            .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .schoolRelationshipAndAcceptanceWithinTheSurroundingCommunity
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How suitable is the school's location for its function
                          as an educational institution?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution &&
                          locationSecurity
                            .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                            .value &&
                          locationSecurity
                            .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                            .isNA &&
                          !locationSecurity
                            .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                            .isNA.flag
                            ? locationSecurity
                                .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                                .value.value
                            : locationSecurity.schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution &&
                              locationSecurity
                                .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                                .isNA &&
                              locationSecurity
                                .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution &&
                          locationSecurity.schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution &&
                          locationSecurity
                            .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .schoolLocationSuitabilityForItsFunctionAsAnEducationalInstitution
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what extent are there known high-risk locations or
                          elements in close proximity to the school (i.e high
                          crime areas, dangerous roads, dangerous waterways,
                          forested areas etc)?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.knownHighRiskLocationsOrElementsCloseToTheSchool &&
                          locationSecurity
                            .knownHighRiskLocationsOrElementsCloseToTheSchool
                            .value &&
                          locationSecurity
                            .knownHighRiskLocationsOrElementsCloseToTheSchool
                            .isNA &&
                          !locationSecurity
                            .knownHighRiskLocationsOrElementsCloseToTheSchool
                            .isNA.flag
                            ? locationSecurity
                                .knownHighRiskLocationsOrElementsCloseToTheSchool
                                .value.value
                            : locationSecurity.knownHighRiskLocationsOrElementsCloseToTheSchool &&
                              locationSecurity
                                .knownHighRiskLocationsOrElementsCloseToTheSchool
                                .isNA &&
                              locationSecurity
                                .knownHighRiskLocationsOrElementsCloseToTheSchool
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "knownHighRiskLocationsOrElementsCloseToTheSchool"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.knownHighRiskLocationsOrElementsCloseToTheSchool &&
                          locationSecurity.knownHighRiskLocationsOrElementsCloseToTheSchool &&
                          locationSecurity
                            .knownHighRiskLocationsOrElementsCloseToTheSchool
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .knownHighRiskLocationsOrElementsCloseToTheSchool
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what degree are there adequate, demarcated and safe
                          drop-off and pick-up points for students?
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents &&
                          locationSecurity
                            .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                            .value &&
                          locationSecurity
                            .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                            .isNA &&
                          !locationSecurity
                            .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                            .isNA.flag
                            ? locationSecurity
                                .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                                .value.value
                            : locationSecurity.adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents &&
                              locationSecurity
                                .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                                .isNA &&
                              locationSecurity
                                .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            locationSecurity,
                            "adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents"
                          )}
                        </td>
                        <td>
                          {locationSecurity &&
                          locationSecurity.adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents &&
                          locationSecurity.adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents &&
                          locationSecurity
                            .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  locationSecurity
                                    .adequateDemarcatedAndSafeDropOffAndPickUpPointsForStudents
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Perimeter Protection</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How sufficient is the height of the external perimeter
                          fence or wall in deterring against the identified
                          threats?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate.value &&
                          perimeterProtection.heightOfFenceAppropriate.isNA &&
                          !perimeterProtection.heightOfFenceAppropriate.isNA
                            .flag
                            ? perimeterProtection.heightOfFenceAppropriate.value
                                .value
                            : perimeterProtection.heightOfFenceAppropriate &&
                              perimeterProtection.heightOfFenceAppropriate
                                .isNA &&
                              perimeterProtection.heightOfFenceAppropriate.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "heightOfFenceAppropriate"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate &&
                          perimeterProtection.heightOfFenceAppropriate.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.heightOfFenceAppropriate
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sturdy and durable is the material of the external
                          perimeter fence or wall in preventing breaches?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate
                            .value &&
                          perimeterProtection.structuralMaterialAppropriate
                            .isNA &&
                          !perimeterProtection.structuralMaterialAppropriate
                            .isNA.flag
                            ? perimeterProtection.structuralMaterialAppropriate
                                .value.value
                            : perimeterProtection.structuralMaterialAppropriate &&
                              perimeterProtection.structuralMaterialAppropriate
                                .isNA &&
                              perimeterProtection.structuralMaterialAppropriate
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "structuralMaterialAppropriate"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate &&
                          perimeterProtection.structuralMaterialAppropriate
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection
                                    .structuralMaterialAppropriate.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-maintained and in good repair is the
                          perimeter fence or wall?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodRepair &&
                          perimeterProtection.goodRepair.value &&
                          perimeterProtection.goodRepair.isNA &&
                          !perimeterProtection.goodRepair.isNA.flag
                            ? perimeterProtection.goodRepair.value.value
                            : perimeterProtection.goodRepair &&
                              perimeterProtection.goodRepair.isNA &&
                              perimeterProtection.goodRepair.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(perimeterProtection, "goodRepair")}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.goodRepair &&
                          perimeterProtection.goodRepair &&
                          perimeterProtection.goodRepair.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.goodRepair.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well does the landscaping around the external
                          perimeter fencing/wall prevent cover for potential
                          intruders and prevent climbing?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.landscapingPreventCover &&
                          perimeterProtection.landscapingPreventCover.value &&
                          perimeterProtection.landscapingPreventCover.isNA &&
                          !perimeterProtection.landscapingPreventCover.isNA.flag
                            ? perimeterProtection.landscapingPreventCover.value
                                .value
                            : perimeterProtection &&
                              perimeterProtection.landscapingPreventCover &&
                              perimeterProtection.landscapingPreventCover
                                .isNA &&
                              perimeterProtection.landscapingPreventCover.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "landscapingPreventCover"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.landscapingPreventCover &&
                          perimeterProtection.landscapingPreventCover &&
                          perimeterProtection.landscapingPreventCover.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.landscapingPreventCover
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-lit is the external perimeter to deter
                          unauthorized activity and provide coverage without
                          'dark spots'?
                        </td>
                        <td>
                          {getSecondRowText(perimeterProtection, "wellLit")}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.wellLit &&
                          perimeterProtection.wellLit &&
                          perimeterProtection.wellLit.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.wellLit.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are barriers, such as bollards, to
                          direct traffic?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.barriersToDirectTraffic &&
                          perimeterProtection.barriersToDirectTraffic.value &&
                          perimeterProtection.barriersToDirectTraffic.isNA &&
                          !perimeterProtection.barriersToDirectTraffic.isNA.flag
                            ? perimeterProtection.barriersToDirectTraffic.value
                                .value
                            : perimeterProtection.barriersToDirectTraffic &&
                              perimeterProtection.barriersToDirectTraffic
                                .isNA &&
                              perimeterProtection.barriersToDirectTraffic.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "barriersToDirectTraffic"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.barriersToDirectTraffic &&
                          perimeterProtection.barriersToDirectTraffic &&
                          perimeterProtection.barriersToDirectTraffic.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.barriersToDirectTraffic
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are barriers, such as bollards, to
                          prevent ramming of access gates?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.barriersToPreventRamming &&
                          perimeterProtection.barriersToPreventRamming.value &&
                          perimeterProtection.barriersToPreventRamming.isNA &&
                          !perimeterProtection.barriersToPreventRamming.isNA
                            .flag
                            ? perimeterProtection.barriersToPreventRamming.value
                                .value
                            : perimeterProtection.barriersToPreventRamming &&
                              perimeterProtection.barriersToPreventRamming
                                .isNA &&
                              perimeterProtection.barriersToPreventRamming.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "barriersToPreventRamming"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.barriersToPreventRamming &&
                          perimeterProtection.barriersToPreventRamming &&
                          perimeterProtection.barriersToPreventRamming.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.barriersToPreventRamming
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what degree are the signs along the perimeter clear
                          and visible indicating that the area is monitored and
                          secured?
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.signsAlongPerimeter &&
                          perimeterProtection.signsAlongPerimeter.value &&
                          perimeterProtection.signsAlongPerimeter.isNA &&
                          !perimeterProtection.signsAlongPerimeter.isNA.flag
                            ? perimeterProtection.signsAlongPerimeter.value
                                .value
                            : perimeterProtection.signsAlongPerimeter &&
                              perimeterProtection.signsAlongPerimeter.isNA &&
                              perimeterProtection.signsAlongPerimeter.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            perimeterProtection,
                            "signsAlongPerimeter"
                          )}
                        </td>
                        <td>
                          {perimeterProtection &&
                          perimeterProtection.signsAlongPerimeter &&
                          perimeterProtection.signsAlongPerimeter &&
                          perimeterProtection.signsAlongPerimeter.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  perimeterProtection.signsAlongPerimeter.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Access Control</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How consistently implemented are the school's access
                          control procedures during school hours?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.consistentlyImplementedAccessControlProceduresDuringOperationalHours &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                            .value &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                            .isNA &&
                          !accessControl
                            .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                            .isNA.flag
                            ? accessControl
                                .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                                .value.value
                            : accessControl.consistentlyImplementedAccessControlProceduresDuringOperationalHours &&
                              accessControl
                                .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                                .isNA &&
                              accessControl
                                .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "consistentlyImplementedAccessControlProceduresDuringOperationalHours"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.consistentlyImplementedAccessControlProceduresDuringOperationalHours &&
                          accessControl.consistentlyImplementedAccessControlProceduresDuringOperationalHours &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .consistentlyImplementedAccessControlProceduresDuringOperationalHours
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently implemented are the school's access
                          control procedures outside of school hours?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.consistentlyImplementedAccessControlProceduresOutsideOperationalHours &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                            .value &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                            .isNA &&
                          !accessControl
                            .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                            .isNA.flag
                            ? accessControl
                                .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                                .value.value
                            : accessControl.consistentlyImplementedAccessControlProceduresOutsideOperationalHours &&
                              accessControl
                                .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                                .isNA &&
                              accessControl
                                .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "consistentlyImplementedAccessControlProceduresOutsideOperationalHours"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.consistentlyImplementedAccessControlProceduresOutsideOperationalHours &&
                          accessControl.consistentlyImplementedAccessControlProceduresOutsideOperationalHours &&
                          accessControl
                            .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .consistentlyImplementedAccessControlProceduresOutsideOperationalHours
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well does the school provide dedicated pedestrian
                          entrances and exits to prevent potential hazards with
                          vehicles?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.dedicatedPedestrianEntrancesAndExits &&
                          accessControl.dedicatedPedestrianEntrancesAndExits
                            .value &&
                          accessControl.dedicatedPedestrianEntrancesAndExits
                            .isNA &&
                          !accessControl.dedicatedPedestrianEntrancesAndExits
                            .isNA.flag
                            ? accessControl.dedicatedPedestrianEntrancesAndExits
                                .value.value
                            : accessControl.dedicatedPedestrianEntrancesAndExits &&
                              accessControl.dedicatedPedestrianEntrancesAndExits
                                .isNA &&
                              accessControl.dedicatedPedestrianEntrancesAndExits
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "dedicatedPedestrianEntrancesAndExits"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.dedicatedPedestrianEntrancesAndExits &&
                          accessControl.dedicatedPedestrianEntrancesAndExits &&
                          accessControl.dedicatedPedestrianEntrancesAndExits
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .dedicatedPedestrianEntrancesAndExits.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently are visitors asked for the purpose of
                          their visit and is this confirmed with the appropriate
                          school personnel?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsAskedForPurposeOfVisit &&
                          accessControl.visitorsAskedForPurposeOfVisit.value &&
                          accessControl.visitorsAskedForPurposeOfVisit.isNA &&
                          !accessControl.visitorsAskedForPurposeOfVisit.isNA
                            .flag
                            ? accessControl.visitorsAskedForPurposeOfVisit.value
                                .value
                            : accessControl.visitorsAskedForPurposeOfVisit &&
                              accessControl.visitorsAskedForPurposeOfVisit
                                .isNA &&
                              accessControl.visitorsAskedForPurposeOfVisit.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "visitorsAskedForPurposeOfVisit"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsAskedForPurposeOfVisit &&
                          accessControl.visitorsAskedForPurposeOfVisit &&
                          accessControl.visitorsAskedForPurposeOfVisit.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl.visitorsAskedForPurposeOfVisit
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sufficient are the entry and exit points of the
                          external perimeter with facilitating emergency
                          evacuations (i.e several, clearly marked, and
                          accessible)?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.sufficientEntryAndExitPointsOfExternalPerimeter &&
                          accessControl
                            .sufficientEntryAndExitPointsOfExternalPerimeter
                            .value &&
                          accessControl
                            .sufficientEntryAndExitPointsOfExternalPerimeter
                            .isNA &&
                          !accessControl
                            .sufficientEntryAndExitPointsOfExternalPerimeter
                            .isNA.flag
                            ? accessControl
                                .sufficientEntryAndExitPointsOfExternalPerimeter
                                .value.value
                            : accessControl.sufficientEntryAndExitPointsOfExternalPerimeter &&
                              accessControl
                                .sufficientEntryAndExitPointsOfExternalPerimeter
                                .isNA &&
                              accessControl
                                .sufficientEntryAndExitPointsOfExternalPerimeter
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "sufficientEntryAndExitPointsOfExternalPerimeter"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.sufficientEntryAndExitPointsOfExternalPerimeter &&
                          accessControl.sufficientEntryAndExitPointsOfExternalPerimeter &&
                          accessControl
                            .sufficientEntryAndExitPointsOfExternalPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .sufficientEntryAndExitPointsOfExternalPerimeter
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently are visitors asked to provide
                          official photo ID as part of the check-in process, and
                          are these details recorded?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID
                            .value &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID
                            .isNA &&
                          !accessControl.visitorsAskedToProvideOfficialPhotoID
                            .isNA.flag
                            ? accessControl
                                .visitorsAskedToProvideOfficialPhotoID.value
                                .value
                            : accessControl.visitorsAskedToProvideOfficialPhotoID &&
                              accessControl
                                .visitorsAskedToProvideOfficialPhotoID.isNA &&
                              accessControl
                                .visitorsAskedToProvideOfficialPhotoID.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "visitorsAskedToProvideOfficialPhotoID"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID &&
                          accessControl.visitorsAskedToProvideOfficialPhotoID
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .visitorsAskedToProvideOfficialPhotoID.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are the facilities and equipment to
                          search people’s bags and personal items upon entry?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry &&
                          accessControl
                            .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                            .value &&
                          accessControl
                            .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                            .isNA &&
                          !accessControl
                            .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                            .isNA.flag
                            ? accessControl
                                .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                                .value.value
                            : accessControl.effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry &&
                              accessControl
                                .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                                .isNA &&
                              accessControl
                                .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry &&
                          accessControl.effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry &&
                          accessControl
                            .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .effectiveFacilitiesAndEquipmentToSearchBagsAndPersonalItemsUponEntry
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are visitors' bags or personal items
                          inspected before entering the school premises?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises &&
                          accessControl
                            .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                            .value &&
                          accessControl
                            .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                            .isNA &&
                          !accessControl
                            .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                            .isNA.flag
                            ? accessControl
                                .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                                .value.value
                            : accessControl.visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises &&
                              accessControl
                                .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                                .isNA &&
                              accessControl
                                .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises &&
                          accessControl.visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises &&
                          accessControl
                            .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .visitorsBagsOrPersonalItemsInspectedBeforeEnteringSchoolPremises
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effective is the school's system for issuing
                          visitor badges or passes?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveSchoolSystemForIssuingVisitorBadgesOrPasses &&
                          accessControl
                            .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                            .value &&
                          accessControl
                            .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                            .isNA &&
                          !accessControl
                            .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                            .isNA.flag
                            ? accessControl
                                .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                                .value.value
                            : accessControl.effectiveSchoolSystemForIssuingVisitorBadgesOrPasses &&
                              accessControl
                                .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                                .isNA &&
                              accessControl
                                .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "effectiveSchoolSystemForIssuingVisitorBadgesOrPasses"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveSchoolSystemForIssuingVisitorBadgesOrPasses &&
                          accessControl.effectiveSchoolSystemForIssuingVisitorBadgesOrPasses &&
                          accessControl
                            .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .effectiveSchoolSystemForIssuingVisitorBadgesOrPasses
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effectively does the school provide dedicated
                          vehicle entry and exit points in appropriate
                          locations?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations &&
                          accessControl
                            .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                            .value &&
                          accessControl
                            .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                            .isNA &&
                          !accessControl
                            .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                            .isNA.flag
                            ? accessControl
                                .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                                .value.value
                            : accessControl.effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations &&
                              accessControl
                                .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                                .isNA &&
                              accessControl
                                .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations &&
                          accessControl.effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations &&
                          accessControl
                            .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .effectiveDedicatedVehicleEntryAndExitPointsInAppropriateLocations
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are vehicles inspected before
                          entering the school premises?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.vehiclesInspectedBeforeEnteringSchoolPremises &&
                          accessControl
                            .vehiclesInspectedBeforeEnteringSchoolPremises
                            .value &&
                          accessControl
                            .vehiclesInspectedBeforeEnteringSchoolPremises
                            .isNA &&
                          !accessControl
                            .vehiclesInspectedBeforeEnteringSchoolPremises.isNA
                            .flag
                            ? accessControl
                                .vehiclesInspectedBeforeEnteringSchoolPremises
                                .value.value
                            : accessControl.vehiclesInspectedBeforeEnteringSchoolPremises &&
                              accessControl
                                .vehiclesInspectedBeforeEnteringSchoolPremises
                                .isNA &&
                              accessControl
                                .vehiclesInspectedBeforeEnteringSchoolPremises
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "vehiclesInspectedBeforeEnteringSchoolPremises"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.vehiclesInspectedBeforeEnteringSchoolPremises &&
                          accessControl.vehiclesInspectedBeforeEnteringSchoolPremises &&
                          accessControl
                            .vehiclesInspectedBeforeEnteringSchoolPremises
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .vehiclesInspectedBeforeEnteringSchoolPremises
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effective is the school's system for issuing known
                          vehicle badges or passes?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses &&
                          accessControl
                            .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                            .value &&
                          accessControl
                            .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                            .isNA &&
                          !accessControl
                            .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                            .isNA.flag
                            ? accessControl
                                .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                                .value.value
                            : accessControl.effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses &&
                              accessControl
                                .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                                .isNA &&
                              accessControl
                                .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses &&
                          accessControl.effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses &&
                          accessControl
                            .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .effectiveSchoolSystemForIssuingKnownVehicleBadgesOrPasses
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are the school's procedures for
                          receiving deliveries being followed?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.proceduresForReceivingDeliveriesBeingFollowed &&
                          accessControl
                            .proceduresForReceivingDeliveriesBeingFollowed
                            .value &&
                          accessControl
                            .proceduresForReceivingDeliveriesBeingFollowed
                            .isNA &&
                          !accessControl
                            .proceduresForReceivingDeliveriesBeingFollowed.isNA
                            .flag
                            ? accessControl
                                .proceduresForReceivingDeliveriesBeingFollowed
                                .value.value
                            : accessControl.proceduresForReceivingDeliveriesBeingFollowed &&
                              accessControl
                                .proceduresForReceivingDeliveriesBeingFollowed
                                .isNA &&
                              accessControl
                                .proceduresForReceivingDeliveriesBeingFollowed
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "proceduresForReceivingDeliveriesBeingFollowed"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.proceduresForReceivingDeliveriesBeingFollowed &&
                          accessControl.proceduresForReceivingDeliveriesBeingFollowed &&
                          accessControl
                            .proceduresForReceivingDeliveriesBeingFollowed
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .proceduresForReceivingDeliveriesBeingFollowed
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are the school's procedures for
                          admitting contractors being followed?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.proceduresForAdmittingContractorsBeingFollowed &&
                          accessControl
                            .proceduresForAdmittingContractorsBeingFollowed
                            .value &&
                          accessControl
                            .proceduresForAdmittingContractorsBeingFollowed
                            .isNA &&
                          !accessControl
                            .proceduresForAdmittingContractorsBeingFollowed.isNA
                            .flag
                            ? accessControl
                                .proceduresForAdmittingContractorsBeingFollowed
                                .value.value
                            : accessControl.proceduresForAdmittingContractorsBeingFollowed &&
                              accessControl
                                .proceduresForAdmittingContractorsBeingFollowed
                                .isNA &&
                              accessControl
                                .proceduresForAdmittingContractorsBeingFollowed
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "proceduresForAdmittingContractorsBeingFollowed"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.proceduresForAdmittingContractorsBeingFollowed &&
                          accessControl.proceduresForAdmittingContractorsBeingFollowed &&
                          accessControl
                            .proceduresForAdmittingContractorsBeingFollowed
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .proceduresForAdmittingContractorsBeingFollowed
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How prominently does the school display signs
                          indicating prohibited items on campus?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus
                            .value &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus
                            .isNA &&
                          !accessControl.signsIndicatingProhibitedItemsOnCampus
                            .isNA.flag
                            ? accessControl
                                .signsIndicatingProhibitedItemsOnCampus.value
                                .value
                            : accessControl.signsIndicatingProhibitedItemsOnCampus &&
                              accessControl
                                .signsIndicatingProhibitedItemsOnCampus.isNA &&
                              accessControl
                                .signsIndicatingProhibitedItemsOnCampus.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "signsIndicatingProhibitedItemsOnCampus"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus &&
                          accessControl.signsIndicatingProhibitedItemsOnCampus
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .signsIndicatingProhibitedItemsOnCampus
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How clearly are signs displayed at all access points
                          indicating that it is a private property?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.signsDisplayedAtAllAccessPointsIndicatingPrivateProperty &&
                          accessControl
                            .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                            .value &&
                          accessControl
                            .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                            .isNA &&
                          !accessControl
                            .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                            .isNA.flag
                            ? accessControl
                                .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                                .value.value
                            : accessControl.signsDisplayedAtAllAccessPointsIndicatingPrivateProperty &&
                              accessControl
                                .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                                .isNA &&
                              accessControl
                                .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "signsDisplayedAtAllAccessPointsIndicatingPrivateProperty"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.signsDisplayedAtAllAccessPointsIndicatingPrivateProperty &&
                          accessControl.signsDisplayedAtAllAccessPointsIndicatingPrivateProperty &&
                          accessControl
                            .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .signsDisplayedAtAllAccessPointsIndicatingPrivateProperty
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effectively do the school's access control
                          procedures include a system to monitor and control the
                          removal of school property?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty &&
                          accessControl
                            .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                            .value &&
                          accessControl
                            .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                            .isNA &&
                          !accessControl
                            .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                            .isNA.flag
                            ? accessControl
                                .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                                .value.value
                            : accessControl.accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty &&
                              accessControl
                                .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                                .isNA &&
                              accessControl
                                .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty &&
                          accessControl.accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty &&
                          accessControl
                            .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .accessControlProceduresIncludeSystemToMonitorAndControlRemovalOfSchoolProperty
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How frequently does the school conduct regular audits
                          of its access control systems and procedures to ensure
                          their effectiveness?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness &&
                          accessControl
                            .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                            .value &&
                          accessControl
                            .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                            .isNA &&
                          !accessControl
                            .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                            .isNA.flag
                            ? accessControl
                                .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                                .value.value
                            : accessControl.regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness &&
                              accessControl
                                .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                                .isNA &&
                              accessControl
                                .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness &&
                          accessControl.regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness &&
                          accessControl
                            .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .regularAuditsOfAccessControlSystemsAndProceduresToEnsureEffectiveness
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effective is the school's visitor management
                          system in tracking and managing visitors on campus?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorManagementSystemInTrackingAndManagingVisitorsOnCampus &&
                          accessControl
                            .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                            .value &&
                          accessControl
                            .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                            .isNA &&
                          !accessControl
                            .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                            .isNA.flag
                            ? accessControl
                                .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                                .value.value
                            : accessControl.visitorManagementSystemInTrackingAndManagingVisitorsOnCampus &&
                              accessControl
                                .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                                .isNA &&
                              accessControl
                                .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "visitorManagementSystemInTrackingAndManagingVisitorsOnCampus"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.visitorManagementSystemInTrackingAndManagingVisitorsOnCampus &&
                          accessControl.visitorManagementSystemInTrackingAndManagingVisitorsOnCampus &&
                          accessControl
                            .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .visitorManagementSystemInTrackingAndManagingVisitorsOnCampus
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are photo identification badges
                          issued to all faculty, students, and staff and
                          required for access to school premises?
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises &&
                          accessControl
                            .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                            .value &&
                          accessControl
                            .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                            .isNA &&
                          !accessControl
                            .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                            .isNA.flag
                            ? accessControl
                                .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                                .value.value
                            : accessControl.photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises &&
                              accessControl
                                .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                                .isNA &&
                              accessControl
                                .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            accessControl,
                            "photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises"
                          )}
                        </td>
                        <td>
                          {accessControl &&
                          accessControl.photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises &&
                          accessControl.photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises &&
                          accessControl
                            .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  accessControl
                                    .photoIdentificationBadgesIssuedToAllFacultyStudentsAndStaffAndRequiredForAccessToSchoolPremises
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Structural Resistance and Protection</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How adequate is the distance between the school
                          building(s) and the external perimeter in terms of
                          enhancing security? (Consider 100m or more as highly
                          secure). Note: Only applicable if threats involving an
                          explosion have been listed
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          structuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .value &&
                          structuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA &&
                          !structuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .isNA.flag
                            ? structuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .value.value
                            : structuralResistanceAndProtection &&
                              structuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                              structuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .isNA &&
                              structuralResistanceAndProtection
                                .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "adequateDistanceBetweenJamatkhanaAndExternalPremesis"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          structuralResistanceAndProtection.adequateDistanceBetweenJamatkhanaAndExternalPremesis &&
                          structuralResistanceAndProtection
                            .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .adequateDistanceBetweenJamatkhanaAndExternalPremesis
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How robust are the materials (e.g., concrete, brick,
                          steel) used in the construction of the school
                          buildings against an explosive blast? Note: Only
                          applicable if threats involving an explosion have been
                          listed
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          structuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.value &&
                          structuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA &&
                          !structuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.isNA.flag
                            ? structuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.value
                                .value
                            : structuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                              structuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.isNA &&
                              structuralResistanceAndProtection
                                .jamatkahanConstructedWithRobustMaterial.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "jamatkahanConstructedWithRobustMaterial"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          structuralResistanceAndProtection.jamatkahanConstructedWithRobustMaterial &&
                          structuralResistanceAndProtection
                            .jamatkahanConstructedWithRobustMaterial.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .jamatkahanConstructedWithRobustMaterial
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what extent do the school buildings adhere to local
                          and national building codes for earthquake resistance
                          and withstand seismic activity?
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          structuralResistanceAndProtection
                            .adequateToWithstandEarthquake.value &&
                          structuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA &&
                          !structuralResistanceAndProtection
                            .adequateToWithstandEarthquake.isNA.flag
                            ? structuralResistanceAndProtection
                                .adequateToWithstandEarthquake.value.value
                            : structuralResistanceAndProtection.adequateToWithstandEarthquake &&
                              structuralResistanceAndProtection
                                .adequateToWithstandEarthquake.isNA &&
                              structuralResistanceAndProtection
                                .adequateToWithstandEarthquake.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "adequateToWithstandEarthquake"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          structuralResistanceAndProtection.adequateToWithstandEarthquake &&
                          structuralResistanceAndProtection
                            .adequateToWithstandEarthquake.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .adequateToWithstandEarthquake.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well do the school buildings incorporate
                          protective measures against potential external
                          threats, such as blast-resistant structures or walls?
                          Note: Only applicable if threats involving an
                          explosion have been listed
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.blastProtectionMeasure &&
                          structuralResistanceAndProtection
                            .blastProtectionMeasure.value &&
                          structuralResistanceAndProtection
                            .blastProtectionMeasure.isNA &&
                          !structuralResistanceAndProtection
                            .blastProtectionMeasure.isNA.flag
                            ? structuralResistanceAndProtection
                                .blastProtectionMeasure.value.value
                            : structuralResistanceAndProtection.blastProtectionMeasure &&
                              structuralResistanceAndProtection
                                .blastProtectionMeasure.isNA &&
                              structuralResistanceAndProtection
                                .blastProtectionMeasure.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "blastProtectionMeasure"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.blastProtectionMeasure &&
                          structuralResistanceAndProtection.blastProtectionMeasure &&
                          structuralResistanceAndProtection
                            .blastProtectionMeasure.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .blastProtectionMeasure.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what extent are the windows in the school buildings
                          treated or protected with Shatter Resistant Film (SRF)
                          or similar safety measures? Note: Only applicable if
                          threats involving an explosion have been listed
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.glassInBuildingTreatedWithSRF &&
                          structuralResistanceAndProtection
                            .glassInBuildingTreatedWithSRF.value &&
                          structuralResistanceAndProtection
                            .glassInBuildingTreatedWithSRF.isNA &&
                          !structuralResistanceAndProtection
                            .glassInBuildingTreatedWithSRF.isNA.flag
                            ? structuralResistanceAndProtection
                                .glassInBuildingTreatedWithSRF.value.value
                            : structuralResistanceAndProtection.glassInBuildingTreatedWithSRF &&
                              structuralResistanceAndProtection
                                .glassInBuildingTreatedWithSRF.isNA &&
                              structuralResistanceAndProtection
                                .glassInBuildingTreatedWithSRF.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "glassInBuildingTreatedWithSRF"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.glassInBuildingTreatedWithSRF &&
                          structuralResistanceAndProtection.glassInBuildingTreatedWithSRF &&
                          structuralResistanceAndProtection
                            .glassInBuildingTreatedWithSRF.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .glassInBuildingTreatedWithSRF.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How capable are the roofs of the school buildings in
                          withstanding heavy weather conditions, such as snow,
                          hail, and high winds?
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.roofCapableOfWithstandingHeavyWeatherConditions &&
                          structuralResistanceAndProtection
                            .roofCapableOfWithstandingHeavyWeatherConditions
                            .value &&
                          structuralResistanceAndProtection
                            .roofCapableOfWithstandingHeavyWeatherConditions
                            .isNA &&
                          !structuralResistanceAndProtection
                            .roofCapableOfWithstandingHeavyWeatherConditions
                            .isNA.flag
                            ? structuralResistanceAndProtection
                                .roofCapableOfWithstandingHeavyWeatherConditions
                                .value.value
                            : structuralResistanceAndProtection.roofCapableOfWithstandingHeavyWeatherConditions &&
                              structuralResistanceAndProtection
                                .roofCapableOfWithstandingHeavyWeatherConditions
                                .isNA &&
                              structuralResistanceAndProtection
                                .roofCapableOfWithstandingHeavyWeatherConditions
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            structuralResistanceAndProtection,
                            "roofCapableOfWithstandingHeavyWeatherConditions"
                          )}
                        </td>
                        <td>
                          {structuralResistanceAndProtection &&
                          structuralResistanceAndProtection.roofCapableOfWithstandingHeavyWeatherConditions &&
                          structuralResistanceAndProtection.roofCapableOfWithstandingHeavyWeatherConditions &&
                          structuralResistanceAndProtection
                            .roofCapableOfWithstandingHeavyWeatherConditions
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  structuralResistanceAndProtection
                                    .roofCapableOfWithstandingHeavyWeatherConditions
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Classroom Security</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How well-established are safe zones or protection
                          areas in each classroom for potential emergency
                          situations? (Note: only applicable if Active Shooter
                          is listed as a Threat).
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellEstablishedSafeZones &&
                          classroomSecurity.wellEstablishedSafeZones.value &&
                          classroomSecurity.wellEstablishedSafeZones.isNA &&
                          !classroomSecurity.wellEstablishedSafeZones.isNA.flag
                            ? classroomSecurity.wellEstablishedSafeZones.value
                                .value
                            : classroomSecurity.wellEstablishedSafeZones &&
                              classroomSecurity.wellEstablishedSafeZones.isNA &&
                              classroomSecurity.wellEstablishedSafeZones.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "wellEstablishedSafeZones"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellEstablishedSafeZones &&
                          classroomSecurity.wellEstablishedSafeZones &&
                          classroomSecurity.wellEstablishedSafeZones.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity.wellEstablishedSafeZones
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How reliable is the two-way communication system in
                          each classroom for instant communication with the
                          school's main office or security personnel?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.reliableTwoWayCommunicationSystem &&
                          classroomSecurity.reliableTwoWayCommunicationSystem
                            .value &&
                          classroomSecurity.reliableTwoWayCommunicationSystem
                            .isNA &&
                          !classroomSecurity.reliableTwoWayCommunicationSystem
                            .isNA.flag
                            ? classroomSecurity
                                .reliableTwoWayCommunicationSystem.value.value
                            : classroomSecurity.reliableTwoWayCommunicationSystem &&
                              classroomSecurity
                                .reliableTwoWayCommunicationSystem.isNA &&
                              classroomSecurity
                                .reliableTwoWayCommunicationSystem.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "reliableTwoWayCommunicationSystem"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.reliableTwoWayCommunicationSystem &&
                          classroomSecurity.reliableTwoWayCommunicationSystem &&
                          classroomSecurity.reliableTwoWayCommunicationSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .reliableTwoWayCommunicationSystem.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How capable are the classroom doors of being locked
                          from the inside to provide a secure environment in
                          case of a lockdown situation?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.capableOfBeingLockedFromInside &&
                          classroomSecurity.capableOfBeingLockedFromInside
                            .value &&
                          classroomSecurity.capableOfBeingLockedFromInside
                            .isNA &&
                          !classroomSecurity.capableOfBeingLockedFromInside.isNA
                            .flag
                            ? classroomSecurity.capableOfBeingLockedFromInside
                                .value.value
                            : classroomSecurity.capableOfBeingLockedFromInside &&
                              classroomSecurity.capableOfBeingLockedFromInside
                                .isNA &&
                              classroomSecurity.capableOfBeingLockedFromInside
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "capableOfBeingLockedFromInside"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.capableOfBeingLockedFromInside &&
                          classroomSecurity.capableOfBeingLockedFromInside &&
                          classroomSecurity.capableOfBeingLockedFromInside
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .capableOfBeingLockedFromInside.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well can windows on classroom doors be covered in
                          a security situation to obstruct visibility from
                          outside?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation
                            .value &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation
                            .isNA &&
                          !classroomSecurity
                            .wellWindowCoveredInSecuritySituation.isNA.flag
                            ? classroomSecurity
                                .wellWindowCoveredInSecuritySituation.value
                                .value
                            : classroomSecurity.wellWindowCoveredInSecuritySituation &&
                              classroomSecurity
                                .wellWindowCoveredInSecuritySituation.isNA &&
                              classroomSecurity
                                .wellWindowCoveredInSecuritySituation.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "wellWindowCoveredInSecuritySituation"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation &&
                          classroomSecurity.wellWindowCoveredInSecuritySituation
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .wellWindowCoveredInSecuritySituation.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sufficient is the aisle space in classrooms to
                          allow for quick exits in case of emergencies?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms
                            .value &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms
                            .isNA &&
                          !classroomSecurity.sufficientAisleSpaceInClassrooms
                            .isNA.flag
                            ? classroomSecurity.sufficientAisleSpaceInClassrooms
                                .value.value
                            : classroomSecurity.sufficientAisleSpaceInClassrooms &&
                              classroomSecurity.sufficientAisleSpaceInClassrooms
                                .isNA &&
                              classroomSecurity.sufficientAisleSpaceInClassrooms
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "sufficientAisleSpaceInClassrooms"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms &&
                          classroomSecurity.sufficientAisleSpaceInClassrooms
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .sufficientAisleSpaceInClassrooms.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly are class rosters reviewed and updated
                          to keep track of student attendance and presence?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.regularlyReviewedClassRosters &&
                          classroomSecurity.regularlyReviewedClassRosters
                            .value &&
                          classroomSecurity.regularlyReviewedClassRosters
                            .isNA &&
                          !classroomSecurity.regularlyReviewedClassRosters.isNA
                            .flag
                            ? classroomSecurity.regularlyReviewedClassRosters
                                .value.value
                            : classroomSecurity.regularlyReviewedClassRosters &&
                              classroomSecurity.regularlyReviewedClassRosters
                                .isNA &&
                              classroomSecurity.regularlyReviewedClassRosters
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "regularlyReviewedClassRosters"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.regularlyReviewedClassRosters &&
                          classroomSecurity.regularlyReviewedClassRosters &&
                          classroomSecurity.regularlyReviewedClassRosters
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .regularlyReviewedClassRosters.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How consistently are all classrooms and bathrooms
                          locked and secured after the school day?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.consistentlyLockedAndSecuredAfterSchoolDay &&
                          classroomSecurity
                            .consistentlyLockedAndSecuredAfterSchoolDay.value &&
                          classroomSecurity
                            .consistentlyLockedAndSecuredAfterSchoolDay.isNA &&
                          !classroomSecurity
                            .consistentlyLockedAndSecuredAfterSchoolDay.isNA
                            .flag
                            ? classroomSecurity
                                .consistentlyLockedAndSecuredAfterSchoolDay
                                .value.value
                            : classroomSecurity.consistentlyLockedAndSecuredAfterSchoolDay &&
                              classroomSecurity
                                .consistentlyLockedAndSecuredAfterSchoolDay
                                .isNA &&
                              classroomSecurity
                                .consistentlyLockedAndSecuredAfterSchoolDay.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "consistentlyLockedAndSecuredAfterSchoolDay"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.consistentlyLockedAndSecuredAfterSchoolDay &&
                          classroomSecurity.consistentlyLockedAndSecuredAfterSchoolDay &&
                          classroomSecurity
                            .consistentlyLockedAndSecuredAfterSchoolDay
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .consistentlyLockedAndSecuredAfterSchoolDay
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How secure are valuable items, such as instruments,
                          computers, and video equipment, in the classrooms?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.secureValuableItemsInClassrooms &&
                          classroomSecurity.secureValuableItemsInClassrooms
                            .value &&
                          classroomSecurity.secureValuableItemsInClassrooms
                            .isNA &&
                          !classroomSecurity.secureValuableItemsInClassrooms
                            .isNA.flag
                            ? classroomSecurity.secureValuableItemsInClassrooms
                                .value.value
                            : classroomSecurity.secureValuableItemsInClassrooms &&
                              classroomSecurity.secureValuableItemsInClassrooms
                                .isNA &&
                              classroomSecurity.secureValuableItemsInClassrooms
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "secureValuableItemsInClassrooms"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.secureValuableItemsInClassrooms &&
                          classroomSecurity.secureValuableItemsInClassrooms &&
                          classroomSecurity.secureValuableItemsInClassrooms
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .secureValuableItemsInClassrooms.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How clearly visible and properly lit are emergency
                          exit signs in classrooms?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms &&
                          classroomSecurity
                            .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                            .value &&
                          classroomSecurity
                            .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                            .isNA &&
                          !classroomSecurity
                            .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                            .isNA.flag
                            ? classroomSecurity
                                .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                                .value.value
                            : classroomSecurity.clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms &&
                              classroomSecurity
                                .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                                .isNA &&
                              classroomSecurity
                                .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms &&
                          classroomSecurity.clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms &&
                          classroomSecurity
                            .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .clearlyVisibleAndProperlyLitEmergencyExitSignsInClassrooms
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How well-equipped are classrooms with basic first aid
                          supplies for minor incidents?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellEquippedWithBasicFirstAidSuppliesForMinorIncidents &&
                          classroomSecurity
                            .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                            .value &&
                          classroomSecurity
                            .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                            .isNA &&
                          !classroomSecurity
                            .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                            .isNA.flag
                            ? classroomSecurity
                                .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                                .value.value
                            : classroomSecurity.wellEquippedWithBasicFirstAidSuppliesForMinorIncidents &&
                              classroomSecurity
                                .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                                .isNA &&
                              classroomSecurity
                                .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "wellEquippedWithBasicFirstAidSuppliesForMinorIncidents"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.wellEquippedWithBasicFirstAidSuppliesForMinorIncidents &&
                          classroomSecurity.wellEquippedWithBasicFirstAidSuppliesForMinorIncidents &&
                          classroomSecurity
                            .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .wellEquippedWithBasicFirstAidSuppliesForMinorIncidents
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How aware are all classroom occupants of the nearest
                          exit points and emergency evacuation routes from their
                          location?
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation &&
                          classroomSecurity
                            .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                            .value &&
                          classroomSecurity
                            .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                            .isNA &&
                          !classroomSecurity
                            .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                            .isNA.flag
                            ? classroomSecurity
                                .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                                .value.value
                            : classroomSecurity.awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation &&
                              classroomSecurity
                                .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                                .isNA &&
                              classroomSecurity
                                .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            classroomSecurity,
                            "awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation"
                          )}
                        </td>
                        <td>
                          {classroomSecurity &&
                          classroomSecurity.awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation &&
                          classroomSecurity.awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation &&
                          classroomSecurity
                            .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  classroomSecurity
                                    .awareOfNearestExitPointsAndEmergencyEvacuationRoutesFromLocation
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Lockdown</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How capable is the school of initiating a complete
                          lockdown during times of emergency?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana.value &&
                          lockdown.canLockDownJamatkhana.isNA &&
                          !lockdown.canLockDownJamatkhana.isNA.flag
                            ? lockdown.canLockDownJamatkhana.value.value
                            : lockdown.canLockDownJamatkhana &&
                              lockdown.canLockDownJamatkhana.isNA &&
                              lockdown.canLockDownJamatkhana.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(lockdown, "canLockDownJamatkhana")}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana &&
                          lockdown.canLockDownJamatkhana.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.canLockDownJamatkhana.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How adequately trained are all faculty, staff, and
                          substitutes on the lockdown procedures and their
                          individual responsibilities?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan.value &&
                          lockdown.lockdownPlan.isNA &&
                          !lockdown.lockdownPlan.isNA.flag
                            ? lockdown.lockdownPlan.value.value
                            : lockdown.lockdownPlan &&
                              lockdown.lockdownPlan.isNA &&
                              lockdown.lockdownPlan.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>{getSecondRowText(lockdown, "lockdownPlan")}</td>
                        <td>
                          {lockdown &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan &&
                          lockdown.lockdownPlan.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(lockdown.lockdownPlan.image),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How well identified, equipped, and accessible are
                          specific safe areas or rooms within the school
                          premises for their intended purpose during a lockdown?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown.value &&
                          lockdown.volunteetPlannedForLockdown.isNA &&
                          !lockdown.volunteetPlannedForLockdown.isNA.flag
                            ? lockdown.volunteetPlannedForLockdown.value.value
                            : lockdown.volunteetPlannedForLockdown &&
                              lockdown.volunteetPlannedForLockdown.isNA &&
                              lockdown.volunteetPlannedForLockdown.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "volunteetPlannedForLockdown"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown &&
                          lockdown.volunteetPlannedForLockdown.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.volunteetPlannedForLockdown.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How frequently and effectively does the school conduct
                          lockdown drills involving all staff and students?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified.value &&
                          lockdown.safeSpaceIdentified.isNA &&
                          !lockdown.safeSpaceIdentified.isNA.flag
                            ? lockdown.safeSpaceIdentified.value.value
                            : lockdown.safeSpaceIdentified &&
                              lockdown.safeSpaceIdentified.isNA &&
                              lockdown.safeSpaceIdentified.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(lockdown, "safeSpaceIdentified")}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified &&
                          lockdown.safeSpaceIdentified.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.safeSpaceIdentified.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How frequently are drills reviewed and updated based
                          on feedback and changes in school infrastructure?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.frequencyOfDrills &&
                          lockdown.frequencyOfDrills.value &&
                          lockdown.frequencyOfDrills.isNA &&
                          !lockdown.frequencyOfDrills.isNA.flag
                            ? lockdown.frequencyOfDrills.value.value
                            : lockdown.frequencyOfDrills &&
                              lockdown.frequencyOfDrills.isNA &&
                              lockdown.frequencyOfDrills.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(lockdown, "frequencyOfDrills")}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.frequencyOfDrills &&
                          lockdown.frequencyOfDrills &&
                          lockdown.frequencyOfDrills.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.frequencyOfDrills.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effectively are lockdown procedures communicated
                          to students at age-appropriate levels, and how well
                          are they aware of their roles during a lockdown?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents
                            .value &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents
                            .isNA &&
                          !lockdown.effectivenessOfDrillsCommunicatedToStudents
                            .isNA.flag
                            ? lockdown
                                .effectivenessOfDrillsCommunicatedToStudents
                                .value.value
                            : lockdown.effectivenessOfDrillsCommunicatedToStudents &&
                              lockdown
                                .effectivenessOfDrillsCommunicatedToStudents
                                .isNA &&
                              lockdown
                                .effectivenessOfDrillsCommunicatedToStudents
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "effectivenessOfDrillsCommunicatedToStudents"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents &&
                          lockdown.effectivenessOfDrillsCommunicatedToStudents
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown
                                    .effectivenessOfDrillsCommunicatedToStudents
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How informed are parents and caregivers about the
                          school's lockdown procedures and their roles during
                          such situations?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.informedParentsAndCaregiversAboutLockdownProcedures &&
                          lockdown
                            .informedParentsAndCaregiversAboutLockdownProcedures
                            .value &&
                          lockdown
                            .informedParentsAndCaregiversAboutLockdownProcedures
                            .isNA &&
                          !lockdown
                            .informedParentsAndCaregiversAboutLockdownProcedures
                            .isNA.flag
                            ? lockdown
                                .informedParentsAndCaregiversAboutLockdownProcedures
                                .value.value
                            : lockdown.informedParentsAndCaregiversAboutLockdownProcedures &&
                              lockdown
                                .informedParentsAndCaregiversAboutLockdownProcedures
                                .isNA &&
                              lockdown
                                .informedParentsAndCaregiversAboutLockdownProcedures
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "informedParentsAndCaregiversAboutLockdownProcedures"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.informedParentsAndCaregiversAboutLockdownProcedures &&
                          lockdown.informedParentsAndCaregiversAboutLockdownProcedures &&
                          lockdown
                            .informedParentsAndCaregiversAboutLockdownProcedures
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown
                                    .informedParentsAndCaregiversAboutLockdownProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How effective is the school's system for accounting
                          for all students, staff, and visitors during a
                          lockdown?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown &&
                          lockdown
                            .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                            .value &&
                          lockdown
                            .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                            .isNA &&
                          !lockdown
                            .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                            .isNA.flag
                            ? lockdown
                                .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                                .value.value
                            : lockdown.effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown &&
                              lockdown
                                .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                                .isNA &&
                              lockdown
                                .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown &&
                          lockdown.effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown &&
                          lockdown
                            .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown
                                    .effectiveSystemForAccountingForAllStudentsStaffAndVisitorsDuringLockdown
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          To what extent does the school's lockdown plan include
                          procedures for individuals with disabilities or
                          special needs?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds &&
                          lockdown
                            .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                            .value &&
                          lockdown
                            .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                            .isNA &&
                          !lockdown
                            .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                            .isNA.flag
                            ? lockdown
                                .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                                .value.value
                            : lockdown.extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds &&
                              lockdown
                                .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                                .isNA &&
                              lockdown
                                .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds &&
                          lockdown.extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds &&
                          lockdown
                            .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown
                                    .extentOfLockdownPlanForIndividualsWithDisabilitiesOrSpecialNeeds
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How regularly tested and maintained are the school's
                          lockdown alert systems (like alarms or PA systems)?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.regularityOfLockdownAlertSystems &&
                          lockdown.regularityOfLockdownAlertSystems.value &&
                          lockdown.regularityOfLockdownAlertSystems.isNA &&
                          !lockdown.regularityOfLockdownAlertSystems.isNA.flag
                            ? lockdown.regularityOfLockdownAlertSystems.value
                                .value
                            : lockdown.regularityOfLockdownAlertSystems &&
                              lockdown.regularityOfLockdownAlertSystems.isNA &&
                              lockdown.regularityOfLockdownAlertSystems.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "regularityOfLockdownAlertSystems"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.regularityOfLockdownAlertSystems &&
                          lockdown.regularityOfLockdownAlertSystems &&
                          lockdown.regularityOfLockdownAlertSystems.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown.regularityOfLockdownAlertSystems
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          To what extent are lockdown plans shared and
                          communicated with emergency responders?
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.extentOfLockdownPlansSharedWithEmergencyResponders &&
                          lockdown
                            .extentOfLockdownPlansSharedWithEmergencyResponders
                            .value &&
                          lockdown
                            .extentOfLockdownPlansSharedWithEmergencyResponders
                            .isNA &&
                          !lockdown
                            .extentOfLockdownPlansSharedWithEmergencyResponders
                            .isNA.flag
                            ? lockdown
                                .extentOfLockdownPlansSharedWithEmergencyResponders
                                .value.value
                            : lockdown.extentOfLockdownPlansSharedWithEmergencyResponders &&
                              lockdown
                                .extentOfLockdownPlansSharedWithEmergencyResponders
                                .isNA &&
                              lockdown
                                .extentOfLockdownPlansSharedWithEmergencyResponders
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            lockdown,
                            "extentOfLockdownPlansSharedWithEmergencyResponders"
                          )}
                        </td>
                        <td>
                          {lockdown &&
                          lockdown.extentOfLockdownPlansSharedWithEmergencyResponders &&
                          lockdown.extentOfLockdownPlansSharedWithEmergencyResponders &&
                          lockdown
                            .extentOfLockdownPlansSharedWithEmergencyResponders
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  lockdown
                                    .extentOfLockdownPlansSharedWithEmergencyResponders
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Security Personnel</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Is the Premises protected by security guards?</td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.protectionSecurityGuard &&
                          securityPersonnel.protectionSecurityGuard.value &&
                          securityPersonnel.protectionSecurityGuard.isNA &&
                          !securityPersonnel.protectionSecurityGuard.isNA.flag
                            ? securityPersonnel.protectionSecurityGuard.value
                                .value
                            : securityPersonnel.protectionSecurityGuard &&
                              securityPersonnel.protectionSecurityGuard.isNA &&
                              securityPersonnel.protectionSecurityGuard.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "protectionSecurityGuard"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.protectionSecurityGuard &&
                          securityPersonnel.protectionSecurityGuard &&
                          securityPersonnel.protectionSecurityGuard.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.protectionSecurityGuard
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Is serviceDelivery managed through written SOPs and
                          are they clearly understood and rigidly followed?
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.performanceManagementSystem &&
                          securityPersonnel.performanceManagementSystem.value &&
                          securityPersonnel.performanceManagementSystem.isNA &&
                          !securityPersonnel.performanceManagementSystem.isNA
                            .flag
                            ? securityPersonnel.performanceManagementSystem
                                .value.value
                            : securityPersonnel.performanceManagementSystem &&
                              securityPersonnel.performanceManagementSystem
                                .isNA &&
                              securityPersonnel.performanceManagementSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>{getSecondRowText(securityPersonnel, "")}</td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.performanceManagementSystem &&
                          securityPersonnel.performanceManagementSystem &&
                          securityPersonnel.performanceManagementSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.performanceManagementSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Does each guard post have post-orders that are rigidly
                          followed?
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsInstructions &&
                          securityPersonnel.guardsInstructions.value &&
                          securityPersonnel.guardsInstructions.isNA &&
                          !securityPersonnel.guardsInstructions.isNA.flag
                            ? securityPersonnel.guardsInstructions.value.value
                            : securityPersonnel.guardsInstructions &&
                              securityPersonnel.guardsInstructions.isNA &&
                              securityPersonnel.guardsInstructions.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "guardsInstructions"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsInstructions &&
                          securityPersonnel.guardsInstructions &&
                          securityPersonnel.guardsInstructions.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.guardsInstructions.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Are there regular audit of the performance of the
                          security guard?
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.regularReview &&
                          securityPersonnel.regularReview.value &&
                          securityPersonnel.regularReview.isNA &&
                          !securityPersonnel.regularReview.isNA.flag
                            ? securityPersonnel.regularReview.value.value
                            : securityPersonnel.regularReview &&
                              securityPersonnel.regularReview.isNA &&
                              securityPersonnel.regularReview.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(securityPersonnel, "regularReview")}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.regularReview &&
                          securityPersonnel.regularReview &&
                          securityPersonnel.regularReview.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.regularReview.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Are there an adequate number of guards?</td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.adequateNumberOfGuards &&
                          securityPersonnel.adequateNumberOfGuards.value &&
                          securityPersonnel.adequateNumberOfGuards.isNA &&
                          !securityPersonnel.adequateNumberOfGuards.isNA.flag
                            ? securityPersonnel.adequateNumberOfGuards.value
                                .value
                            : securityPersonnel.adequateNumberOfGuards &&
                              securityPersonnel.adequateNumberOfGuards.isNA &&
                              securityPersonnel.adequateNumberOfGuards.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "adequateNumberOfGuards"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.adequateNumberOfGuards &&
                          securityPersonnel.adequateNumberOfGuards &&
                          securityPersonnel.adequateNumberOfGuards.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.adequateNumberOfGuards.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>Are the guards logically positioned / deployed?</td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsLogiciallyDeployed &&
                          securityPersonnel.guardsLogiciallyDeployed.value &&
                          securityPersonnel.guardsLogiciallyDeployed.isNA &&
                          !securityPersonnel.guardsLogiciallyDeployed.isNA.flag
                            ? securityPersonnel.guardsLogiciallyDeployed.value
                                .value
                            : securityPersonnel.guardsLogiciallyDeployed &&
                              securityPersonnel.guardsLogiciallyDeployed.isNA &&
                              securityPersonnel.guardsLogiciallyDeployed.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "guardsLogiciallyDeployed"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsLogiciallyDeployed &&
                          securityPersonnel.guardsLogiciallyDeployed &&
                          securityPersonnel.guardsLogiciallyDeployed.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.guardsLogiciallyDeployed
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          Do the quards have communication systems, Ideallv a
                          VAr or other hand-held radio system?
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsHaveCommuncationSystem &&
                          securityPersonnel.guardsHaveCommuncationSystem
                            .value &&
                          securityPersonnel.guardsHaveCommuncationSystem.isNA &&
                          !securityPersonnel.guardsHaveCommuncationSystem.isNA
                            .flag
                            ? securityPersonnel.guardsHaveCommuncationSystem
                                .value.value
                            : securityPersonnel.guardsHaveCommuncationSystem &&
                              securityPersonnel.guardsHaveCommuncationSystem
                                .isNA &&
                              securityPersonnel.guardsHaveCommuncationSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "guardsHaveCommuncationSystem"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.guardsHaveCommuncationSystem &&
                          securityPersonnel.guardsHaveCommuncationSystem &&
                          securityPersonnel.guardsHaveCommuncationSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.guardsHaveCommuncationSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Do the guards have correct/adequate equipment and
                          tools?
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.adequateAndEnoughSystem &&
                          securityPersonnel.adequateAndEnoughSystem.value &&
                          securityPersonnel.adequateAndEnoughSystem.isNA &&
                          !securityPersonnel.adequateAndEnoughSystem.isNA.flag
                            ? securityPersonnel.adequateAndEnoughSystem.value
                                .value
                            : securityPersonnel.adequateAndEnoughSystem &&
                              securityPersonnel.adequateAndEnoughSystem.isNA &&
                              securityPersonnel.adequateAndEnoughSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityPersonnel,
                            "adequateAndEnoughSystem"
                          )}
                        </td>
                        <td>
                          {securityPersonnel &&
                          securityPersonnel.adequateAndEnoughSystem &&
                          securityPersonnel.adequateAndEnoughSystem &&
                          securityPersonnel.adequateAndEnoughSystem.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityPersonnel.adequateAndEnoughSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
                <Col xl={12} className="mt-5">
                  <h4>Security Equipment</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How effective is the use of surveillance systems or
                          CCTV cameras for continuous monitoring and security?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.effectiveUseOfSurveillanceSystems &&
                          securityEquipment.effectiveUseOfSurveillanceSystems
                            .value &&
                          securityEquipment.effectiveUseOfSurveillanceSystems
                            .isNA &&
                          !securityEquipment.effectiveUseOfSurveillanceSystems
                            .isNA.flag
                            ? securityEquipment
                                .effectiveUseOfSurveillanceSystems.value.value
                            : securityEquipment.effectiveUseOfSurveillanceSystems &&
                              securityEquipment
                                .effectiveUseOfSurveillanceSystems.isNA &&
                              securityEquipment
                                .effectiveUseOfSurveillanceSystems.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "effectiveUseOfSurveillanceSystems"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.effectiveUseOfSurveillanceSystems &&
                          securityEquipment.effectiveUseOfSurveillanceSystems &&
                          securityEquipment.effectiveUseOfSurveillanceSystems
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .effectiveUseOfSurveillanceSystems.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well are the security cameras installed along the
                          perimeter functioning?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellFunctioningSecurityCamerasInstalledAlongPerimeter &&
                          securityEquipment
                            .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                            .value &&
                          securityEquipment
                            .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                            .isNA &&
                          !securityEquipment
                            .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                            .isNA.flag
                            ? securityEquipment
                                .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                                .value.value
                            : securityEquipment.wellFunctioningSecurityCamerasInstalledAlongPerimeter &&
                              securityEquipment
                                .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                                .isNA &&
                              securityEquipment
                                .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "wellFunctioningSecurityCamerasInstalledAlongPerimeter"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellFunctioningSecurityCamerasInstalledAlongPerimeter &&
                          securityEquipment.wellFunctioningSecurityCamerasInstalledAlongPerimeter &&
                          securityEquipment
                            .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .wellFunctioningSecurityCamerasInstalledAlongPerimeter
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well are the school's video security systems
                          connected to the building's emergency power supply,
                          ensuring functionality even during power outages?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.videoSecuritySystemsConnectedToEmergencyPowerSupply &&
                          securityEquipment
                            .videoSecuritySystemsConnectedToEmergencyPowerSupply
                            .value &&
                          securityEquipment
                            .videoSecuritySystemsConnectedToEmergencyPowerSupply
                            .isNA &&
                          !securityEquipment
                            .videoSecuritySystemsConnectedToEmergencyPowerSupply
                            .isNA.flag
                            ? securityEquipment
                                .videoSecuritySystemsConnectedToEmergencyPowerSupply
                                .value.value
                            : securityEquipment.videoSecuritySystemsConnectedToEmergencyPowerSupply &&
                              securityEquipment
                                .videoSecuritySystemsConnectedToEmergencyPowerSupply
                                .isNA &&
                              securityEquipment
                                .videoSecuritySystemsConnectedToEmergencyPowerSupply
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "videoSecuritySystemsConnectedToEmergencyPowerSupply"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.videoSecuritySystemsConnectedToEmergencyPowerSupply &&
                          securityEquipment.videoSecuritySystemsConnectedToEmergencyPowerSupply &&
                          securityEquipment
                            .videoSecuritySystemsConnectedToEmergencyPowerSupply
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .videoSecuritySystemsConnectedToEmergencyPowerSupply
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-connected is the school's alarm system to
                          local law enforcement for immediate response during
                          emergencies?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellConnectedAlarmSystemToLawEnforcement &&
                          securityEquipment
                            .wellConnectedAlarmSystemToLawEnforcement.value &&
                          securityEquipment
                            .wellConnectedAlarmSystemToLawEnforcement.isNA &&
                          !securityEquipment
                            .wellConnectedAlarmSystemToLawEnforcement.isNA.flag
                            ? securityEquipment
                                .wellConnectedAlarmSystemToLawEnforcement.value
                                .value
                            : securityEquipment.wellConnectedAlarmSystemToLawEnforcement &&
                              securityEquipment
                                .wellConnectedAlarmSystemToLawEnforcement
                                .isNA &&
                              securityEquipment
                                .wellConnectedAlarmSystemToLawEnforcement.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "wellConnectedAlarmSystemToLawEnforcement"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellConnectedAlarmSystemToLawEnforcement &&
                          securityEquipment.wellConnectedAlarmSystemToLawEnforcement &&
                          securityEquipment
                            .wellConnectedAlarmSystemToLawEnforcement.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .wellConnectedAlarmSystemToLawEnforcement
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well does the school retain security camera tapes
                          or digital recordings for a specified period of time
                          that complies with local regulations and best
                          practices?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.schoolRetainSecurityCameraTapesOrDigitalRecordings &&
                          securityEquipment
                            .schoolRetainSecurityCameraTapesOrDigitalRecordings
                            .value &&
                          securityEquipment
                            .schoolRetainSecurityCameraTapesOrDigitalRecordings
                            .isNA &&
                          !securityEquipment
                            .schoolRetainSecurityCameraTapesOrDigitalRecordings
                            .isNA.flag
                            ? securityEquipment
                                .schoolRetainSecurityCameraTapesOrDigitalRecordings
                                .value.value
                            : securityEquipment.schoolRetainSecurityCameraTapesOrDigitalRecordings &&
                              securityEquipment
                                .schoolRetainSecurityCameraTapesOrDigitalRecordings
                                .isNA &&
                              securityEquipment
                                .schoolRetainSecurityCameraTapesOrDigitalRecordings
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "schoolRetainSecurityCameraTapesOrDigitalRecordings"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.schoolRetainSecurityCameraTapesOrDigitalRecordings &&
                          securityEquipment.schoolRetainSecurityCameraTapesOrDigitalRecordings &&
                          securityEquipment
                            .schoolRetainSecurityCameraTapesOrDigitalRecordings
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .schoolRetainSecurityCameraTapesOrDigitalRecordings
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly are all emergency response equipment and
                          supplies checked and maintained?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.regularlyCheckedEmergencyResponseEquipmentAndSupplies &&
                          securityEquipment
                            .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                            .value &&
                          securityEquipment
                            .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                            .isNA &&
                          !securityEquipment
                            .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                            .isNA.flag
                            ? securityEquipment
                                .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                                .value.value
                            : securityEquipment.regularlyCheckedEmergencyResponseEquipmentAndSupplies &&
                              securityEquipment
                                .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                                .isNA &&
                              securityEquipment
                                .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "regularlyCheckedEmergencyResponseEquipmentAndSupplies"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.regularlyCheckedEmergencyResponseEquipmentAndSupplies &&
                          securityEquipment.regularlyCheckedEmergencyResponseEquipmentAndSupplies &&
                          securityEquipment
                            .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .regularlyCheckedEmergencyResponseEquipmentAndSupplies
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sufficient is the school's backup utility service
                          for emergency needs?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.sufficientBackupUtilityServiceForEmergencyNeeds &&
                          securityEquipment
                            .sufficientBackupUtilityServiceForEmergencyNeeds
                            .value &&
                          securityEquipment
                            .sufficientBackupUtilityServiceForEmergencyNeeds
                            .isNA &&
                          !securityEquipment
                            .sufficientBackupUtilityServiceForEmergencyNeeds
                            .isNA.flag
                            ? securityEquipment
                                .sufficientBackupUtilityServiceForEmergencyNeeds
                                .value.value
                            : securityEquipment.sufficientBackupUtilityServiceForEmergencyNeeds &&
                              securityEquipment
                                .sufficientBackupUtilityServiceForEmergencyNeeds
                                .isNA &&
                              securityEquipment
                                .sufficientBackupUtilityServiceForEmergencyNeeds
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "sufficientBackupUtilityServiceForEmergencyNeeds"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.sufficientBackupUtilityServiceForEmergencyNeeds &&
                          securityEquipment.sufficientBackupUtilityServiceForEmergencyNeeds &&
                          securityEquipment
                            .sufficientBackupUtilityServiceForEmergencyNeeds
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .sufficientBackupUtilityServiceForEmergencyNeeds
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and adequate is the CCTV or camera
                          system monitoring the school's access control points,
                          including entrances and exits?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints &&
                          securityEquipment
                            .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                            .value &&
                          securityEquipment
                            .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                            .isNA &&
                          !securityEquipment
                            .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                            .isNA.flag
                            ? securityEquipment
                                .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                                .value.value
                            : securityEquipment.functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints &&
                              securityEquipment
                                .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                                .isNA &&
                              securityEquipment
                                .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints &&
                          securityEquipment.functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints &&
                          securityEquipment
                            .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .functionalAndAdequateCCTVOrCameraSystemMonitoringAccessControlPoints
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-monitored is the interior of the school
                          compound by a functional and adequate CCTV or camera
                          system?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem &&
                          securityEquipment
                            .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                            .value &&
                          securityEquipment
                            .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                            .isNA &&
                          !securityEquipment
                            .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                            .isNA.flag
                            ? securityEquipment
                                .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                                .value.value
                            : securityEquipment.wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem &&
                              securityEquipment
                                .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                                .isNA &&
                              securityEquipment
                                .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem &&
                          securityEquipment.wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem &&
                          securityEquipment
                            .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .wellMonitoredInteriorOfSchoolCompoundByFunctionalAndAdequateCCTVOrCameraSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-equipped and dedicated is the control room
                          for monitoring the CCTV system?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          securityEquipment
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .value &&
                          securityEquipment
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .isNA &&
                          !securityEquipment
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .isNA.flag
                            ? securityEquipment
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .value.value
                            : securityEquipment.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                              securityEquipment
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .isNA &&
                              securityEquipment
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          securityEquipment.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          securityEquipment
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently is the CCTV system actively monitored
                          (24/7)?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.consistentlyCCTVSystemActivelyMonitored &&
                          securityEquipment
                            .consistentlyCCTVSystemActivelyMonitored.value &&
                          securityEquipment
                            .consistentlyCCTVSystemActivelyMonitored.isNA &&
                          !securityEquipment
                            .consistentlyCCTVSystemActivelyMonitored.isNA.flag
                            ? securityEquipment
                                .consistentlyCCTVSystemActivelyMonitored.value
                                .value
                            : securityEquipment.consistentlyCCTVSystemActivelyMonitored &&
                              securityEquipment
                                .consistentlyCCTVSystemActivelyMonitored.isNA &&
                              securityEquipment
                                .consistentlyCCTVSystemActivelyMonitored.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "consistentlyCCTVSystemActivelyMonitored"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.consistentlyCCTVSystemActivelyMonitored &&
                          securityEquipment.consistentlyCCTVSystemActivelyMonitored &&
                          securityEquipment
                            .consistentlyCCTVSystemActivelyMonitored.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .consistentlyCCTVSystemActivelyMonitored
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and effective is the intruder detection
                          system in place for the school's perimeter?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .value &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .isNA &&
                          !securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .isNA.flag
                            ? securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .value.value
                            : securityEquipment.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                              securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .isNA &&
                              securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and effective are the intruder
                          detection systems for the doors and windows of the
                          school buildings?
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .value &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .isNA &&
                          !securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .isNA.flag
                            ? securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .value.value
                            : securityEquipment.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                              securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .isNA &&
                              securityEquipment
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            securityEquipment,
                            "functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings"
                          )}
                        </td>
                        <td>
                          {securityEquipment &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          securityEquipment.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          securityEquipment
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  securityEquipment
                                    .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Safety Response</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How regular or continuous are the school safety audits
                          conducted?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.regularSafetyAudit &&
                          safety_response.regularSafetyAudit.value &&
                          safety_response.regularSafetyAudit.isNA &&
                          !safety_response.regularSafetyAudit.isNA.flag
                            ? safety_response.regularSafetyAudit.value.value
                            : safety_response.regularSafetyAudit &&
                              safety_response.regularSafetyAudit.isNA &&
                              safety_response.regularSafetyAudit.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "regularSafetyAudit"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.regularSafetyAudit &&
                          safety_response.regularSafetyAudit &&
                          safety_response.regularSafetyAudit.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.regularSafetyAudit.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-distributed are the smoke detectors
                          installed?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistributedSmokeDetectors &&
                          safety_response.wellDistributedSmokeDetectors.value &&
                          safety_response.wellDistributedSmokeDetectors.isNA &&
                          !safety_response.wellDistributedSmokeDetectors.isNA
                            .flag
                            ? safety_response.wellDistributedSmokeDetectors
                                .value.value
                            : safety_response.wellDistributedSmokeDetectors &&
                              safety_response.wellDistributedSmokeDetectors
                                .isNA &&
                              safety_response.wellDistributedSmokeDetectors.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellDistributedSmokeDetectors"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistributedSmokeDetectors &&
                          safety_response.wellDistributedSmokeDetectors &&
                          safety_response.wellDistributedSmokeDetectors
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellDistributedSmokeDetectors
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well maintained are the smoke detectors throughout
                          the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedSmokeDetectors &&
                          safety_response.wellMaintainedSmokeDetectors.value &&
                          safety_response.wellMaintainedSmokeDetectors.isNA &&
                          !safety_response.wellMaintainedSmokeDetectors.isNA
                            .flag
                            ? safety_response.wellMaintainedSmokeDetectors.value
                                .value
                            : safety_response.wellMaintainedSmokeDetectors &&
                              safety_response.wellMaintainedSmokeDetectors
                                .isNA &&
                              safety_response.wellMaintainedSmokeDetectors.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellMaintainedSmokeDetectors"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedSmokeDetectors &&
                          safety_response.wellMaintainedSmokeDetectors &&
                          safety_response.wellMaintainedSmokeDetectors.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellMaintainedSmokeDetectors
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-distributed are the fire extinguishers
                          throughout the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistibutedFireExtinguishers &&
                          safety_response.wellDistibutedFireExtinguishers
                            .value &&
                          safety_response.wellDistibutedFireExtinguishers
                            .isNA &&
                          !safety_response.wellDistibutedFireExtinguishers.isNA
                            .flag
                            ? safety_response.wellDistibutedFireExtinguishers
                                .value.value
                            : safety_response.wellDistibutedFireExtinguishers &&
                              safety_response.wellDistibutedFireExtinguishers
                                .isNA &&
                              safety_response.wellDistibutedFireExtinguishers
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellDistibutedFireExtinguishers"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistibutedFireExtinguishers &&
                          safety_response.wellDistibutedFireExtinguishers &&
                          safety_response.wellDistibutedFireExtinguishers
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .wellDistibutedFireExtinguishers.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well maintained are the fire extinguishers
                          throughout the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedFireExtinguishers &&
                          safety_response.wellMaintainedFireExtinguishers
                            .value &&
                          safety_response.wellMaintainedFireExtinguishers
                            .isNA &&
                          !safety_response.wellMaintainedFireExtinguishers.isNA
                            .flag
                            ? safety_response.wellMaintainedFireExtinguishers
                                .value.value
                            : safety_response.wellMaintainedFireExtinguishers &&
                              safety_response.wellMaintainedFireExtinguishers
                                .isNA &&
                              safety_response.wellMaintainedFireExtinguishers
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellMaintainedFireExtinguishers"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedFireExtinguishers &&
                          safety_response.wellMaintainedFireExtinguishers &&
                          safety_response.wellMaintainedFireExtinguishers
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .wellMaintainedFireExtinguishers.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-distributed are the fire hoses throughout the
                          school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistibutedFireHoses &&
                          safety_response.wellDistibutedFireHoses.value &&
                          safety_response.wellDistibutedFireHoses.isNA &&
                          !safety_response.wellDistibutedFireHoses.isNA.flag
                            ? safety_response.wellDistibutedFireHoses.value
                                .value
                            : safety_response.wellDistibutedFireHoses &&
                              safety_response.wellDistibutedFireHoses.isNA &&
                              safety_response.wellDistibutedFireHoses.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellDistibutedFireHoses"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellDistibutedFireHoses &&
                          safety_response.wellDistibutedFireHoses &&
                          safety_response.wellDistibutedFireHoses.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellDistibutedFireHoses.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-maintained are the fire hoses throughout the
                          school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedFireHoses &&
                          safety_response.wellMaintainedFireHoses.value &&
                          safety_response.wellMaintainedFireHoses.isNA &&
                          !safety_response.wellMaintainedFireHoses.isNA.flag
                            ? safety_response.wellMaintainedFireHoses.value
                                .value
                            : safety_response.wellMaintainedFireHoses &&
                              safety_response.wellMaintainedFireHoses.isNA &&
                              safety_response.wellMaintainedFireHoses.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellMaintainedFireHoses"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedFireHoses &&
                          safety_response.wellMaintainedFireHoses &&
                          safety_response.wellMaintainedFireHoses.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellMaintainedFireHoses.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and well-maintained is the school's
                          sprinkler system?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedSprinklerSystem &&
                          safety_response.wellMaintainedSprinklerSystem.value &&
                          safety_response.wellMaintainedSprinklerSystem.isNA &&
                          !safety_response.wellMaintainedSprinklerSystem.isNA
                            .flag
                            ? safety_response.wellMaintainedSprinklerSystem
                                .value.value
                            : safety_response.wellMaintainedSprinklerSystem &&
                              safety_response.wellMaintainedSprinklerSystem
                                .isNA &&
                              safety_response.wellMaintainedSprinklerSystem.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellMaintainedSprinklerSystem"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellMaintainedSprinklerSystem &&
                          safety_response.wellMaintainedSprinklerSystem &&
                          safety_response.wellMaintainedSprinklerSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellMaintainedSprinklerSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what degree are fire extinguishers inspected to
                          ensure their proper functioning?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.degreeOfFireExtinguisherInspection &&
                          safety_response.degreeOfFireExtinguisherInspection
                            .value &&
                          safety_response.degreeOfFireExtinguisherInspection
                            .isNA &&
                          !safety_response.degreeOfFireExtinguisherInspection
                            .isNA.flag
                            ? safety_response.degreeOfFireExtinguisherInspection
                                .value.value
                            : safety_response.degreeOfFireExtinguisherInspection &&
                              safety_response.degreeOfFireExtinguisherInspection
                                .isNA &&
                              safety_response.degreeOfFireExtinguisherInspection
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "degreeOfFireExtinguisherInspection"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.degreeOfFireExtinguisherInspection &&
                          safety_response.degreeOfFireExtinguisherInspection &&
                          safety_response.degreeOfFireExtinguisherInspection
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .degreeOfFireExtinguisherInspection.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How vandal-resistant are smoke detectors, such as
                          having tamper alarms or protective cages?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.vacantResistantSmokeDetectors &&
                          safety_response.vacantResistantSmokeDetectors.value &&
                          safety_response.vacantResistantSmokeDetectors.isNA &&
                          !safety_response.vacantResistantSmokeDetectors.isNA
                            .flag
                            ? safety_response.vacantResistantSmokeDetectors
                                .value.value
                            : safety_response.vacantResistantSmokeDetectors &&
                              safety_response.vacantResistantSmokeDetectors
                                .isNA &&
                              safety_response.vacantResistantSmokeDetectors.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "vacantResistantSmokeDetectors"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.vacantResistantSmokeDetectors &&
                          safety_response.vacantResistantSmokeDetectors &&
                          safety_response.vacantResistantSmokeDetectors
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.vacantResistantSmokeDetectors
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How easy and clearly marked is the access to fire
                          extinguishers throughout the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers
                            .value &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers
                            .isNA &&
                          !safety_response.easyAndClearlyMarkedFireExtinguishers
                            .isNA.flag
                            ? safety_response
                                .easyAndClearlyMarkedFireExtinguishers.value
                                .value
                            : safety_response.fittedWithHandRails &&
                              safety_response
                                .easyAndClearlyMarkedFireExtinguishers.isNA &&
                              safety_response
                                .easyAndClearlyMarkedFireExtinguishers.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "easyAndClearlyMarkedFireExtinguishers"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers &&
                          safety_response.easyAndClearlyMarkedFireExtinguishers
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .easyAndClearlyMarkedFireExtinguishers.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How adequate is the access to first aid supplies?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateAccessToFirstAidSupplies &&
                          safety_response.adequateAccessToFirstAidSupplies
                            .value &&
                          safety_response.adequateAccessToFirstAidSupplies
                            .isNA &&
                          !safety_response.adequateAccessToFirstAidSupplies.isNA
                            .flag
                            ? safety_response.adequateAccessToFirstAidSupplies
                                .value.value
                            : safety_response.adequateAccessToFirstAidSupplies &&
                              safety_response.adequateAccessToFirstAidSupplies
                                .isNA &&
                              safety_response.adequateAccessToFirstAidSupplies
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "adequateAccessToFirstAidSupplies"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateAccessToFirstAidSupplies &&
                          safety_response.adequateAccessToFirstAidSupplies &&
                          safety_response.adequateAccessToFirstAidSupplies
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .adequateAccessToFirstAidSupplies.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clear and relevant is the emergency signage
                          displayed throughout the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearAndRelevantEmergencySignage &&
                          safety_response.clearAndRelevantEmergencySignage
                            .value &&
                          safety_response.clearAndRelevantEmergencySignage
                            .isNA &&
                          !safety_response.clearAndRelevantEmergencySignage.isNA
                            .flag
                            ? safety_response.clearAndRelevantEmergencySignage
                                .value.value
                            : safety_response.clearAndRelevantEmergencySignage &&
                              safety_response.clearAndRelevantEmergencySignage
                                .isNA &&
                              safety_response.clearAndRelevantEmergencySignage
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "clearAndRelevantEmergencySignage"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearAndRelevantEmergencySignage &&
                          safety_response.clearAndRelevantEmergencySignage &&
                          safety_response.clearAndRelevantEmergencySignage
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .clearAndRelevantEmergencySignage.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what extent does the school have internal fire
                          doors installed?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.extentOfInternalFireDoorsInstalled &&
                          safety_response.extentOfInternalFireDoorsInstalled
                            .value &&
                          safety_response.extentOfInternalFireDoorsInstalled
                            .isNA &&
                          !safety_response.extentOfInternalFireDoorsInstalled
                            .isNA.flag
                            ? safety_response.extentOfInternalFireDoorsInstalled
                                .value.value
                            : safety_response.extentOfInternalFireDoorsInstalled &&
                              safety_response.extentOfInternalFireDoorsInstalled
                                .isNA &&
                              safety_response.extentOfInternalFireDoorsInstalled
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "extentOfInternalFireDoorsInstalled"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.extentOfInternalFireDoorsInstalled &&
                          safety_response.extentOfInternalFireDoorsInstalled &&
                          safety_response.extentOfInternalFireDoorsInstalled
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .extentOfInternalFireDoorsInstalled.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          How well are the emergency exits throughout the school
                          functioning, unblocked, and clearly signposted?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellFunctioningEmergencyExits &&
                          safety_response.wellFunctioningEmergencyExits.value &&
                          safety_response.wellFunctioningEmergencyExits.isNA &&
                          !safety_response.wellFunctioningEmergencyExits.isNA
                            .flag
                            ? safety_response.wellFunctioningEmergencyExits
                                .value.value
                            : safety_response.wellFunctioningEmergencyExits &&
                              safety_response.wellFunctioningEmergencyExits
                                .isNA &&
                              safety_response.wellFunctioningEmergencyExits.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellFunctioningEmergencyExits"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellFunctioningEmergencyExits &&
                          safety_response.wellFunctioningEmergencyExits &&
                          safety_response.wellFunctioningEmergencyExits
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.wellFunctioningEmergencyExits
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clearly marked and easily accessible are
                          evacuation routes and assembly areas?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearlyMarkedEvacuationRoutesAndAssemblyAreas &&
                          safety_response
                            .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                            .value &&
                          safety_response
                            .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                            .isNA &&
                          !safety_response
                            .clearlyMarkedEvacuationRoutesAndAssemblyAreas.isNA
                            .flag
                            ? safety_response
                                .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                                .value.value
                            : safety_response.clearlyMarkedEvacuationRoutesAndAssemblyAreas &&
                              safety_response
                                .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                                .isNA &&
                              safety_response
                                .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "clearlyMarkedEvacuationRoutesAndAssemblyAreas"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.clearlyMarkedEvacuationRoutesAndAssemblyAreas &&
                          safety_response.clearlyMarkedEvacuationRoutesAndAssemblyAreas &&
                          safety_response
                            .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .clearlyMarkedEvacuationRoutesAndAssemblyAreas
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          To what extent are fire doors fitted with alarms and
                          'panic-bars' for easy opening during emergencies?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars
                            .value &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars
                            .isNA &&
                          !safety_response.fireDoorsFittedWithAlarmsAndPanicBars
                            .isNA.flag
                            ? safety_response
                                .fireDoorsFittedWithAlarmsAndPanicBars.value
                                .value
                            : safety_response.fireDoorsFittedWithAlarmsAndPanicBars &&
                              safety_response
                                .fireDoorsFittedWithAlarmsAndPanicBars.isNA &&
                              safety_response
                                .fireDoorsFittedWithAlarmsAndPanicBars.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "fireDoorsFittedWithAlarmsAndPanicBars"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars &&
                          safety_response.fireDoorsFittedWithAlarmsAndPanicBars
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .fireDoorsFittedWithAlarmsAndPanicBars.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How safe, well-lit, and equipped with handrails are
                          the school's stairways?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.safeWellLitAndEquippedWithHandrails &&
                          safety_response.safeWellLitAndEquippedWithHandrails
                            .value &&
                          safety_response.safeWellLitAndEquippedWithHandrails
                            .isNA &&
                          !safety_response.safeWellLitAndEquippedWithHandrails
                            .isNA.flag
                            ? safety_response
                                .safeWellLitAndEquippedWithHandrails.value.value
                            : safety_response.safeWellLitAndEquippedWithHandrails &&
                              safety_response
                                .safeWellLitAndEquippedWithHandrails.isNA &&
                              safety_response
                                .safeWellLitAndEquippedWithHandrails.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "safeWellLitAndEquippedWithHandrails"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.safeWellLitAndEquippedWithHandrails &&
                          safety_response.safeWellLitAndEquippedWithHandrails &&
                          safety_response.safeWellLitAndEquippedWithHandrails
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .safeWellLitAndEquippedWithHandrails.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How reliable is the fire brigade/firefighting service
                          capable of assisting during a fire?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.reliableFireBrigadeFirefightingService &&
                          safety_response.reliableFireBrigadeFirefightingService
                            .value &&
                          safety_response.reliableFireBrigadeFirefightingService
                            .isNA &&
                          !safety_response
                            .reliableFireBrigadeFirefightingService.isNA.flag
                            ? safety_response
                                .reliableFireBrigadeFirefightingService.value
                                .value
                            : safety_response.reliableFireBrigadeFirefightingService &&
                              safety_response
                                .reliableFireBrigadeFirefightingService.isNA &&
                              safety_response
                                .reliableFireBrigadeFirefightingService.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "reliableFireBrigadeFirefightingService"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.reliableFireBrigadeFirefightingService &&
                          safety_response.reliableFireBrigadeFirefightingService &&
                          safety_response.reliableFireBrigadeFirefightingService
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .reliableFireBrigadeFirefightingService
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How adequate is the response time of the fire
                          brigade/firefighting service (i.e., within 10
                          minutes)?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateResponseTimeOfFireBrigadeFirefightingService &&
                          safety_response
                            .adequateResponseTimeOfFireBrigadeFirefightingService
                            .value &&
                          safety_response
                            .adequateResponseTimeOfFireBrigadeFirefightingService
                            .isNA &&
                          !safety_response
                            .adequateResponseTimeOfFireBrigadeFirefightingService
                            .isNA.flag
                            ? safety_response
                                .adequateResponseTimeOfFireBrigadeFirefightingService
                                .value.value
                            : safety_response.adequateResponseTimeOfFireBrigadeFirefightingService &&
                              safety_response
                                .adequateResponseTimeOfFireBrigadeFirefightingService
                                .isNA &&
                              safety_response
                                .adequateResponseTimeOfFireBrigadeFirefightingService
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "adequateResponseTimeOfFireBrigadeFirefightingService"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.adequateResponseTimeOfFireBrigadeFirefightingService &&
                          safety_response.adequateResponseTimeOfFireBrigadeFirefightingService &&
                          safety_response
                            .adequateResponseTimeOfFireBrigadeFirefightingService
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .adequateResponseTimeOfFireBrigadeFirefightingService
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well does the school comply with local or national
                          fire codes that are applicable?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.schoolComplyWithLocalOrNationalFireCodes &&
                          safety_response
                            .schoolComplyWithLocalOrNationalFireCodes.value &&
                          safety_response
                            .schoolComplyWithLocalOrNationalFireCodes.isNA &&
                          !safety_response
                            .schoolComplyWithLocalOrNationalFireCodes.isNA.flag
                            ? safety_response
                                .schoolComplyWithLocalOrNationalFireCodes.value
                                .value
                            : safety_response.schoolComplyWithLocalOrNationalFireCodes &&
                              safety_response
                                .schoolComplyWithLocalOrNationalFireCodes
                                .isNA &&
                              safety_response
                                .schoolComplyWithLocalOrNationalFireCodes.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "schoolComplyWithLocalOrNationalFireCodes"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.schoolComplyWithLocalOrNationalFireCodes &&
                          safety_response.schoolComplyWithLocalOrNationalFireCodes &&
                          safety_response
                            .schoolComplyWithLocalOrNationalFireCodes.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .schoolComplyWithLocalOrNationalFireCodes
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How available is an on-site medical response team or
                          trained first aid responders at the school?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.onSiteMedicalResponseTeamOrTrainedFirstAidResponders &&
                          safety_response
                            .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                            .value &&
                          safety_response
                            .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                            .isNA &&
                          !safety_response
                            .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                            .isNA.flag
                            ? safety_response
                                .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                                .value.value
                            : safety_response.onSiteMedicalResponseTeamOrTrainedFirstAidResponders &&
                              safety_response
                                .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                                .isNA &&
                              safety_response
                                .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "onSiteMedicalResponseTeamOrTrainedFirstAidResponders"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.onSiteMedicalResponseTeamOrTrainedFirstAidResponders &&
                          safety_response.onSiteMedicalResponseTeamOrTrainedFirstAidResponders &&
                          safety_response
                            .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .onSiteMedicalResponseTeamOrTrainedFirstAidResponders
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sufficient is the capacity of the ambulance
                          service that the school has access to for handling
                          potential emergencies?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.sufficientCapacityOfAmbulanceService &&
                          safety_response.sufficientCapacityOfAmbulanceService
                            .value &&
                          safety_response.sufficientCapacityOfAmbulanceService
                            .isNA &&
                          !safety_response.sufficientCapacityOfAmbulanceService
                            .isNA.flag
                            ? safety_response
                                .sufficientCapacityOfAmbulanceService.value
                                .value
                            : safety_response.sufficientCapacityOfAmbulanceService &&
                              safety_response
                                .sufficientCapacityOfAmbulanceService.isNA &&
                              safety_response
                                .sufficientCapacityOfAmbulanceService.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "sufficientCapacityOfAmbulanceService"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.sufficientCapacityOfAmbulanceService &&
                          safety_response.sufficientCapacityOfAmbulanceService &&
                          safety_response.sufficientCapacityOfAmbulanceService
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .sufficientCapacityOfAmbulanceService.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How sufficient is the capacity of the law enforcement
                          service that the school has access to for responding
                          to potential security incidents?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.capacityOfLawEnforcementServiceForSecurityIncidents &&
                          safety_response
                            .capacityOfLawEnforcementServiceForSecurityIncidents
                            .value &&
                          safety_response
                            .capacityOfLawEnforcementServiceForSecurityIncidents
                            .isNA &&
                          !safety_response
                            .capacityOfLawEnforcementServiceForSecurityIncidents
                            .isNA.flag
                            ? safety_response
                                .capacityOfLawEnforcementServiceForSecurityIncidents
                                .value.value
                            : safety_response.capacityOfLawEnforcementServiceForSecurityIncidents &&
                              safety_response
                                .capacityOfLawEnforcementServiceForSecurityIncidents
                                .isNA &&
                              safety_response
                                .capacityOfLawEnforcementServiceForSecurityIncidents
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "capacityOfLawEnforcementServiceForSecurityIncidents"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.capacityOfLawEnforcementServiceForSecurityIncidents &&
                          safety_response.capacityOfLawEnforcementServiceForSecurityIncidents &&
                          safety_response
                            .capacityOfLawEnforcementServiceForSecurityIncidents
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .capacityOfLawEnforcementServiceForSecurityIncidents
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          If hazardous materials (HAZMAT) are stored on-site,
                          how well are they managed?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.haZMATStoredOnSiteManaged &&
                          safety_response.haZMATStoredOnSiteManaged.value &&
                          safety_response.haZMATStoredOnSiteManaged.isNA &&
                          !safety_response.haZMATStoredOnSiteManaged.isNA.flag
                            ? safety_response.haZMATStoredOnSiteManaged.value
                                .value
                            : safety_response.haZMATStoredOnSiteManaged &&
                              safety_response.haZMATStoredOnSiteManaged.isNA &&
                              safety_response.haZMATStoredOnSiteManaged.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "haZMATStoredOnSiteManaged"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.haZMATStoredOnSiteManaged &&
                          safety_response.haZMATStoredOnSiteManaged &&
                          safety_response.haZMATStoredOnSiteManaged.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response.haZMATStoredOnSiteManaged
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-trained are all personnel working with
                          hazardous materials for their safe handling and
                          storage?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .value &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .isNA &&
                          !safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .isNA.flag
                            ? safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .value.value
                            : safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                              safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .isNA &&
                              safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-trained are all personnel working with
                          hazardous materials for their safe handling and
                          storage?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .value &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .isNA &&
                          !safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .isNA.flag
                            ? safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .value.value
                            : safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                              safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .isNA &&
                              safety_response
                                .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response.wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials &&
                          safety_response
                            .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .wellTrainedPersonnelForSafeHandlingAndStorageOfHazardousMaterials
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How suitable is the school's emergency accessibility
                          for individuals with disabilities?
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.suitableEmergencyAccessibilityForIndividualsWithDisabilities &&
                          safety_response
                            .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                            .value &&
                          safety_response
                            .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                            .isNA &&
                          !safety_response
                            .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                            .isNA.flag
                            ? safety_response
                                .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                                .value.value
                            : safety_response.suitableEmergencyAccessibilityForIndividualsWithDisabilities &&
                              safety_response
                                .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                                .isNA &&
                              safety_response
                                .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            safety_response,
                            "suitableEmergencyAccessibilityForIndividualsWithDisabilities"
                          )}
                        </td>
                        <td>
                          {safety_response &&
                          safety_response.suitableEmergencyAccessibilityForIndividualsWithDisabilities &&
                          safety_response.suitableEmergencyAccessibilityForIndividualsWithDisabilities &&
                          safety_response
                            .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  safety_response
                                    .suitableEmergencyAccessibilityForIndividualsWithDisabilities
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Policy And Procedure</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Security
                          Risk Assessment?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveAssessment &&
                          policyAndProcedure.currentAndComprehensiveAssessment
                            .value &&
                          policyAndProcedure.currentAndComprehensiveAssessment
                            .isNA &&
                          !policyAndProcedure.currentAndComprehensiveAssessment
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveAssessment.value.value
                            : policyAndProcedure.currentAndComprehensiveAssessment &&
                              policyAndProcedure
                                .currentAndComprehensiveAssessment.isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveAssessment.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveAssessment"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveAssessment &&
                          policyAndProcedure.currentAndComprehensiveAssessment &&
                          policyAndProcedure.currentAndComprehensiveAssessment
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveAssessment.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Security
                          Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensivePolicy &&
                          policyAndProcedure.currentAndComprehensivePolicy
                            .value &&
                          policyAndProcedure.currentAndComprehensivePolicy
                            .isNA &&
                          !policyAndProcedure.currentAndComprehensivePolicy.isNA
                            .flag
                            ? policyAndProcedure.currentAndComprehensivePolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensivePolicy &&
                              policyAndProcedure.currentAndComprehensivePolicy
                                .isNA &&
                              policyAndProcedure.currentAndComprehensivePolicy
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensivePolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensivePolicy &&
                          policyAndProcedure.currentAndComprehensivePolicy &&
                          policyAndProcedure.currentAndComprehensivePolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensivePolicy.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Health
                          and Safety Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveHealthAndSafetyPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveHealthAndSafetyPolicy
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveHealthAndSafetyPolicy
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveHealthAndSafetyPolicy.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveHealthAndSafetyPolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveHealthAndSafetyPolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveHealthAndSafetyPolicy
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveHealthAndSafetyPolicy
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveHealthAndSafetyPolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveHealthAndSafetyPolicy &&
                          policyAndProcedure.currentAndComprehensiveHealthAndSafetyPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveHealthAndSafetyPolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveHealthAndSafetyPolicy
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Bullying
                          and Harassment Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveBullyingAndHarassmentPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingAndHarassmentPolicy
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingAndHarassmentPolicy
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveBullyingAndHarassmentPolicy
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveBullyingAndHarassmentPolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveBullyingAndHarassmentPolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveBullyingAndHarassmentPolicy
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveBullyingAndHarassmentPolicy
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveBullyingAndHarassmentPolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveBullyingAndHarassmentPolicy &&
                          policyAndProcedure.currentAndComprehensiveBullyingAndHarassmentPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingAndHarassmentPolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveBullyingAndHarassmentPolicy
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Code of
                          Conduct/Disciplinary Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCodeOfConductPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveCodeOfConductPolicy.value &&
                          policyAndProcedure
                            .currentAndComprehensiveCodeOfConductPolicy.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveCodeOfConductPolicy.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveCodeOfConductPolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveCodeOfConductPolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveCodeOfConductPolicy
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveCodeOfConductPolicy.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveCodeOfConductPolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCodeOfConductPolicy &&
                          policyAndProcedure.currentAndComprehensiveCodeOfConductPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveCodeOfConductPolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveCodeOfConductPolicy
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          Emergency Response/Crisis Management Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePolicy
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePolicy
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePolicy.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveEmergencyResponsePolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePolicy
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePolicy
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveEmergencyResponsePolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePolicy &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveEmergencyResponsePolicy
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Child
                          Protection Policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveChildProtectionPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveChildProtectionPolicy
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveChildProtectionPolicy
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveChildProtectionPolicy.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveChildProtectionPolicy
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveChildProtectionPolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveChildProtectionPolicy
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveChildProtectionPolicy
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveChildProtectionPolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveChildProtectionPolicy &&
                          policyAndProcedure.currentAndComprehensiveChildProtectionPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveChildProtectionPolicy
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveChildProtectionPolicy
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Security
                          Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan
                            .value &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveSecurityPlan.isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveSecurityPlan.value.value
                            : policyAndProcedure.currentAndComprehensiveSecurityPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveSecurityPlan.isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveSecurityPlan.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveSecurityPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan &&
                          policyAndProcedure.currentAndComprehensiveSecurityPlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveSecurityPlan.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          Emergency/Crisis Response Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePlan &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePlan.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveEmergencyResponsePlan &&
                              policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveEmergencyResponsePlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveEmergencyResponsePlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePlan &&
                          policyAndProcedure.currentAndComprehensiveEmergencyResponsePlan &&
                          policyAndProcedure
                            .currentAndComprehensiveEmergencyResponsePlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveEmergencyResponsePlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Fire
                          Safety Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveFireSafetyPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveFireSafetyPlan.value &&
                          policyAndProcedure
                            .currentAndComprehensiveFireSafetyPlan.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveFireSafetyPlan.isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveFireSafetyPlan.value
                                .value
                            : policyAndProcedure.currentAndComprehensiveFireSafetyPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveFireSafetyPlan.isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveFireSafetyPlan.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveFireSafetyPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveFireSafetyPlan &&
                          policyAndProcedure.currentAndComprehensiveFireSafetyPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveFireSafetyPlan.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveFireSafetyPlan.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Medical
                          Emergency Response Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveMedicalEmergencyResponsePlan &&
                          policyAndProcedure
                            .currentAndComprehensiveMedicalEmergencyResponsePlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveMedicalEmergencyResponsePlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveMedicalEmergencyResponsePlan
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveMedicalEmergencyResponsePlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveMedicalEmergencyResponsePlan &&
                              policyAndProcedure
                                .currentAndComprehensiveMedicalEmergencyResponsePlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveMedicalEmergencyResponsePlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveMedicalEmergencyResponsePlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveMedicalEmergencyResponsePlan &&
                          policyAndProcedure.currentAndComprehensiveMedicalEmergencyResponsePlan &&
                          policyAndProcedure
                            .currentAndComprehensiveMedicalEmergencyResponsePlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveMedicalEmergencyResponsePlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Bullying
                          Prevention Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveBullyingPreventionPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingPreventionPlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingPreventionPlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveBullyingPreventionPlan.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveBullyingPreventionPlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveBullyingPreventionPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveBullyingPreventionPlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveBullyingPreventionPlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveBullyingPreventionPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveBullyingPreventionPlan &&
                          policyAndProcedure.currentAndComprehensiveBullyingPreventionPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveBullyingPreventionPlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveBullyingPreventionPlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          Cybersecurity Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCybersecurityPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveCybersecurityPlan.value &&
                          policyAndProcedure
                            .currentAndComprehensiveCybersecurityPlan.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveCybersecurityPlan.isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveCybersecurityPlan.value
                                .value
                            : policyAndProcedure.currentAndComprehensiveCybersecurityPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveCybersecurityPlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveCybersecurityPlan.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveCybersecurityPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCybersecurityPlan &&
                          policyAndProcedure.currentAndComprehensiveCybersecurityPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveCybersecurityPlan.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveCybersecurityPlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Visitor
                          Management Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveVisitorManagementPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveVisitorManagementPlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveVisitorManagementPlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveVisitorManagementPlan.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveVisitorManagementPlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveVisitorManagementPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveVisitorManagementPlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveVisitorManagementPlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveVisitorManagementPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveVisitorManagementPlan &&
                          policyAndProcedure.currentAndComprehensiveVisitorManagementPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveVisitorManagementPlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveVisitorManagementPlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's Crisis
                          Communication Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCrisisCommunicationPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveCrisisCommunicationPlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveCrisisCommunicationPlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveCrisisCommunicationPlan.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveCrisisCommunicationPlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveCrisisCommunicationPlan &&
                              policyAndProcedure
                                .currentAndComprehensiveCrisisCommunicationPlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveCrisisCommunicationPlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveCrisisCommunicationPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveCrisisCommunicationPlan &&
                          policyAndProcedure.currentAndComprehensiveCrisisCommunicationPlan &&
                          policyAndProcedure
                            .currentAndComprehensiveCrisisCommunicationPlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveCrisisCommunicationPlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          Post-Incident Recovery Plan?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensivePostIncidentRecoveryPlan &&
                          policyAndProcedure
                            .currentAndComprehensivePostIncidentRecoveryPlan
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensivePostIncidentRecoveryPlan
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensivePostIncidentRecoveryPlan
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensivePostIncidentRecoveryPlan
                                .value.value
                            : policyAndProcedure.currentAndComprehensivePostIncidentRecoveryPlan &&
                              policyAndProcedure
                                .currentAndComprehensivePostIncidentRecoveryPlan
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensivePostIncidentRecoveryPlan
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensivePostIncidentRecoveryPlan"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensivePostIncidentRecoveryPlan &&
                          policyAndProcedure.currentAndComprehensivePostIncidentRecoveryPlan &&
                          policyAndProcedure
                            .currentAndComprehensivePostIncidentRecoveryPlan
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensivePostIncidentRecoveryPlan
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          procedures for Access Control?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveAccessControlProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveAccessControlProcedures
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveAccessControlProcedures
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveAccessControlProcedures.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveAccessControlProcedures
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveAccessControlProcedures &&
                              policyAndProcedure
                                .currentAndComprehensiveAccessControlProcedures
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveAccessControlProcedures
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveAccessControlProcedures"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveAccessControlProcedures &&
                          policyAndProcedure.currentAndComprehensiveAccessControlProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveAccessControlProcedures
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveAccessControlProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          procedures for employing and managing private security
                          services?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures &&
                              policyAndProcedure
                                .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures &&
                          policyAndProcedure.currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveEmployingAndManagingPrivateSecurityServicesProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          procedures for lockdown?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveLockdownProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveLockdownProcedures.value &&
                          policyAndProcedure
                            .currentAndComprehensiveLockdownProcedures.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveLockdownProcedures.isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveLockdownProcedures.value
                                .value
                            : policyAndProcedure.currentAndComprehensiveLockdownProcedures &&
                              policyAndProcedure
                                .currentAndComprehensiveLockdownProcedures
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveLockdownProcedures.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveLockdownProcedures"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveLockdownProcedures &&
                          policyAndProcedure.currentAndComprehensiveLockdownProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveLockdownProcedures.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveLockdownProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          procedures for shelter-in-place?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveShelterInPlaceProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveShelterInPlaceProcedures
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveShelterInPlaceProcedures
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveShelterInPlaceProcedures
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveShelterInPlaceProcedures
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveShelterInPlaceProcedures &&
                              policyAndProcedure
                                .currentAndComprehensiveShelterInPlaceProcedures
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveShelterInPlaceProcedures
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveShelterInPlaceProcedures"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveShelterInPlaceProcedures &&
                          policyAndProcedure.currentAndComprehensiveShelterInPlaceProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveShelterInPlaceProcedures
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveShelterInPlaceProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's
                          procedures for evacuation?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEvacuationProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveEvacuationProcedures
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveEvacuationProcedures.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveEvacuationProcedures.isNA
                            .flag
                            ? policyAndProcedure
                                .currentAndComprehensiveEvacuationProcedures
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveEvacuationProcedures &&
                              policyAndProcedure
                                .currentAndComprehensiveEvacuationProcedures
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveEvacuationProcedures
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveEvacuationProcedures"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveEvacuationProcedures &&
                          policyAndProcedure.currentAndComprehensiveEvacuationProcedures &&
                          policyAndProcedure
                            .currentAndComprehensiveEvacuationProcedures
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveEvacuationProcedures
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How established are the school's procedures for
                          managing threats or emergencies originating from the
                          surrounding area?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.establishedProceduresForManagingThreatsOrEmergencies &&
                          policyAndProcedure
                            .establishedProceduresForManagingThreatsOrEmergencies
                            .value &&
                          policyAndProcedure
                            .establishedProceduresForManagingThreatsOrEmergencies
                            .isNA &&
                          !policyAndProcedure
                            .establishedProceduresForManagingThreatsOrEmergencies
                            .isNA.flag
                            ? policyAndProcedure
                                .establishedProceduresForManagingThreatsOrEmergencies
                                .value.value
                            : policyAndProcedure.establishedProceduresForManagingThreatsOrEmergencies &&
                              policyAndProcedure
                                .establishedProceduresForManagingThreatsOrEmergencies
                                .isNA &&
                              policyAndProcedure
                                .establishedProceduresForManagingThreatsOrEmergencies
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "establishedProceduresForManagingThreatsOrEmergencies"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.establishedProceduresForManagingThreatsOrEmergencies &&
                          policyAndProcedure.establishedProceduresForManagingThreatsOrEmergencies &&
                          policyAndProcedure
                            .establishedProceduresForManagingThreatsOrEmergencies
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .establishedProceduresForManagingThreatsOrEmergencies
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's protocol
                          for Reporting of Suspicious Activities?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForReportingOfSuspiciousActivities &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveProtocolForReportingOfSuspiciousActivities &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveProtocolForReportingOfSuspiciousActivities"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForReportingOfSuspiciousActivities &&
                          policyAndProcedure.currentAndComprehensiveProtocolForReportingOfSuspiciousActivities &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveProtocolForReportingOfSuspiciousActivities
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's protocol
                          for Incident Reporting and Documentation?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForIncidentReportingAndDocumentation &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveProtocolForIncidentReportingAndDocumentation &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveProtocolForIncidentReportingAndDocumentation"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForIncidentReportingAndDocumentation &&
                          policyAndProcedure.currentAndComprehensiveProtocolForIncidentReportingAndDocumentation &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveProtocolForIncidentReportingAndDocumentation
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's protocol
                          for maintenance of security and safety equipment?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment &&
                          policyAndProcedure.currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveProtocolForMaintenanceOfSecurityAndSafetyEquipment
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's protocol
                          for School Bus Safety?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForSchoolBusSafety &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForSchoolBusSafety
                            .value &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForSchoolBusSafety
                            .isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveProtocolForSchoolBusSafety
                            .isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveProtocolForSchoolBusSafety
                                .value.value
                            : policyAndProcedure.currentAndComprehensiveProtocolForSchoolBusSafety &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForSchoolBusSafety
                                .isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveProtocolForSchoolBusSafety
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveProtocolForSchoolBusSafety"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveProtocolForSchoolBusSafety &&
                          policyAndProcedure.currentAndComprehensiveProtocolForSchoolBusSafety &&
                          policyAndProcedure
                            .currentAndComprehensiveProtocolForSchoolBusSafety
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveProtocolForSchoolBusSafety
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How current and comprehensive is the school's weapons
                          policy?
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveWeaponsPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveWeaponsPolicy.value &&
                          policyAndProcedure
                            .currentAndComprehensiveWeaponsPolicy.isNA &&
                          !policyAndProcedure
                            .currentAndComprehensiveWeaponsPolicy.isNA.flag
                            ? policyAndProcedure
                                .currentAndComprehensiveWeaponsPolicy.value
                                .value
                            : policyAndProcedure.currentAndComprehensiveWeaponsPolicy &&
                              policyAndProcedure
                                .currentAndComprehensiveWeaponsPolicy.isNA &&
                              policyAndProcedure
                                .currentAndComprehensiveWeaponsPolicy.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            policyAndProcedure,
                            "currentAndComprehensiveWeaponsPolicy"
                          )}
                        </td>
                        <td>
                          {policyAndProcedure &&
                          policyAndProcedure.currentAndComprehensiveWeaponsPolicy &&
                          policyAndProcedure.currentAndComprehensiveWeaponsPolicy &&
                          policyAndProcedure
                            .currentAndComprehensiveWeaponsPolicy.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  policyAndProcedure
                                    .currentAndComprehensiveWeaponsPolicy.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Building Exterior</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How adequately controlled and monitored are the
                          internal parking areas for staff, student and
                          visitors?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.windowsSecuredWithBat &&
                          buildingExterior.windowsSecuredWithBat.value &&
                          buildingExterior.windowsSecuredWithBat.isNA &&
                          !buildingExterior.windowsSecuredWithBat.isNA.flag
                            ? buildingExterior.windowsSecuredWithBat.value.value
                            : buildingExterior.windowsSecuredWithBat &&
                              buildingExterior.windowsSecuredWithBat.isNA &&
                              buildingExterior.windowsSecuredWithBat.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "windowsSecuredWithBat"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.windowsSecuredWithBat &&
                          buildingExterior.windowsSecuredWithBat &&
                          buildingExterior.windowsSecuredWithBat.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.windowsSecuredWithBat.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effectively does the school implement policies
                          that restrict non-authorized access to bus-loading
                          zones?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof
                            .value &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof
                            .isNA &&
                          !buildingExterior.protectedAgainstUNAuthAccessFromRoof
                            .isNA.flag
                            ? buildingExterior
                                .protectedAgainstUNAuthAccessFromRoof.value
                                .value
                            : buildingExterior.protectedAgainstUNAuthAccessFromRoof &&
                              buildingExterior
                                .protectedAgainstUNAuthAccessFromRoof.isNA &&
                              buildingExterior
                                .protectedAgainstUNAuthAccessFromRoof.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "protectedAgainstUNAuthAccessFromRoof"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof &&
                          buildingExterior.protectedAgainstUNAuthAccessFromRoof
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .protectedAgainstUNAuthAccessFromRoof.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clearly marked are bus-loading, unloading, and
                          drop-off zones?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.protectedAgainstUNAuthAccessFromBasement &&
                          buildingExterior
                            .protectedAgainstUNAuthAccessFromBasement.value &&
                          buildingExterior
                            .protectedAgainstUNAuthAccessFromBasement.isNA &&
                          !buildingExterior
                            .protectedAgainstUNAuthAccessFromBasement.isNA.flag
                            ? buildingExterior
                                .protectedAgainstUNAuthAccessFromBasement.value
                                .value
                            : buildingExterior.protectedAgainstUNAuthAccessFromBasement &&
                              buildingExterior
                                .protectedAgainstUNAuthAccessFromBasement
                                .isNA &&
                              buildingExterior
                                .protectedAgainstUNAuthAccessFromBasement.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "protectedAgainstUNAuthAccessFromBasement"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.protectedAgainstUNAuthAccessFromBasement &&
                          buildingExterior.protectedAgainstUNAuthAccessFromBasement &&
                          buildingExterior
                            .protectedAgainstUNAuthAccessFromBasement.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .protectedAgainstUNAuthAccessFromBasement
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective is the school's parking system in
                          incorporating an identification mechanism, like
                          placards in windshields?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.internalHallwayClearOfObstruction &&
                          buildingExterior.internalHallwayClearOfObstruction
                            .value &&
                          buildingExterior.internalHallwayClearOfObstruction
                            .isNA &&
                          !buildingExterior.internalHallwayClearOfObstruction
                            .isNA.flag
                            ? buildingExterior.internalHallwayClearOfObstruction
                                .value.value
                            : buildingExterior.internalHallwayClearOfObstruction &&
                              buildingExterior.internalHallwayClearOfObstruction
                                .isNA &&
                              buildingExterior.internalHallwayClearOfObstruction
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "internalHallwayClearOfObstruction"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.internalHallwayClearOfObstruction &&
                          buildingExterior.internalHallwayClearOfObstruction &&
                          buildingExterior.internalHallwayClearOfObstruction
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .internalHallwayClearOfObstruction.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How securely are all windows across the school able to
                          be locked?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingdesginedToPreventUnnecessaryObservation &&
                          buildingExterior
                            .buildingdesginedToPreventUnnecessaryObservation
                            .value &&
                          buildingExterior
                            .buildingdesginedToPreventUnnecessaryObservation
                            .isNA &&
                          !buildingExterior
                            .buildingdesginedToPreventUnnecessaryObservation
                            .isNA.flag
                            ? buildingExterior
                                .buildingdesginedToPreventUnnecessaryObservation
                                .value.value
                            : buildingExterior.buildingdesginedToPreventUnnecessaryObservation &&
                              buildingExterior
                                .buildingdesginedToPreventUnnecessaryObservation
                                .isNA &&
                              buildingExterior
                                .buildingdesginedToPreventUnnecessaryObservation
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "buildingdesginedToPreventUnnecessaryObservation"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingdesginedToPreventUnnecessaryObservation &&
                          buildingExterior.buildingdesginedToPreventUnnecessaryObservation &&
                          buildingExterior
                            .buildingdesginedToPreventUnnecessaryObservation
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .buildingdesginedToPreventUnnecessaryObservation
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly are windows inspected for any security
                          weaknesses?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingdesginedToAllowObservation &&
                          buildingExterior.buildingdesginedToAllowObservation
                            .value &&
                          buildingExterior.buildingdesginedToAllowObservation
                            .isNA &&
                          !buildingExterior.buildingdesginedToAllowObservation
                            .isNA.flag
                            ? buildingExterior
                                .buildingdesginedToAllowObservation.value.value
                            : buildingExterior.buildingdesginedToAllowObservation &&
                              buildingExterior
                                .buildingdesginedToAllowObservation.isNA &&
                              buildingExterior
                                .buildingdesginedToAllowObservation.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "buildingdesginedToAllowObservation"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingdesginedToAllowObservation &&
                          buildingExterior.buildingdesginedToAllowObservation &&
                          buildingExterior.buildingdesginedToAllowObservation
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .buildingdesginedToAllowObservation.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clear are the zones adjacent to the school
                          buildings from vegetation and other obstructions?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.assetsAdequatelySecured &&
                          buildingExterior.assetsAdequatelySecured.value &&
                          buildingExterior.assetsAdequatelySecured.isNA &&
                          !buildingExterior.assetsAdequatelySecured.isNA.flag
                            ? buildingExterior.assetsAdequatelySecured.value
                                .value
                            : buildingExterior.assetsAdequatelySecured &&
                              buildingExterior.assetsAdequatelySecured.isNA &&
                              buildingExterior.assetsAdequatelySecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "assetsAdequatelySecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.assetsAdequatelySecured &&
                          buildingExterior.assetsAdequatelySecured &&
                          buildingExterior.assetsAdequatelySecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.assetsAdequatelySecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How adequately lit are the school grounds to ensure
                          visibility during low light conditions?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.elavatorServiced &&
                          buildingExterior.elavatorServiced.value &&
                          buildingExterior.elavatorServiced.isNA &&
                          !buildingExterior.elavatorServiced.isNA.flag
                            ? buildingExterior.elavatorServiced.value.value
                            : buildingExterior.elavatorServiced &&
                              buildingExterior.elavatorServiced.isNA &&
                              buildingExterior.elavatorServiced.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "elavatorServiced"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.elavatorServiced &&
                          buildingExterior.elavatorServiced &&
                          buildingExterior.elavatorServiced.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.elavatorServiced.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How promptly are incidents of vandalism and graffiti
                          documented and removed?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingFacadeAcceptable &&
                          buildingExterior.buildingFacadeAcceptable.value &&
                          buildingExterior.buildingFacadeAcceptable.isNA &&
                          !buildingExterior.buildingFacadeAcceptable.isNA.flag
                            ? buildingExterior.buildingFacadeAcceptable.value
                                .value
                            : buildingExterior.buildingFacadeAcceptable &&
                              buildingExterior.buildingFacadeAcceptable.isNA &&
                              buildingExterior.buildingFacadeAcceptable.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "buildingFacadeAcceptable"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.buildingFacadeAcceptable &&
                          buildingExterior.buildingFacadeAcceptable &&
                          buildingExterior.buildingFacadeAcceptable.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.buildingFacadeAcceptable
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well are mechanical, electrical, and other
                          equipment protected to prevent unauthorized access?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.parkingAreaSecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-restricted is access to the school roof?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.parkingAreaSecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clearly are speed limits posted in appropriate
                          areas like parking lots and driveways?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.parkingAreaSecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How adequately lit are pedestrian walkways to ensure
                          visual surveillance?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          buildingExterior.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          buildingExterior
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior
                                    .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>How visible and accessible are fire hydrants?</td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.parkingAreaSecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are the procedures in place to handle a
                          situation where an unauthorized person gains access to
                          the school grounds?
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.value &&
                          buildingExterior.parkingAreaSecured.isNA &&
                          !buildingExterior.parkingAreaSecured.isNA.flag
                            ? buildingExterior.parkingAreaSecured.value.value
                            : buildingExterior.parkingAreaSecured &&
                              buildingExterior.parkingAreaSecured.isNA &&
                              buildingExterior.parkingAreaSecured.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingExterior,
                            "parkingAreaSecured"
                          )}
                        </td>
                        <td>
                          {buildingExterior &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured &&
                          buildingExterior.parkingAreaSecured.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingExterior.parkingAreaSecured.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Building Interior</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How clearly marked and easily identifiable is the
                          school's front office?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable
                            .value &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable
                            .isNA &&
                          !buildingInterior.clearlyMarkedAndEasilyIdentifiable
                            .isNA.flag
                            ? buildingInterior
                                .clearlyMarkedAndEasilyIdentifiable.value.value
                            : buildingInterior.clearlyMarkedAndEasilyIdentifiable &&
                              buildingInterior
                                .clearlyMarkedAndEasilyIdentifiable.isNA &&
                              buildingInterior
                                .clearlyMarkedAndEasilyIdentifiable.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "clearlyMarkedAndEasilyIdentifiable"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable &&
                          buildingInterior.clearlyMarkedAndEasilyIdentifiable
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .clearlyMarkedAndEasilyIdentifiable.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clearly marked and accessible is the first aid or
                          school nurse office?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAndAccessible &&
                          buildingInterior.clearlyMarkedAndAccessible.value &&
                          buildingInterior.clearlyMarkedAndAccessible.isNA &&
                          !buildingInterior.clearlyMarkedAndAccessible.isNA.flag
                            ? buildingInterior.clearlyMarkedAndAccessible.value
                                .value
                            : buildingInterior.clearlyMarkedAndAccessible &&
                              buildingInterior.clearlyMarkedAndAccessible
                                .isNA &&
                              buildingInterior.clearlyMarkedAndAccessible.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "clearlyMarkedAndAccessible"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAndAccessible &&
                          buildingInterior.clearlyMarkedAndAccessible &&
                          buildingInterior.clearlyMarkedAndAccessible.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior.clearlyMarkedAndAccessible
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How familiar are school staff with and trained on how
                          to shut off utility services in case of an emergency?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.familiarWithAndTrainedOnHowToShutOffUtilityServices &&
                          buildingInterior
                            .familiarWithAndTrainedOnHowToShutOffUtilityServices
                            .value &&
                          buildingInterior
                            .familiarWithAndTrainedOnHowToShutOffUtilityServices
                            .isNA &&
                          !buildingInterior
                            .familiarWithAndTrainedOnHowToShutOffUtilityServices
                            .isNA.flag
                            ? buildingInterior
                                .familiarWithAndTrainedOnHowToShutOffUtilityServices
                                .value.value
                            : buildingInterior.familiarWithAndTrainedOnHowToShutOffUtilityServices &&
                              buildingInterior
                                .familiarWithAndTrainedOnHowToShutOffUtilityServices
                                .isNA &&
                              buildingInterior
                                .familiarWithAndTrainedOnHowToShutOffUtilityServices
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "familiarWithAndTrainedOnHowToShutOffUtilityServices"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.familiarWithAndTrainedOnHowToShutOffUtilityServices &&
                          buildingInterior.familiarWithAndTrainedOnHowToShutOffUtilityServices &&
                          buildingInterior
                            .familiarWithAndTrainedOnHowToShutOffUtilityServices
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .familiarWithAndTrainedOnHowToShutOffUtilityServices
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How accurately is the inventory kept of all chemicals
                          used in science classes or laboratory areas?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses &&
                          buildingInterior
                            .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                            .value &&
                          buildingInterior
                            .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                            .isNA &&
                          !buildingInterior
                            .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                            .isNA.flag
                            ? buildingInterior
                                .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                                .value.value
                            : buildingInterior.accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses &&
                              buildingInterior
                                .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                                .isNA &&
                              buildingInterior
                                .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses &&
                          buildingInterior.accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses &&
                          buildingInterior
                            .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .accuratelyInventoryKeptOfAllChemicalsUsedInScienceClasses
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How secure are custodial and storage closets when not
                          in use?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.secureCustodialAndStorageClosetsWhenNotInUse &&
                          buildingInterior
                            .secureCustodialAndStorageClosetsWhenNotInUse
                            .value &&
                          buildingInterior
                            .secureCustodialAndStorageClosetsWhenNotInUse
                            .isNA &&
                          !buildingInterior
                            .secureCustodialAndStorageClosetsWhenNotInUse.isNA
                            .flag
                            ? buildingInterior
                                .secureCustodialAndStorageClosetsWhenNotInUse
                                .value.value
                            : buildingInterior.secureCustodialAndStorageClosetsWhenNotInUse &&
                              buildingInterior
                                .secureCustodialAndStorageClosetsWhenNotInUse
                                .isNA &&
                              buildingInterior
                                .secureCustodialAndStorageClosetsWhenNotInUse
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "secureCustodialAndStorageClosetsWhenNotInUse"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.secureCustodialAndStorageClosetsWhenNotInUse &&
                          buildingInterior.secureCustodialAndStorageClosetsWhenNotInUse &&
                          buildingInterior
                            .secureCustodialAndStorageClosetsWhenNotInUse
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .secureCustodialAndStorageClosetsWhenNotInUse
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How securely are unassigned lockers monitored for
                          unauthorized use?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.securelyUnassignedLockersMonitoredForUnauthorizedUse &&
                          buildingInterior
                            .securelyUnassignedLockersMonitoredForUnauthorizedUse
                            .value &&
                          buildingInterior
                            .securelyUnassignedLockersMonitoredForUnauthorizedUse
                            .isNA &&
                          !buildingInterior
                            .securelyUnassignedLockersMonitoredForUnauthorizedUse
                            .isNA.flag
                            ? buildingInterior
                                .securelyUnassignedLockersMonitoredForUnauthorizedUse
                                .value.value
                            : buildingInterior.securelyUnassignedLockersMonitoredForUnauthorizedUse &&
                              buildingInterior
                                .securelyUnassignedLockersMonitoredForUnauthorizedUse
                                .isNA &&
                              buildingInterior
                                .securelyUnassignedLockersMonitoredForUnauthorizedUse
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "securelyUnassignedLockersMonitoredForUnauthorizedUse"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.securelyUnassignedLockersMonitoredForUnauthorizedUse &&
                          buildingInterior.securelyUnassignedLockersMonitoredForUnauthorizedUse &&
                          buildingInterior
                            .securelyUnassignedLockersMonitoredForUnauthorizedUse
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .securelyUnassignedLockersMonitoredForUnauthorizedUse
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How adequately are doors and stairwells numbered for
                          easy location identification?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification &&
                          buildingInterior
                            .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                            .value &&
                          buildingInterior
                            .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                            .isNA &&
                          !buildingInterior
                            .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                            .isNA.flag
                            ? buildingInterior
                                .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                                .value.value
                            : buildingInterior.adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification &&
                              buildingInterior
                                .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                                .isNA &&
                              buildingInterior
                                .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification &&
                          buildingInterior.adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification &&
                          buildingInterior
                            .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .adequatelyDoorsAndStairwellsNumberedForEasyLocationIdentification
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well lit are stairwells, hallways, and restrooms
                          to ensure visibility and safety?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety &&
                          buildingInterior
                            .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                            .value &&
                          buildingInterior
                            .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                            .isNA &&
                          !buildingInterior
                            .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                            .isNA.flag
                            ? buildingInterior
                                .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                                .value.value
                            : buildingInterior.wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety &&
                              buildingInterior
                                .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                                .isNA &&
                              buildingInterior
                                .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety &&
                          buildingInterior.wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety &&
                          buildingInterior
                            .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .wellLitStairwellsHallwaysAndRestroomsToEnsureVisibilityAndSafety
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well monitored are enclosed stairwells, either
                          electronically or by security personnel, for
                          unauthorized access?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.wellMonitoredEnclosedStairwellsForUnauthorizedAccess &&
                          buildingInterior
                            .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                            .value &&
                          buildingInterior
                            .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                            .isNA &&
                          !buildingInterior
                            .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                            .isNA.flag
                            ? buildingInterior
                                .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                                .value.value
                            : buildingInterior.wellMonitoredEnclosedStairwellsForUnauthorizedAccess &&
                              buildingInterior
                                .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                                .isNA &&
                              buildingInterior
                                .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "wellMonitoredEnclosedStairwellsForUnauthorizedAccess"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.wellMonitoredEnclosedStairwellsForUnauthorizedAccess &&
                          buildingInterior.wellMonitoredEnclosedStairwellsForUnauthorizedAccess &&
                          buildingInterior
                            .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .wellMonitoredEnclosedStairwellsForUnauthorizedAccess
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly are doors and locks inspected and
                          maintained in good condition?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition &&
                          buildingInterior
                            .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                            .value &&
                          buildingInterior
                            .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                            .isNA &&
                          !buildingInterior
                            .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                            .isNA.flag
                            ? buildingInterior
                                .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                                .value.value
                            : buildingInterior.regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition &&
                              buildingInterior
                                .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                                .isNA &&
                              buildingInterior
                                .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition &&
                          buildingInterior.regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition &&
                          buildingInterior
                            .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .regularlyDoorsAndLocksInspectedAndMaintainedInGoodCondition
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How extensively is blast fragment retention film
                          installed on windows to prevent injury from shattered
                          glass? (Note: only applicable if threats involving an
                          explosion have been listed)
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.extensivelyBlastFragmentRetentionFilmInstalledOnWindows &&
                          buildingInterior
                            .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                            .value &&
                          buildingInterior
                            .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                            .isNA &&
                          !buildingInterior
                            .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                            .isNA.flag
                            ? buildingInterior
                                .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                                .value.value
                            : buildingInterior.extensivelyBlastFragmentRetentionFilmInstalledOnWindows &&
                              buildingInterior
                                .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                                .isNA &&
                              buildingInterior
                                .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "extensivelyBlastFragmentRetentionFilmInstalledOnWindows"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.extensivelyBlastFragmentRetentionFilmInstalledOnWindows &&
                          buildingInterior.extensivelyBlastFragmentRetentionFilmInstalledOnWindows &&
                          buildingInterior
                            .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .extensivelyBlastFragmentRetentionFilmInstalledOnWindows
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clearly marked are areas with restricted access?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAreasWithRestrictedAccess &&
                          buildingInterior
                            .clearlyMarkedAreasWithRestrictedAccess.value &&
                          buildingInterior
                            .clearlyMarkedAreasWithRestrictedAccess.isNA &&
                          !buildingInterior
                            .clearlyMarkedAreasWithRestrictedAccess.isNA.flag
                            ? buildingInterior
                                .clearlyMarkedAreasWithRestrictedAccess.value
                                .value
                            : buildingInterior.clearlyMarkedAreasWithRestrictedAccess &&
                              buildingInterior
                                .clearlyMarkedAreasWithRestrictedAccess.isNA &&
                              buildingInterior
                                .clearlyMarkedAreasWithRestrictedAccess.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "clearlyMarkedAreasWithRestrictedAccess"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.clearlyMarkedAreasWithRestrictedAccess &&
                          buildingInterior.clearlyMarkedAreasWithRestrictedAccess &&
                          buildingInterior
                            .clearlyMarkedAreasWithRestrictedAccess.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .clearlyMarkedAreasWithRestrictedAccess
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are measures in place to detect and
                          address any unauthorized access or trespassing within
                          the school building?
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding &&
                          buildingInterior
                            .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                            .value &&
                          buildingInterior
                            .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                            .isNA &&
                          !buildingInterior
                            .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                            .isNA.flag
                            ? buildingInterior
                                .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                                .value.value
                            : buildingInterior.effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding &&
                              buildingInterior
                                .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                                .isNA &&
                              buildingInterior
                                .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            buildingInterior,
                            "effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding"
                          )}
                        </td>
                        <td>
                          {buildingInterior &&
                          buildingInterior.effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding &&
                          buildingInterior.effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding &&
                          buildingInterior
                            .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  buildingInterior
                                    .effectiveMeasuresInPlaceToDetectAndAddressUnauthorizedAccessOrTrespassingWithinTheSchoolBuilding
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>School Culture</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How comprehensive and effective is the security
                          information and training provided to faculty,
                          students, and staff?
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.comprehensiveAndEffective &&
                          schoolCulture.comprehensiveAndEffective.value &&
                          schoolCulture.comprehensiveAndEffective.isNA &&
                          !schoolCulture.comprehensiveAndEffective.isNA.flag
                            ? schoolCulture.comprehensiveAndEffective.value
                                .value
                            : schoolCulture.comprehensiveAndEffective &&
                              schoolCulture.comprehensiveAndEffective.isNA &&
                              schoolCulture.comprehensiveAndEffective.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            schoolCulture,
                            "comprehensiveAndEffective"
                          )}
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.comprehensiveAndEffective &&
                          schoolCulture.comprehensiveAndEffective &&
                          schoolCulture.comprehensiveAndEffective.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  schoolCulture.comprehensiveAndEffective.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well does the security information and training
                          include guidance on how first responders operate to
                          ensure cooperation and avoid hindering their
                          operations during emergencies?
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.wellIncludeGuidance &&
                          schoolCulture.wellIncludeGuidance.value &&
                          schoolCulture.wellIncludeGuidance.isNA &&
                          !schoolCulture.wellIncludeGuidance.isNA.flag
                            ? schoolCulture.wellIncludeGuidance.value.value
                            : schoolCulture.wellIncludeGuidance &&
                              schoolCulture.wellIncludeGuidance.isNA &&
                              schoolCulture.wellIncludeGuidance.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            schoolCulture,
                            "wellIncludeGuidance"
                          )}
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.wellIncludeGuidance &&
                          schoolCulture.wellIncludeGuidance &&
                          schoolCulture.wellIncludeGuidance.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  schoolCulture.wellIncludeGuidance.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently are faculty and staff responsible for
                          monitoring hallways, stairwells, and restrooms during
                          school hours?
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.consistentlyResponsibleForMonitoring &&
                          schoolCulture.consistentlyResponsibleForMonitoring
                            .value &&
                          schoolCulture.consistentlyResponsibleForMonitoring
                            .isNA &&
                          !schoolCulture.consistentlyResponsibleForMonitoring
                            .isNA.flag
                            ? schoolCulture.consistentlyResponsibleForMonitoring
                                .value.value
                            : schoolCulture.consistentlyResponsibleForMonitoring &&
                              schoolCulture.consistentlyResponsibleForMonitoring
                                .isNA &&
                              schoolCulture.consistentlyResponsibleForMonitoring
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            schoolCulture,
                            "consistentlyResponsibleForMonitoring"
                          )}
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.consistentlyResponsibleForMonitoring &&
                          schoolCulture.consistentlyResponsibleForMonitoring &&
                          schoolCulture.consistentlyResponsibleForMonitoring
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  schoolCulture
                                    .consistentlyResponsibleForMonitoring.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are the curricula and programs
                          implemented by the school aimed at preventing harmful
                          behaviors (e.g., social problem-solving, life skills,
                          anti-bullying, mentoring programs, character
                          education)?
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.effectiveCurriculaAndPrograms &&
                          schoolCulture.effectiveCurriculaAndPrograms.value &&
                          schoolCulture.effectiveCurriculaAndPrograms.isNA &&
                          !schoolCulture.effectiveCurriculaAndPrograms.isNA.flag
                            ? schoolCulture.effectiveCurriculaAndPrograms.value
                                .value
                            : schoolCulture.effectiveCurriculaAndPrograms &&
                              schoolCulture.effectiveCurriculaAndPrograms
                                .isNA &&
                              schoolCulture.effectiveCurriculaAndPrograms.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            schoolCulture,
                            "effectiveCurriculaAndPrograms"
                          )}
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.effectiveCurriculaAndPrograms &&
                          schoolCulture.effectiveCurriculaAndPrograms &&
                          schoolCulture.effectiveCurriculaAndPrograms.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  schoolCulture.effectiveCurriculaAndPrograms
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly does the school maintain, review, and
                          update a code of conduct or school handbook?
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.regularlyMaintainReviewAndUpdate &&
                          schoolCulture.regularlyMaintainReviewAndUpdate
                            .value &&
                          schoolCulture.regularlyMaintainReviewAndUpdate.isNA &&
                          !schoolCulture.regularlyMaintainReviewAndUpdate.isNA
                            .flag
                            ? schoolCulture.regularlyMaintainReviewAndUpdate
                                .value.value
                            : schoolCulture.regularlyMaintainReviewAndUpdate &&
                              schoolCulture.regularlyMaintainReviewAndUpdate
                                .isNA &&
                              schoolCulture.regularlyMaintainReviewAndUpdate
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            schoolCulture,
                            "regularlyMaintainReviewAndUpdate"
                          )}
                        </td>
                        <td>
                          {schoolCulture &&
                          schoolCulture.regularlyMaintainReviewAndUpdate &&
                          schoolCulture.regularlyMaintainReviewAndUpdate &&
                          schoolCulture.regularlyMaintainReviewAndUpdate
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  schoolCulture.regularlyMaintainReviewAndUpdate
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>

                <Col xl={12} className="mt-5">
                  <h4>Communication System</h4>
                  <table className="w-full colored-table summary-table quater-table">
                    <thead>
                      <tr>
                        <th>Question</th>
                        <th>Answer</th>
                        <th>Comments</th>
                        <th>Images</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          How comprehensive and effective is the communication
                          system in place that ensures communication with all
                          individuals at the school, including faculty,
                          students, staff, emergency response teams, and
                          visitors?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.comprehensiveCommunicationSystem &&
                          communicationSystem.comprehensiveCommunicationSystem
                            .value &&
                          communicationSystem.comprehensiveCommunicationSystem
                            .isNA &&
                          !communicationSystem.comprehensiveCommunicationSystem
                            .isNA.flag
                            ? communicationSystem
                                .comprehensiveCommunicationSystem.value.value
                            : communicationSystem.comprehensiveCommunicationSystem &&
                              communicationSystem
                                .comprehensiveCommunicationSystem.isNA &&
                              communicationSystem
                                .comprehensiveCommunicationSystem.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "comprehensiveCommunicationSystem"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.comprehensiveCommunicationSystem &&
                          communicationSystem.comprehensiveCommunicationSystem &&
                          communicationSystem.comprehensiveCommunicationSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .comprehensiveCommunicationSystem.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and effective is the school premises'
                          Public Address (PA) system for emergency announcements
                          or general communications?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectivePA &&
                          communicationSystem.functionalAndEffectivePA.value &&
                          communicationSystem.functionalAndEffectivePA.isNA &&
                          !communicationSystem.functionalAndEffectivePA.isNA
                            .flag
                            ? communicationSystem.functionalAndEffectivePA.value
                                .value
                            : communicationSystem.functionalAndEffectivePA &&
                              communicationSystem.functionalAndEffectivePA
                                .isNA &&
                              communicationSystem.functionalAndEffectivePA.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "functionalAndEffectivePA"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectivePA &&
                          communicationSystem.functionalAndEffectivePA &&
                          communicationSystem.functionalAndEffectivePA.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem.functionalAndEffectivePA
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regular and effective is the school's
                          communication with local law enforcement and emergency
                          responders, ensuring a coordinated response during
                          emergencies?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularAndEffectiveCommunicationWithLawEnforcement &&
                          communicationSystem
                            .regularAndEffectiveCommunicationWithLawEnforcement
                            .value &&
                          communicationSystem
                            .regularAndEffectiveCommunicationWithLawEnforcement
                            .isNA &&
                          !communicationSystem
                            .regularAndEffectiveCommunicationWithLawEnforcement
                            .isNA.flag
                            ? communicationSystem
                                .regularAndEffectiveCommunicationWithLawEnforcement
                                .value.value
                            : communicationSystem.regularAndEffectiveCommunicationWithLawEnforcement &&
                              communicationSystem
                                .regularAndEffectiveCommunicationWithLawEnforcement
                                .isNA &&
                              communicationSystem
                                .regularAndEffectiveCommunicationWithLawEnforcement
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "regularAndEffectiveCommunicationWithLawEnforcement"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularAndEffectiveCommunicationWithLawEnforcement &&
                          communicationSystem.regularAndEffectiveCommunicationWithLawEnforcement &&
                          communicationSystem
                            .regularAndEffectiveCommunicationWithLawEnforcement
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .regularAndEffectiveCommunicationWithLawEnforcement
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-developed are the school's procedures for
                          communicating with the public and the media regarding
                          security issues or incidents?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.wellDevelopedProceduresForCommunicatingWithPublicAndMedia &&
                          communicationSystem
                            .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                            .value &&
                          communicationSystem
                            .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                            .isNA &&
                          !communicationSystem
                            .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                            .isNA.flag
                            ? communicationSystem
                                .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                                .value.value
                            : communicationSystem.wellDevelopedProceduresForCommunicatingWithPublicAndMedia &&
                              communicationSystem
                                .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                                .isNA &&
                              communicationSystem
                                .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "wellDevelopedProceduresForCommunicatingWithPublicAndMedia"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.wellDevelopedProceduresForCommunicatingWithPublicAndMedia &&
                          communicationSystem.wellDevelopedProceduresForCommunicatingWithPublicAndMedia &&
                          communicationSystem
                            .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .wellDevelopedProceduresForCommunicatingWithPublicAndMedia
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How clear and effective is the school's notification
                          protocol that outlines who should be contacted during
                          emergencies and the means by which they should be
                          contacted?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.clearAndEffectiveNotificationProtocol &&
                          communicationSystem
                            .clearAndEffectiveNotificationProtocol.value &&
                          communicationSystem
                            .clearAndEffectiveNotificationProtocol.isNA &&
                          !communicationSystem
                            .clearAndEffectiveNotificationProtocol.isNA.flag
                            ? communicationSystem
                                .clearAndEffectiveNotificationProtocol.value
                                .value
                            : communicationSystem.clearAndEffectiveNotificationProtocol &&
                              communicationSystem
                                .clearAndEffectiveNotificationProtocol.isNA &&
                              communicationSystem
                                .clearAndEffectiveNotificationProtocol.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "clearAndEffectiveNotificationProtocol"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.clearAndEffectiveNotificationProtocol &&
                          communicationSystem.clearAndEffectiveNotificationProtocol &&
                          communicationSystem
                            .clearAndEffectiveNotificationProtocol.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .clearAndEffectiveNotificationProtocol.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regularly are the school's communication systems
                          tested to ensure their functionality during
                          emergencies?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularlyTestedCommunicationSystems &&
                          communicationSystem
                            .regularlyTestedCommunicationSystems.value &&
                          communicationSystem
                            .regularlyTestedCommunicationSystems.isNA &&
                          !communicationSystem
                            .regularlyTestedCommunicationSystems.isNA.flag
                            ? communicationSystem
                                .regularlyTestedCommunicationSystems.value.value
                            : communicationSystem.regularlyTestedCommunicationSystems &&
                              communicationSystem
                                .regularlyTestedCommunicationSystems.isNA &&
                              communicationSystem
                                .regularlyTestedCommunicationSystems.isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "regularlyTestedCommunicationSystems"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularlyTestedCommunicationSystems &&
                          communicationSystem.regularlyTestedCommunicationSystems &&
                          communicationSystem
                            .regularlyTestedCommunicationSystems.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .regularlyTestedCommunicationSystems.image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective is the system in place for anonymous
                          reporting of safety concerns by students, staff, and
                          faculty?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.effectiveSystemForAnonymousReportingOfSafetyConcerns &&
                          communicationSystem
                            .effectiveSystemForAnonymousReportingOfSafetyConcerns
                            .value &&
                          communicationSystem
                            .effectiveSystemForAnonymousReportingOfSafetyConcerns
                            .isNA &&
                          !communicationSystem
                            .effectiveSystemForAnonymousReportingOfSafetyConcerns
                            .isNA.flag
                            ? communicationSystem
                                .effectiveSystemForAnonymousReportingOfSafetyConcerns
                                .value.value
                            : communicationSystem.effectiveSystemForAnonymousReportingOfSafetyConcerns &&
                              communicationSystem
                                .effectiveSystemForAnonymousReportingOfSafetyConcerns
                                .isNA &&
                              communicationSystem
                                .effectiveSystemForAnonymousReportingOfSafetyConcerns
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "effectiveSystemForAnonymousReportingOfSafetyConcerns"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.effectiveSystemForAnonymousReportingOfSafetyConcerns &&
                          communicationSystem.effectiveSystemForAnonymousReportingOfSafetyConcerns &&
                          communicationSystem
                            .effectiveSystemForAnonymousReportingOfSafetyConcerns
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .effectiveSystemForAnonymousReportingOfSafetyConcerns
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How effective are the mechanisms in place for parents
                          and guardians to receive timely notifications in the
                          event of an emergency?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications &&
                          communicationSystem
                            .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                            .value &&
                          communicationSystem
                            .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                            .isNA &&
                          !communicationSystem
                            .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                            .isNA.flag
                            ? communicationSystem
                                .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                                .value.value
                            : communicationSystem.effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications &&
                              communicationSystem
                                .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                                .isNA &&
                              communicationSystem
                                .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications &&
                          communicationSystem.effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications &&
                          communicationSystem
                            .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .effectiveMechanismsForParentsAndGuardiansToReceiveTimelyNotifications
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How regular and effective is the process for updates
                          and review of the communication protocol to keep up
                          with changing circumstances and technologies?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol &&
                          communicationSystem
                            .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                            .value &&
                          communicationSystem
                            .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                            .isNA &&
                          !communicationSystem
                            .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                            .isNA.flag
                            ? communicationSystem
                                .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                                .value.value
                            : communicationSystem.regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol &&
                              communicationSystem
                                .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                                .isNA &&
                              communicationSystem
                                .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol &&
                          communicationSystem.regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol &&
                          communicationSystem
                            .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .regularAndEffectiveProcessForUpdatesAndReviewOfCommunicationProtocol
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How well-equipped and dedicated is the control room
                          for monitoring the CCTV system?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          communicationSystem
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .value &&
                          communicationSystem
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .isNA &&
                          !communicationSystem
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .isNA.flag
                            ? communicationSystem
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .value.value
                            : communicationSystem.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                              communicationSystem
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .isNA &&
                              communicationSystem
                                .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          communicationSystem.wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem &&
                          communicationSystem
                            .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .wellEquippedAndDedicatedControlRoomForMonitoringCCTVSystem
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How consistently is the CCTV system actively monitored
                          (24/7)?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.consistentlyCCTVSystemActivelyMonitored &&
                          communicationSystem
                            .consistentlyCCTVSystemActivelyMonitored.value &&
                          communicationSystem
                            .consistentlyCCTVSystemActivelyMonitored.isNA &&
                          !communicationSystem
                            .consistentlyCCTVSystemActivelyMonitored.isNA.flag
                            ? communicationSystem
                                .consistentlyCCTVSystemActivelyMonitored.value
                                .value
                            : communicationSystem.consistentlyCCTVSystemActivelyMonitored &&
                              communicationSystem
                                .consistentlyCCTVSystemActivelyMonitored.isNA &&
                              communicationSystem
                                .consistentlyCCTVSystemActivelyMonitored.isNA
                                .flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "consistentlyCCTVSystemActivelyMonitored"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.consistentlyCCTVSystemActivelyMonitored &&
                          communicationSystem.consistentlyCCTVSystemActivelyMonitored &&
                          communicationSystem
                            .consistentlyCCTVSystemActivelyMonitored.image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .consistentlyCCTVSystemActivelyMonitored
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and effective is the intruder detection
                          system in place for the school's perimeter?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .value &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .isNA &&
                          !communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .isNA.flag
                            ? communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .value.value
                            : communicationSystem.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                              communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .isNA &&
                              communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .functionalAndEffectiveIntruderDetectionSystemForSchoolPerimeter
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          How functional and effective are the intruder
                          detection systems for the doors and windows of the
                          school buildings?
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .value &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .isNA &&
                          !communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .isNA.flag
                            ? communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .value.value
                            : communicationSystem.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                              communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .isNA &&
                              communicationSystem
                                .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                .isNA.flag
                            ? "Not Applicable"
                            : "-"}
                        </td>
                        <td>
                          {getSecondRowText(
                            communicationSystem,
                            "functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings"
                          )}
                        </td>
                        <td>
                          {communicationSystem &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          communicationSystem.functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings &&
                          communicationSystem
                            .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                            .image ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: getImage(
                                  communicationSystem
                                    .functionalAndEffectiveIntruderDetectionSystemsForDoorsAndWindowsOfSchoolBuildings
                                    .image
                                ),
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title>Risk Register</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <table className="w-full colored-table summary-table">
                    <thead>
                      <tr>
                        <th>Assessment Group</th>
                        <th>Additional Control Measure</th>
                        <th>Control Measure Category</th>
                        <th>Control Measure Details</th>
                        <th>Responsible Person</th>
                        <th>Deadline</th>
                      </tr>
                    </thead>
                    <tbody>
                      {riskRegister && riskRegister.length > 0 ? (
                        riskRegister.map((risk, index) => (
                          <tr key={`risk_register_${index}`}>
                            <td>{risk.section}</td>
                            <td>{risk.additionalControlMeasure}</td>
                            <td>
                              {risk.controlMeasureCateory
                                ? risk.controlMeasureCateory.value
                                : ""}
                            </td>
                            <td>{risk.implementationSteps}</td>
                            <td>{risk.responsiblePerson}</td>
                            <td>{risk.deadline}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={6}>No Data Available</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
