export const getModifiedData = (data) => {
  let modifiedData = [];
  for (let i = 0; i < data.length; i++) {
    if (
      data &&
      data[i] &&
      data[i].assessmentTemplate &&
      data[i].assessmentTemplate.ID === "1"
    ) {
      let a = {
        id: data[i].id,
        assesmentUser:
          data[i] &&
          data[i].assesmentUser &&
          data[i].assesmentUser.name &&
          data[i].assesmentUser.name.trim() !== ""
            ? data[i].assesmentUser.name
            : data[i].assesmentUser.displayName,
        assessor:
          data[i] &&
          data[i].assessmentResponse &&
          typeof JSON.parse(data[i].assessmentResponse)?.scope?.Assesor ===
            "string"
            ? JSON.parse(data[i].assessmentResponse)?.scope?.Assesor
            : "",
        assessmentDate: data[i].assessmentDate,
        country:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.scope?.Country?.value
            : "",
        city:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.scope?.City
            : "",
        agency:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.scope?.Agency
            : "",
        assessmentTemplate: data[i].assessmentTemplate,
        assessmentResponse: data[i].assessmentResponse,
      };
      modifiedData.push(a);
    }
    if (
      data &&
      data[i] &&
      data[i].assessmentTemplate &&
      (data[i].assessmentTemplate.ID === "2" ||
        data[i].assessmentTemplate.ID === "3")
    ) {
      let a = {
        id: data[i].id,
        assesmentUser:
          data[i] &&
          data[i].assesmentUser &&
          data[i].assesmentUser.name &&
          data[i].assesmentUser.name.trim() !== ""
            ? data[i].assesmentUser.name
            : data[i].assesmentUser.displayName,
        assessor:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.assesmentDetails
                ?.assesorFirstName.value
            : "",
        assessmentDate: data[i].assessmentDate,
        country:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.assesmentDetails.country
                ?.value?.value
            : "",
        city:
          data[i] && data[i].assessmentResponse
            ? JSON.parse(data[i].assessmentResponse)?.assesmentDetails?.city
                ?.value || ""
            : "",
        agency:
          data[i] && data[i].assesmentUser && data[i].assesmentUser.agency
            ? data[i].assesmentUser.agency
            : "",
        assessmentTemplate: data[i].assessmentTemplate,
        assessmentResponse: data[i].assessmentResponse,
      };
      modifiedData.push(a);
    }
  }
  return modifiedData;
};

export const getNotesData = (threatdata) => {
  let note = [];
  for (let i = 0; i < threatdata.length; i++) {
    const threat = threatdata[i].ThreatName;
    let section = "";
    let notes = "";
    if (threatdata[i].ControlMeasure.notes) {
      section = "Control Measure";
      notes = threatdata[i].ControlMeasure.notes;
      note.push({
        threat,
        section,
        notes,
      });
    }
    if (threatdata[i].Likelihood.notes) {
      section = "Likelihood";
      notes = threatdata[i].Likelihood.notes;
      note.push({
        threat,
        section,
        notes,
      });
    }
    if (threatdata[i].Impact.notes) {
      section = "Impact";
      notes = threatdata[i].Impact.notes;
      note.push({
        threat,
        section,
        notes,
      });
    }
  }
  return note;
};
