import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

export default function ContingencyPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const exportPdf = () => {
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF("p", "px", [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          "",
          "FAST"
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Contindency-Plan-${moment(
        assesment.assessmentDate
      ).format("DD-MM-YYYY")}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    setDownloadingPDFImage(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("p", "px", [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Contindency-Plan-${moment(
        assesment.assessmentDate
      ).format("DD-MM-YYYY")}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === "undefined") {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    criticalIncidentManagementTeam,
    cIMTActionPlans,
    incidents,
    annexes,
  } = assesmentResponse;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>Assessment Id: # {assesmentId} </h4>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download PDF{" "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Assesment Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>CONTINGENCY PLAN:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Country & Location</td>
                    <td>{planInformation?.countrylocation}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Introduction</h4>
              <Col xs={12}>
                <b>
                  These Contingency Plans are based on the most probable
                  incident types that have the potential to impact
                </b>
              </Col>
              <Col xs={12}>
                <p>{introduction?.introduction}</p>
              </Col>
              <Col xs={12}>
                <b>
                  To determine the most probable incident types, Security Risk
                  Assessments (SRA) are conducted annually at a minimum, to
                  identify and assess all relevant threats within the agency
                  area of operations.
                </b>
              </Col>
              <h4 className="mt-4">Security Risk Assessments</h4>
              <Col xs={12}>
                <b>The following SRA has been completed:</b>
              </Col>
              <Col xs={12}>
                {introduction?.sras?.map((sra, index) => (
                  <p>{sra?.sra}</p>
                ))}
              </Col>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Critical Incident Management Team (CIMT)</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>CIMT Function / Designation:</b>
                    </th>
                    <th>
                      <b>Primary</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                    <th>
                      <b>Backup</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criticalIncidentManagementTeam.criticalIncidentManagementTeam &&
                    criticalIncidentManagementTeam.criticalIncidentManagementTeam.map(
                      (cimt, index) => (
                        <tr>
                          <td>{cimt.designation}</td>
                          <td>{cimt.primary}</td>
                          <td>{cimt.contact}</td>
                          <td>{cimt.backup}</td>
                          <td>{cimt.contact2}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </Col>
            {console.log(criticalIncidentManagementTeam)}

            <Col xs={12} className="mt-4">
              <h4>Most Probable Incidents</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>No.</b>
                    </th>
                    <th>
                      <b>Incident Type</b>
                    </th>
                    <th>
                      <b>Risk Rating from SRA ( where applicable )</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {incidents.incidents &&
                    incidents.incidents.map((incident, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{incident.incidentType}</td>
                        <td>{incident.riskRating}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
            {cIMTActionPlans.map((item, index) => {
              return (
                <Col xs={12} className="mt-4">
                  <h4>CIMT Action Plan {index + 1}</h4>
                  <h4>Incident Type:</h4>
                  <table className="w-full colored-table summary-table ">
                    <thead>
                      <tr>
                        <th>
                          <b>CIMT Function / Designation:</b>
                        </th>
                        <th>
                          <b>Take orderly first steps(First Hour)</b>
                        </th>
                        <th>
                          <b>Establish Control of the Situation(First Day)</b>
                        </th>
                        <th>
                          <b>
                            Establish Incident Management Routine(if multi-day
                            response)
                          </b>
                        </th>
                        <th>
                          <b>
                            Post incident actions(within 1 week of "incident
                            conclusion")
                          </b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.designation.map((des, dIndex) => {
                        return (
                          <tr>
                            <td>{des.name}</td>
                            <td>{des?.inputs?.Takeorderfirststep}</td>
                            <td>{des?.inputs?.EstbCntoftheSituation}</td>
                            <td>{des?.inputs?.EstIcdMgmtRoutine}</td>
                            <td>{des?.inputs?.Postincidentactions}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Col>
              );
            })}
            <Col xs={12} className="mt-4">
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {annexes.annexes &&
                    annexes.annexes.map((ann, index) => (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{ann.documentName}</td>
                        <td>{ann.locationOfAnnex}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
