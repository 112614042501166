import React from "react";
import { useParams, useLocation } from "react-router-dom";
import { Button, Card, Col, Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { getAssesmentById } from "../../services/AssesmentService";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import moment from "moment-timezone";
import CreateControlMeasure from "./ControlMeasureSummary";
import Impact from "./ImpactSummary";
import Likelihood from "./LikelihoodSummary";
import RiskMatrix from "./RiskMatrix";
import RiskRegister from "./RiskRegisterSummary";
import Scope from "./ScopeSummary";
import Threat from "./ThreatSummary";
import Vulnerability from "./VulnerabilitySummary";
import ScopeSummary from "./ScopeSummary";
import ThreatSummary from "./ThreatSummary";
import VulnerabilitySummary from "./VulnerabilitySummary";
import LikelihoodSummary from "./LikelihoodSummary";
import ImpactSummary from "./ImpactSummary";
import RiskRegisterSummary from "./RiskRegisterSummary";
import ControlMeasureSummary from "./ControlMeasureSummary";
import UserInfo from "./UserInfo";
import AssesmentAnswer from "./AssesmentAnswer";
import NotesSummary from "./NotesSummary";

export default function AssesmentSummary(props) {
  const { assesmentId } = useParams();
  const location = useLocation();
  const user = useSelector((state) => state.auth.auth);
  const [assesment, setAssesment] = React.useState(undefined);

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingExcel, setDownloadingExcel] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [scope, setScopeData] = React.useState({
    Assesor: "",
    SuperVisor: "",
    Agency: "",
    Description: "",
    AssesmentDate: "",
    Country: "United States",
    City: "",
    Notes: "",
  });
  const [threat, setThreatData] = React.useState([
    {
      id: 1,
      ThreatName: "1",
      Category: "Armed conflict",
      Type: "Abduction/hostage taking",
      Description: "",
      performanceIndex: {
        Capability: "",
        History: "",
        Intention: "",
      },
      ControlMeasure: {
        detail: "",
        impact: [],
        notes: "",
        humanResources: "",
        PolicyAndProcedure: "",
        Training: "",
        Equipment: "",
        PhysicalSecurity: "",
      },
      Impact: {
        People: "",
        Information: "",
        Property: "",
        Capability: "",
        Economic: "",
        Reputation: "",
        notes: "",
      },
      Likelihood: {
        chance: "",
        notes: "",
      },
      RiskRegister: [
        {
          Strategy: "Physical Security",
          Category: "",
          Feature: "",
          Control_Measure_Details: "",
          Responsible_person: "",
          Deadline: "",
          Date_completed: "",
        },
      ],
    },
  ]);
  const [vulnerabilities, setVulnerabilitiesData] = React.useState({
    Experience: "",
    Training: "",
    Awareness: "",
    Planning: "",
    Security_Culture: "",
    Incident_Reporting: "",
    Incident_Management: "",
    Acceptance: "",
    Physical_Protection: "",
    notes: "",
  });
  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setScopeData(parsedData.scope);
        setThreatData(parsedData.threat);
        setVulnerabilitiesData(parsedData.vulnerabilities);
      }
      setLoaded(true);
    } else if (assesmentId === "undefined") {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setScopeData(parsedData.scope);
      setThreatData(parsedData.threat);
      setVulnerabilitiesData(parsedData.vulnerabilities);
    }
  }, [assesmentId]);

  const exportPdf = () => {
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF("p", "px", [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          "",
          "FAST"
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `SRA-${scope.Agency}-${scope.Country.label.replaceAll(
        " ",
        "-"
      )}-${scope.Type.label.replaceAll(" ", "-")}-${moment(
        scope.AssesmentDate
      ).format("DD-MM-YYYY")}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportAsExcel = () => {
    Swal.fire({
      title: "Export as Excel",
      text: "Are you sure you want to export as excel?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Export it!",
      cancelButtonText: "No, cancel!",
      reverseButtons: true,
    }).then((result) => {
      if (result) {
        setDownloadingExcel(true);
        const csvdata = [];
        for (let i = 0; i < threat.length; i++) {
          const data = threat[i];
          for (let j = 0; j < data.RiskRegister.length; j++) {
            const measure = data.RiskRegister[j];
            const item = {
              "Threat Name": data.ThreatName,
              Strategy: measure.Strategy.value,
              Category: measure.Category.value,
              Feature: measure.Feature.value,
              "Control Measure Details": measure.Control_Measure_Details,
              "Responsible person": measure.Responsible_person,
              Deadline: measure.Deadline,
              "Date completed": measure.Date_completed,
            };
            csvdata.push(item);
          }
        }
        const ws = XLSX.utils.json_to_sheet(csvdata);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        setDownloadingExcel(false);
        const fileName = `RR-SRA-${
          scope.Agency
        }-${scope.Country.label.replaceAll(
          " ",
          "-"
        )}-${scope.Type.label.replaceAll(" ", "-")}-${moment(
          scope.AssesmentDate
        ).format("DD-MM-YYYY")}`;
        FileSaver.saveAs(data, `${fileName}${fileExtension}`);
      }
    });
  };

  const getAssementIdText = () => {
    if (!scope && !scope.Country && !scope.Agency) return "";
    const fileName = `RR-SRA-${scope.Agency}-${
      scope?.Country?.label || scope?.Country?.toString() || "-"
    }-${moment(new Date()).format("DD-MM-YYYY")}`;
    return fileName;
  };

  return (
    <>
      <Row>
        <Col xs={6}>
          {scope && <h4>Assessment Id: # {getAssementIdText()} </h4>}
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download PDF{"  "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportAsExcel}>
            Download as Excel
            {downloadingExcel && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <div id="capture">
        <br /> <br />
        {assesment && <UserInfo assesment={assesment} />}
        <Card>
          <Card.Header>
            <Card.Title>Risk Matrix</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xl={12}>
                <RiskMatrix
                  threadData={threat}
                  vulnerabilities={vulnerabilities}
                  disabled
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
        {assesment && (
          <AssesmentAnswer
            scope={scope}
            threadData={threat}
            vulnerabilities={vulnerabilities}
          />
        )}
        {assesment && (
          <Card>
            <Card.Header>
              <Card.Title>Notes</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col xl={12}>
                  <NotesSummary
                    scope={scope}
                    vulnerabilities={vulnerabilities}
                    threadData={threat}
                    disabled
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        )}
      </div>
    </>
  );
}
