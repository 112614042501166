import axiosInstance from "./AxiosInstance";

export function getAppSetting(name) {
  return axiosInstance
    .get(`v1/app/appsetting?name=${name}`)
    .then((res) => res.data);
}

export function updateAppSetting(id, body) {
  return axiosInstance
    .patch(`v1/app/appsetting/${id}`, body)
    .then((res) => res.data);
}

export function getSupervisors() {
  return axiosInstance
    .get(`/v1/users/supervisors`)
    .then((res) => res.data)
    .catch((err) => Promise.reject(err));
}
