import React from "react";
import { Card, Col, Row } from "react-bootstrap";

export default function UserInfo({ assesment }) {
  const { name, email } = assesment.assesmentUser;
  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <i className={`la la-user me-2`} /> User Info
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={12}>
            <table className="table table-bordered table-striped user_detail">
              <tr>
                <td>User Name</td>
                <td>{name ? name.split(" ")[0] : "-"}</td>
              </tr>
              <tr>
                <td>User Surname</td>
                <td>{name ? name.split(" ")[1] : "-"}</td>
              </tr>
              <tr>
                <td>User Email</td>
                <td>{email}</td>
              </tr>
            </table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
