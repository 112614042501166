import axiosInstance from "./AxiosInstance";

export function getAssesments() {
  return axiosInstance.get(`v1/assesment`).then((res) => res.data);
}

export function getAssesmentsCompleted() {
  return axiosInstance.get(`v1/assesment/completed`).then((res) => res.data);
}

export function addAssesment(assesment) {
  return axiosInstance.post(`v1/assesment`, assesment).then((res) => res.data);
}

export function deleteAssesment(id) {
  return axiosInstance.delete(`v1/assesment/${id}`).then((res) => res.data);
}

export function editAssesment(id, assesment) {
  return axiosInstance
    .patch(`v1/assesment/${id}`, assesment)
    .then((res) => res.data);
}

export const SaveAssesment = (assesmentId, body) => {
  return axiosInstance
    .patch(`/v1/assesment/${assesmentId}`, body)
    .then((res) => res.data);
};

export const getAssesmentById = (assesmentId) => {
  return axiosInstance
    .get(`/v1/assesment/${assesmentId}`)
    .then((res) => res.data);
};
