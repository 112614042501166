import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export default function PSPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF("p", "px", [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          "PNG",
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          "",
          "FAST"
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `PSP-${
        planInformation?.agency?.value || planInformation?.agency || "-"
      }-${planInformation?.officeName || "-"}-${moment(
        assesment.assessmentDate
      ).format("DD-MM-YYYY")}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById("capture");
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF("p", "px", [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `PSP-${
        planInformation?.agency?.value || planInformation?.agency || "-"
      }-${planInformation?.officeName || "-"}-${moment(
        assesment.assessmentDate
      ).format("DD-MM-YYYY")}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== "undefined") {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === "undefined") {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    scopeAndPolicy,
    accountability,
    premisesOverview,
    riskManagement,
    securityManagement,
    physicalSecurityMeasures,
    miscellaneous,
    medicalResponse,
    fireSafety,
    occupationalSafetyAndHealth,
    emergencyContacts,
    annexes,
  } = assesmentResponse;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{" "}
            {planInformation?.agency?.value || planInformation?.agency || ""}-
            {planInformation?.officeName || ""}-
            {moment(assesment.assessmentDate).format("DD-MM-YYYY") || ""}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: "right" }}>
          <Button onClick={exportPdf}>
            Download PDF{" "}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{" "}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>PREMISES SECURITY PLAN (PSP):</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Office Name</td>
                    <td>{planInformation?.officeName}</td>
                  </tr>
                  <tr>
                    <td>City & Country</td>
                    <td>{planInformation?.cityCountry}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Introduction</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    This Premises Security Plan (PSP) captures the details of
                    security and safety measures in place at the:{" "}
                  </tr>
                  <tr>{introduction?.introduction}</tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Accountability</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "25%" }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: "25%" }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: "15%" }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: "35%" }}>
                      <b>Responsibility</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Premises Senior Manager</b>
                    </td>
                    <td>{accountability?.premisesseniorManagerName}</td>
                    <td>{accountability?.premisesseniorManagerContact}</td>
                    <td>
                      Accountable for premises security. Approves the PSP.
                      Supports activities to ensure compliance with the PSP.
                      Provides resources in support of the PSP.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Security Focal Point (SFP)</b>
                    </td>
                    <td>{accountability?.sfpName}</td>
                    <td>{accountability?.sfpContact}</td>
                    <td>
                      Responsible for premises security. Creates and maintains
                      the PSP. Conducts regular Premises Vulnerability
                      Assessments (PVA) and manages the risk register. Ensures
                      staff compliance with the PSP. Manages resources in
                      support of the PSP. Liaises with external stakeholders
                      critical to premises security.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Guard Force Supervisor</b>
                    </td>
                    <td>{accountability?.gfsName}</td>
                    <td>{accountability?.gfsContact}</td>
                    <td>
                      Responsible for ensuring adherence to the PSP by all
                      static guard-force (SGF) personnel. Reports incidents of
                      non-conformance to the SecPro / SFP. Maintains the
                      critical link between the SecPro/SFP and the guard-force.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Designated First Aider</b>
                    </td>
                    <td>{accountability?.dfaName}</td>
                    <td>{accountability?.dfaContact}</td>
                    <td>
                      Responsible for managing medical related risks and
                      incidents at the office, in coordination with the SFP.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fire Marshall</b>
                    </td>
                    <td>{accountability?.fireMarshalName}</td>
                    <td>{accountability?.fireMarshalContact}</td>
                    <td>
                      Responsible for managing fire related risks and incidents
                      at the office, in coordination with the SFP.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Occupational Safety and Health Representative</b>
                    </td>
                    <td>{accountability?.oshpName}</td>
                    <td>{accountability?.oshpContact}</td>
                    <td>
                      Responsible for managing safety related risks and
                      incidents at the office, in coordination with the SFP.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Facilities Manager</b>
                    </td>
                    <td>{accountability?.facilitiesManagerName}</td>
                    <td>{accountability?.facilitiesManagerContact}</td>
                    <td>
                      Responsible for ensuring all physical security aspects of
                      the premises are in place and functioning.
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>HR Manager</b>
                    </td>
                    <td>{accountability?.hrManagerName}</td>
                    <td>{accountability?.hrManagerContact}</td>
                    <td>
                      Responsible for supporting initiatives to ensure staff
                      understanding and compliance with the PSP.
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Premises Overview</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Location of the premises</b>
                    </td>
                    <td>{premisesOverview?.locationPremises}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Information about the neighbourhood</b>
                    </td>
                    <td>{premisesOverview?.informationAboutNeighbourhood}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Type of perimeter</b>
                    </td>
                    <td>{premisesOverview?.typeOfPerimeter}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Number of access points</b>
                    </td>
                    <td>{premisesOverview?.numberOfAccessPoint}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of buildings</b>
                    </td>
                    <td>{premisesOverview?.numberOfBuilding}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of agency staff</b>
                    </td>
                    <td>{premisesOverview?.numberOfAgencyStaff}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Broad overview of security measures</b>
                    </td>
                    <td>{premisesOverview?.broadOverviewOfSecuirity}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Broad overview of other occupants</b>
                    </td>
                    <td>{premisesOverview?.broadOverviewOfOtherOccupants}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Premises Layout</b>
                    </td>
                    <td>
                      {" "}
                      <img
                        style={{
                          width: "100px",
                        }}
                        src={premisesOverview?.premisesLayout}
                      ></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Risk Management</h4>
              <Row className="p-3">
                <Col xs={12} className="mb-3">
                  <b>
                    Security Risk Assessment (SRA) and Premises Vulnerability
                    Assessment (PVA)
                  </b>
                  <br />
                  <br />
                  <b>
                    This OSP is written in conjunction with the following
                    Security Risk Assessment (SRA) and Premises Vulnerability
                    Assessment (PVA).
                  </b>
                </Col>
                <Col xs={6}>
                  <label>
                    <b>SRA:</b>
                  </label>
                  <div>{riskManagement?.sra}</div>
                </Col>
                <Col xs={6}>
                  <label>
                    <b>PVA:</b>
                  </label>
                  <div>{riskManagement?.pva}</div>
                </Col>
                <hr className="my-4" />
                <Col xs={12}>
                  <b>Risk Overview</b>
                  <br />
                  <br />
                  This is a summary of the threats with the highest risk rating,
                  as identified in the SRA and PVA.
                </Col>
                <Col xs={12} className="my-2 mt-4">
                  <label>
                    <b>Key threats in the local area</b>
                  </label>
                  {riskManagement?.keyThreatsLocalArea &&
                    riskManagement?.keyThreatsLocalArea.map((value, index) => (
                      <div>
                        <span>{index + 1}.</span>&nbsp;{value}
                      </div>
                    ))}
                </Col>
                <Col xs={12}>
                  <label>
                    <b>Key threats to the premises</b>
                  </label>
                  {riskManagement?.keyThreatsOffice &&
                    riskManagement?.keyThreatsOffice.map((value, index) => (
                      <div>
                        <span>{index + 1}.</span> &nbsp;{value}
                      </div>
                    ))}
                </Col>
              </Row>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Security Management</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Security reporting</b>
                    </td>
                    <td>{securityManagement?.securityReporting}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Access Control Plan</b>
                    </td>
                    <td>{securityManagement?.accessControlPlan}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Incident Management Plan</b>
                    </td>
                    <td>{securityManagement?.incidentManagementPlan}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Security Lockdown Plan</b>
                    </td>
                    <td>{securityManagement?.securityLockdownPlan}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>IT Security Plan</b>
                    </td>
                    <td>{securityManagement?.itSecurityPlan}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Security training and briefings</b>
                    </td>
                    <td>{securityManagement?.securityTrainingAndBriefings}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Security lockdown drill</b>
                    </td>
                    <td>{securityManagement?.securityLockdownDrill}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Physical Security Measures</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Perimeter Security</b>
                    </td>
                    <td>{physicalSecurityMeasures?.perimeterSecurity}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Door Security</b>
                    </td>
                    <td>{physicalSecurityMeasures?.doorSecurity}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Windows</b>
                    </td>
                    <td>{physicalSecurityMeasures?.windows}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Security Guards</b>
                    </td>
                    <td>{physicalSecurityMeasures?.securityGuards}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>CCTV</b>
                    </td>
                    <td>{physicalSecurityMeasures?.cctv}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Alarms (door, window, motion etc.)</b>
                    </td>
                    <td>{physicalSecurityMeasures?.alarms}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Lockdown / Safe room</b>
                    </td>
                    <td>{physicalSecurityMeasures?.lockdownSafeRoom}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Emergency exits</b>
                    </td>
                    <td>{physicalSecurityMeasures?.emergencyExits}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Miscellaneous</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Key Control</b>
                    </td>
                    <td>{miscellaneous.keyControl}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Clear Desk Policy</b>
                    </td>
                    <td>{miscellaneous.clearDeskPolicy}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>High-value assets</b>
                    </td>
                    <td>{miscellaneous.highValueAssets}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Power</b>
                    </td>
                    <td>{miscellaneous.power}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Electrical items</b>
                    </td>
                    <td>{miscellaneous.electricalItems}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Medical Response</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Location of Medical Kit & accident book</b>
                    </td>
                    <td>{medicalResponse.locationOfMedicalKit}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Medical Emergency Response Plan</b>
                    </td>
                    <td>{medicalResponse.medicalEmergencyResponsePlan}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Medical training</b>
                    </td>
                    <td>{medicalResponse.medicalTraining}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Medical emergency drill</b>
                    </td>
                    <td>{medicalResponse.medicalEmergencyDrill}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Fire Safety</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Fire hazard awareness</b>
                    </td>
                    <td>{fireSafety.fireHazardAwareness}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Escape routes</b>
                    </td>
                    <td>{fireSafety.escapeRoutes}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fire Doors</b>
                    </td>
                    <td>{fireSafety.fireDoors}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Drills</b>
                    </td>
                    <td>{fireSafety.drills}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Extinguishers</b>
                    </td>
                    <td>{fireSafety.extinguishers}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Smoke detectors</b>
                    </td>
                    <td>{fireSafety.smokeDetectors}</td>
                  </tr>

                  <tr>
                    <td>
                      <b>Fire Blankets</b>
                    </td>
                    <td>{fireSafety.fireBlankets}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fire Alarms</b>
                    </td>
                    <td>{fireSafety.fireAlarms}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fire Hoses</b>
                    </td>
                    <td>{fireSafety.fireHoses}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Occupational Safety And Health</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: "50%" }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>OSH Policy</b>
                    </td>
                    <td>{occupationalSafetyAndHealth.oshPolicy}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Hazard awareness and reporting</b>
                    </td>
                    <td>
                      {occupationalSafetyAndHealth.hazardAwarenessAndReporting}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Earthquake drill</b>
                    </td>
                    <td>{occupationalSafetyAndHealth.earthquakeDrill}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Emergency Contacts</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Support Service</b>
                    </th>
                    <th>
                      <b>Point of Contact (if known)</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                    <th>
                      <b>Capabilities / Comments</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts.contacts &&
                    emergencyContacts.contacts.map(
                      (contact) =>
                        (contact.supportService ||
                          contact.pointOfContact ||
                          contact.contactNumber ||
                          contact.capabilities) && (
                          <tr key={contact.id}>
                            <td>{contact.supportService}</td>
                            <td>{contact.pointOfContact}</td>
                            <td>{contact.contactNumber}</td>
                            <td>{contact.capabilities}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12}>
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {annexes.annexes &&
                    annexes.annexes.map(
                      (ann, index) =>
                        (ann.documentName || ann.locationOfAnnex) && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{ann.documentName}</td>
                            <td>{ann.locationOfAnnex}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
