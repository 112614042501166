import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export default function JourneyManagementPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const Tabledata = [
    {
      docname: 'Travel Request Form',
      file: 'TravelPreparationChekclist.docx',
    },
    {
      docname: 'AKDN Road Safety Guidelines',
      file: 'AKDNRoadSafetyGuidelines.docx',
    },
    {
      docname: 'Method of Travel Procedure',
      file: 'MethodofTravelProcedures.docx',
    },
    {
      docname: 'Travel Preparation Checklist',
      file: 'TravelPreparationChekclist.docx',
    },
  ];

  const handleDownload = (name) => {
    let path = `/documents/${name}`;
    window.electron.ipcRenderer.downloadFile(path);
  };

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF('p', 'px', [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          '',
          'FAST'
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Journey-Management-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Journey-Management-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== 'undefined') {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === 'undefined') {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    accountability,
    riskAssessment,
    routineJourneys,
    nonRoutineJourneys,
    vehicles,
    travelRequest,
    travelApproval,
    annexes,
  } = assesmentResponse;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{' '}
            {planInformation?.agency?.value || planInformation?.agency || ''}-
            {planInformation?.officeName || ''}-
            {moment(assesment.assessmentDate).format('DD-MM-YYYY') || ''}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button onClick={exportPdf}>
            Download PDF{' '}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{' '}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>
                      PREMISES SECURITY PLAN (JOURNEY MANAGEMENT):
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Country & Facility Name</td>
                    <td>{planInformation?.countryFacility}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Current Version::</td>
                    <td>{planInformation?.currentVersion}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>INTRODUCTION</h4>
              <Col xs={12} className="mt-4">
                <table className="w-full colored-table summary-table half-table">
                  <thead>
                    {/* <tr>
                    <th colSpan={2}></th>
                  </tr> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        This Journey Management Plan (JMP) details how plans and
                        manages travel for its staff.
                      </td>
                      <td>{introduction?.introduction}</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </Col>
              <h5>Purpose</h5>
              <p className="my-1">
                The aim of the Journey Management Plan is to:
              </p>
              <ul
                className="custom-number"
                style={{
                  paddingLeft: '2rem',
                }}
              >
                <li>Manage travel risks;</li>
                <li>Encourage effective resource management;</li>
                <li>Provide efficient accountability of assets. </li>
              </ul>
              <div className="mt-4">
                <h4>Revisions</h4>
                <p className="mb-2">
                  The JMP is a “living” document and should be routinely
                  reviewed and revised as necessary (at minimum every twelve
                  months, or after any travel-related incident involving AKDN
                  staff and/or assets, or after a significant change in the
                  threat environment).
                </p>
              </div>
              <div className="mt-4">
                <h4>Application</h4>
                <p className="mb-2">
                  This plan should be taken into immediate use.
                </p>
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>ACCOUNTABILITY AND RESPONSIBILITY</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '35%' }}>
                      <b>Responsibility</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accountability &&
                    accountability.map(
                      (item, index) =>
                        (item.designation ||
                          item.name ||
                          item.contact ||
                          item.responsibilities) && (
                          <tr>
                            <td>{item?.designation}</td>
                            <td>{item?.name}</td>
                            <td>{item?.contact}</td>
                            <td>{item?.responsibilities} </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>RISK ASSESSMENT</h4>
              <p className="my-3">
                Travel threats to agency staff, and the levels of risk
                associated with these threats have been assessed, and where
                required, control measures have been identified and implemented
                to mitigate the risk. These details are captured in the agency’s
                Security Risk Assessments (SRA).
              </p>
              <Col xs={12} className="mt-4">
                <h4>Security Risk Assessments </h4>
                <table className="w-full colored-table summary-table half-table">
                  <thead>
                    <tr>
                      <th>The following SRA has been completed:</th>
                    </tr>
                  </thead>
                  <tbody>
                    {riskAssessment &&
                      riskAssessment?.map(
                        (item, index) =>
                          item.sra && (
                            <tr>
                              <td>{item?.sra}</td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              </Col>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>SCOPE</h4>
              <p className="my-1">This plan is applicable to:</p>
              <ul
                className="custom-number"
                style={{
                  paddingLeft: '2rem',
                }}
              >
                <li>
                  All agency-related journeys (whether they are local, national
                  or international journeys), by whatever means of transport,
                  involving AKDN staff, contractors, volunteers, consultants and
                  any personnel operating under the Agency Duty of Care
                  umbrella. For the purposes of this plan, the term “staff” is
                  used to refer to the above personnel
                </li>
                <li>
                  The use of all vehicles owned, managed and under the direct
                  control and responsibility of the agency.
                </li>
                <li>
                  The use of third-party contractor vehicles and services, where
                  applicable. The service agreements with third party
                  contractors must confirm minimum standards for drivers and
                  vehicles, in accordance with this plan.
                </li>
              </ul>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Vehicles</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th>
                      Vehicles which fall directly under the Agency’s oversight,
                      control and responsibility are as follows:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vehicles &&
                    vehicles?.map(
                      (item, index) =>
                        item.vehicle && (
                          <tr>
                            <td>{item?.vehicle}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4 className="my-2">REQUEST AND APPROVAL PROCESS </h4>
              <h4>Travel Request Process</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th>
                      Any travel involving staff must be requested through the
                      following process:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {travelRequest &&
                    travelRequest?.map(
                      (item, index) =>
                        item.stage && (
                          <tr>
                            <td>{item?.stage}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Travel Approval Process</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th>
                      Once travel has been requested and security-cleared, all
                      travel must be approved. The following actions must be
                      taken before resources will be allocated:
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {travelApproval &&
                    travelApproval?.map(
                      (item, index) =>
                        item.stage && (
                          <tr>
                            <td>{item?.stage}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                {Tabledata &&
                    Tabledata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.docname}</td>
                        <td>
                          <i
                            style={{ fontSize: '20px', marginLeft: '10px' }}
                            role="button"
                            className={`la la-download me-2`}
                            onClick={() => handleDownload(item.file)}
                          />
                        </td>
                      </tr>
                    ))}
                  {annexes.annexes &&
                    annexes.annexes.map(
                      (ann, index) =>
                        (ann.documentName || ann.location) && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{ann.documentName}</td>
                            <td>{ann.location}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
