import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export default function LockdownPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const handleDownload = (name) => {
    let path = `/documents/${name}`;
    window.electron.ipcRenderer.downloadFile(path);
  };

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF('p', 'px', [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          '',
          'FAST'
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Lockdown-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Lockdown-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== 'undefined') {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === 'undefined') {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    accountability,
    initiateLockdown,
    lockdownArea,
    emergencyContacts,
    annexes,
  } = assesmentResponse;

  const Tabledata = [
    {
      docname: 'Lockdown Capability',
      file: 'LockdownCapability.docx',
    },
    {
      docname: 'Lockdown Procedures',
      file: 'LockdownProcedures.docx',
    },
    {
      docname: 'Developing Lockdown Drills',
      file: 'LockdownDrills.docx',
    },
  ];

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{'Lockdown'}-
            {planInformation?.agency?.value || planInformation?.agency || ''}-
            {planInformation?.countryFacility || ''}-
            {moment(assesment.assessmentDate).format('DD-MM-YYYY') || ''}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button onClick={exportPdf}>
            Download PDF{' '}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{' '}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>PREMISES SECURITY PLAN (LOCKDOWN):</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Country & Facility Name</td>
                    <td>{planInformation?.countryFacility}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Current Version::</td>
                    <td>{planInformation?.currentVersion}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>INTRODUCTION</h4>
              <h5>Revisions</h5>
              <p className="my-1">This plan will be reviewed:</p>
              <ul
                className="custom-number"
                style={{
                  paddingLeft: '2rem',
                }}
              >
                <li>Annually (routine review).</li>
                <li>After any significant physical changes to the premises.</li>
                <li>Following a security incident at the premises.</li>
                <li>
                  Following a significant change in the security context or on
                  the emergence of a new threat.
                </li>
              </ul>
              <div className="mt-4">
                <h4>Definitions</h4>
                <p className="mb-2">
                  There are important differences between shelter-in-place and
                  lockdown arrangements.
                </p>
              </div>
              <div>
                <p>
                  {' '}
                  <b>Shelter-in-place:</b> refers to remaining inside the
                  facility to take immediate shelter in order to protect people
                  from an external threat, where inside offers a higher degree
                  of safety than outside.
                </p>
                <p>
                  <b>Lockdown:</b> is initiated to reduce direct accessibility
                  from a threat to people whilst inside a facility such as
                  hostile intruder, terrorist attack or criminal activity. In
                  this instance it is used as a delay measure to allow for a
                  local law enforcement response.
                </p>
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>ACCOUNTABILITY AND RESPONSIBILITY</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '35%' }}>
                      <b>Responsibility</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {accountability &&
                    accountability.map(
                      (item, index) =>
                        (item.designation ||
                          item.name ||
                          item.contact ||
                          item.responsibilities) && (
                          <tr>
                            <td>{item?.designation}</td>
                            <td>{item?.name}</td>
                            <td>{item?.contact}</td>
                            <td>{item?.responsibilities} </td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>INITIATING LOCKDOWN</h4>
              <p className="my-3">
                <b> Remember:</b> it is very much the exception to evacuate a
                building in the event of a Lockdown. Unless the location of an
                intruder is known, a "blind" evacuation may put people in more
                danger (e.g., from an intruder or a device at one of the
                entrances/exits) than if they had remained within the building.
              </p>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>
                        {' '}
                        Alarm or signal used to move people into Lockdown Areas
                      </b>
                    </td>
                    <td>{initiateLockdown.lockdownSignal}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Signal to stand down and give the all-clear</b>
                    </td>
                    <td>{initiateLockdown.clearSignal}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>LOCKDOWN AREAS </h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th>Rooms / areas identified as suitable for Lockdown</th>
                  </tr>
                </thead>
                <tbody>
                  {lockdownArea &&
                    lockdownArea?.map(
                      (item, index) =>
                        item.area && (
                          <tr>
                            <td>{item?.area}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>EMERGENCY CONTACTS</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th>
                      <b>Organisation</b>
                    </th>
                    <th>
                      <b>Point of Contact</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts &&
                    emergencyContacts.contacts.map(
                      (item, index) =>
                        (item.organisation ||
                          item.pointOfContact ||
                          item.contactNumber) && (
                          <tr key={index}>
                            <td>{item.organisation}</td>
                            <td>{item.pointOfContact}</td>
                            <td>{item.contactNumber}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Tabledata &&
                    Tabledata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.docname}</td>
                        <td>
                          <i
                            style={{ fontSize: '20px', marginLeft: '10px' }}
                            role="button"
                            className={`la la-download me-2`}
                            onClick={() => handleDownload(item.file)}
                          />
                        </td>
                      </tr>
                    ))}
                  {annexes.annexes &&
                    annexes.annexes.map(
                      (ann, index) =>
                        (ann.documentName || ann.location) && (
                          <tr key={index}>
                            <td>{index + 4}</td>
                            <td>{ann.documentName}</td>
                            <td>{ann.location}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
