import React from "react";
import { Badge, Card, Col, Row } from "react-bootstrap";
import ThreatSummary from "./ThreatSummary";
import VulnerabilitySummary from "./VulnerabilitySummary";
import LikelihoodSummary from "./LikelihoodSummary";
import ImpactSummary from "./ImpactSummary";
import ControlMeasureSummary from "./ControlMeasureSummary";
import RiskRegisterSummary from "./RiskRegisterSummary";
import moment from "moment";
export default function AssesmentAnswer({
  scope,
  threadData,
  vulnerabilities,
}) {
  if (!threadData) {
    return <></>;
  }
  const getRiskClass = (risk) => {
    if (risk === "Very High Risk") {
      return "bg-red-400 color-white";
    } else if (risk === "High Risk") {
      return "bg-orange-400 color-white";
    } else if (risk === "Medium Risk") {
      return "bg-yellow-400 color-white";
    } else if (risk === "Low Risk") {
      return "bg-blue-400 color-white";
    } else {
      return "bg-green-400 color-white";
    }
  };

  const getSuperVisor = (item) => {
    if (item) {
      const assessors = item.map((i) => i.name);
      return assessors.join(", ");
    }
    return "";
  };

  return (
    <Card>
      <Card.Header>
        <Card.Title>
          <i className={`la la-user me-2`} /> Assesment Anwsers
        </Card.Title>
      </Card.Header>
      <Card.Body>
        <Row>
          <Col xl={12}>
            <h4>Scope</h4>
            <table className="min-w-full w-full table table-bordered table-striped user_detail">
              <tr>
                <td>Assesor :</td>
                <td>{scope.Assesor?.firstName || scope.Assesor}</td>
              </tr>
              <tr>
                <td>SuperVisor : </td>
                <td>
                  {typeof scope.SuperVisor === "string"
                    ? scope.SuperVisor
                    : getSuperVisor(scope.SuperVisor)}
                </td>
              </tr>
              <tr>
                <td>Agency : </td>
                <td>{scope.Agency}</td>
              </tr>
              <tr>
                <td>Description : </td>
                <td>{scope.Description}</td>
              </tr>
              <tr>
                <td>Year</td>
                <td>{moment(scope.AssesmentDate).format("YYYY")}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{scope.Address}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{scope.Type.value}</td>
              </tr>
              <tr>
                <td>Date Completed</td>
                <td>{moment(scope.AssesmentDate).format("MM-DD-YYYY")}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{scope.Country.value}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{scope.City}</td>
              </tr>
            </table>
          </Col>
          <Col xs={12}>
            <h4>Threat Summary</h4>
            <ThreatSummary threat={threadData} disabled={true} />
          </Col>
          <Col xs={12}>
            <h4>Vulnerability Summary</h4>
            <VulnerabilitySummary
              vulnerabilities={vulnerabilities}
              disabled={true}
            />
          </Col>
          <br />
          <br />
          <Col xs={12} className="mt-3">
            <h4>Likelihood Summary</h4>
            <LikelihoodSummary threadData={threadData} disabled={true} />
          </Col>
          <br />
          <Col xl={12} className="mt-3">
            <h4>Impact Summary</h4>
            <ImpactSummary
              threadData={threadData}
              vulnerabilities={vulnerabilities}
              disabled={true}
            />
          </Col>
          <Col xs={12} className="mt-3">
            <h4>Risk</h4>
            <table className="min-w-full w-full table colored-table user_detail">
              <tbody>
                <tr>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4"
                  >
                    Threat Name
                  </th>
                  <th
                    scope="col"
                    className="text-sm font-medium text-gray-900 px-6 py-4"
                  >
                    <br /> Risk
                  </th>
                </tr>
                {threadData &&
                  threadData.map((item, index) => (
                    <tr className="border-b" key={"risk_index_" + index}>
                      <td className="text-sm text-gray-900 font-medium px-6 py-4 whitespace-nowrap">
                        {item.ThreatName}
                      </td>
                      <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                        {item.risk_matrix ? item.risk_matrix : "-"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </Col>
          {/* <Col xl={12} className="mt-3">
            <h4>Risk Management</h4>
            <ControlMeasureSummary threadData={threadData} disabled={true} />
          </Col>
          <Col xl={12} className="mt-3">
            <h4>Risk Register</h4>
            <RiskRegisterSummary threadData={threadData} disabled={true} />
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
}
