import React from "react";
import getMuiTheme from "material-ui/styles/getMuiTheme";
import MuiThemeProvider from "material-ui/styles/MuiThemeProvider";
import MaterialUiForm from "./MaterialUiForm";
import swal from "sweetalert";
import { postContactQuery } from "../../../../services/ContactService";
import { useSelector } from "react-redux";

const ContactUs = (props) => {
  const user = useSelector((state) => state.auth.auth);
  const contact = async (item, reset) => {
    try {
      const body = {
        ...item,
        userId: user.userId,
        email: user.email,
        fullName: user.displayName,
      };
      await postContactQuery(body);
      reset();
      swal("Success", "Your message has been sent!", "success");
    } catch (e) {
      console.log(e);
      swal("Error", "Something went wrong!", "error");
    }
  };
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-md-12 col-xl-12">
          <MuiThemeProvider muiTheme={getMuiTheme()}>
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Contact Form</h4>
              </div>
              <div className="card-body">
                <MaterialUiForm
                  onSubmit={(e, _values, props) => contact(e, props.reset)}
                />
              </div>
            </div>
          </MuiThemeProvider>
        </div>
      </div>
    </>
  );
};
export default ContactUs;
