/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
/// Link
import { Link } from "react-router-dom";
import useScrollPosition from "use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const appDetails = useSelector((state) => state.auth.appSetting);
  const user = useSelector((state) => state.auth.auth);
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    //sidebar icon Heart blast
    /*    var handleheartBlast = document.querySelector(".heart");
    function heartBlast() {
      return handleheartBlast.classList.toggle("heart-blast");
    }
    handleheartBlast.addEventListener("click", heartBlast);*/
  }, []);
  let scrollPosition = useScrollPosition();
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let contact = ["contact-form"],
    users = ["users"];

  return (
    <div
      className={`deznav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? scrollPosition > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="deznav-scroll relative">
        <MM className="metismenu" id="menu">
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/users">
              <i className="flaticon-080-search"></i>
              <span className="nav-text">Users</span>
            </Link>
          </li>
          <li className={`${path === "completed" ? "mm-active" : ""}`}>
            <Link
              className={`${path === "completed" ? "mm-active" : ""}`}
              to="/assesment/completed"
            >
              <i className="flaticon-013-checkmark"></i>
              <span className="nav-text">Completed Assessment</span>
            </Link>
          </li>
          <li className={`${path === "assigned" ? "mm-active" : ""}`}>
            <Link
              className={`${path === "assigned" ? "mm-active" : ""}`}
              to="/assement/assigned"
            >
              <i className="flaticon-043-menu"></i>
              <span className="nav-text">Assigned Assessment</span>
            </Link>
          </li>
          {/*          <li className={`${path === "assementTemplate" ? "mm-active" : ""}`}>
            <Link
              className={`${path === "assementTemplate" ? "mm-active" : ""}`}
              to="/assementTemplate"
            >
              <i className="flaticon-041-folder"></i>
              <span className="nav-text">Assessment Templates</span>
            </Link>
          </li>*/}
          <li className={`${path === "contact-query" ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/contact-query">
              <i className="flaticon-087-stop"></i>
              <span className="nav-text">Contact Queries</span>
            </Link>
          </li>
          {/*   <li className={`${contact.includes(path) ? "mm-active" : ""}`}>
            <Link className="ai-icon" to="/contact-form">
              <i className="flaticon-087-stop"></i>
              <span className="nav-text">Contact Form</span>
            </Link>
          </li>
          {user && user.role === "admin" && (
            <li className={`${path === "app-setting" ? "mm-active" : ""}`}>
              <Link className="ai-icon" to="/app-setting">
                <i className="flaticon-087-stop"></i>
                <span className="nav-text">App Setting</span>
              </Link>
            </li>
          )}*/}
        </MM>
        <div className="copyright position-absolute bottom-0">
          <p>
            <strong>{appDetails?.name}</strong> © 2022 All Rights Reserved
          </p>
          {/*          <p className="fs-12">
            Made with <span className="heart"></span> by {appDetails?.name}
          </p>*/}
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
