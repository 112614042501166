import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export default function HREPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const handleDownload = (name) => {
    let path = `/documents/${name}`;
    window.electron.ipcRenderer.downloadFile(path);
  };

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF('p', 'px', [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          '',
          'FAST'
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Hibernation-Relocation-Evacuation-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Hibernation-Relocation-Evacuation-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== 'undefined') {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === 'undefined') {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    criticalIncidentManagementTeam,
    preparedness,
    hibernation,
    relocation,
    initialRoute,
    relocationRoutes,
    relocationRoutesMaps,
    evacuation,
    evacuationRoutes,
    evacuationRoutesMaps,
    roadTravel,
    airTravel,
    seaTravel,
    emergencyContacts,
    annexes,
  } = assesmentResponse;

  const Tabledata = [
    {
      docname: 'HRE Process',
      file: 'HREProcess.docx',
    },
    {
      docname: 'Critical Incident Response Level System (CIRLS)',
      file: 'CIRLS.docx',
    },
    {
      docname: 'HRE Preparation Checklists',
      file: 'HREPreparationChecklists.docx',
    },
    {
      docname: 'HRE Briefs',
      file: 'HREBriefs.docx',
    },
    {
      docname: 'Evacuation Manifest',
      file: 'EvacuationManifest.xlsx',
    },
    {
      docname: 'Emergency Supplies Guidelines',
      file: 'EmergencySuppliesGuideline.docx',
    },
  ];

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{'Hibernation-Relocation-Evacuation'}-
            {planInformation?.agency?.value || planInformation?.agency || ''}-
            {planInformation?.countryFacility || ''}-
            {moment(assesment.assessmentDate).format('DD-MM-YYYY') || ''}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button onClick={exportPdf}>
            Download PDF{' '}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{' '}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
          &nbsp;
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>
                      PREMISES SECURITY PLAN (Hibernation Relocation Evacuation
                      (HRE)):
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency:</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>Country:</td>
                    <td>{planInformation?.country}</td>
                  </tr>
                  <tr>
                    <td>Location Name:</td>
                    <td>{planInformation?.location}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date:</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Current Version::</td>
                    <td>{planInformation?.currentVersion}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>INTRODUCTION</h4>
              <Col xs={12} className="mt-4">
                <table className="w-full colored-table summary-table half-table">
                  <thead>
                    {/* <tr>
                    <th colSpan={2}></th>
                  </tr> */}
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        In the context of heightened security risks and a
                        deteriorating operating environment, the implementation
                        of a comprehensive Hibernation, Relocation, and
                        Evacuation (HRE) plan is crucial to ensure the safety
                        and well-being of staff in
                      </td>
                      <td>{introduction?.introduction}</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </Col>
              <div className="mt-2">
                <h4>Purpose</h4>
                <p className="mb-2">
                  The aim of the plan is to outline a structured approach to
                  managing emergencies by providing clear protocols for
                  hibernation, where personnel are instructed to remain in a
                  secure location; relocation, involving the transfer of staff
                  to a safer area within the region; and evacuation, which
                  encompasses the organised withdrawal of all personnel from the
                  area, typically out-of-country. The HRE plan aims to mitigate
                  risks, protect lives, and maintain operational continuity
                  during periods of instability.
                </p>
              </div>
              <div>
                <h4>Revisions</h4>
                <p className="mb-2">
                  The HRE Plan is a “living” document and will be routinely
                  reviewed and revised as necessary at minimum every six months
                  or at the onset of any significant change to the operating
                  environment.
                </p>
              </div>
              <div>
                <h4>Application</h4>
                <p className="mb-2">
                  This plan is applicable to all AKDN staff, although evacuation
                  out-of-country will be restricted to international staff only.
                </p>
                <p>This plan should be taken into immediate use.</p>
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Critical Incident Management Team (CIMT)</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>CIMT Function / Designation:</b>
                    </th>
                    <th>
                      <b>Name</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                    <th>
                      <b>HRE Responsibilities (additional to CIMT Function)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {criticalIncidentManagementTeam.criticalIncidentManagementTeam &&
                    criticalIncidentManagementTeam.criticalIncidentManagementTeam.map(
                      (cimt, index) =>
                        (cimt.designation ||
                          cimt.name ||
                          cimt.contact ||
                          cimt.responsibilities) && (
                          <tr>
                            <td>{cimt.designation}</td>
                            <td>{cimt.name}</td>
                            <td>{cimt.contact}</td>
                            <td>{cimt.responsibilities}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>PREPAREDNESS</h4>
              <Col xs={12} className="mt-4">
                <table className="w-full colored-table summary-table half-table">
                  <thead></thead>
                  <tbody>
                    <tr>
                      <td>
                        CIMT closely monitors the associated triggers in the
                        Critical Incident Response Level System (CIRLS) when
                        there is deterioration of the security landscape in
                        order to maintain the correct levels of preparedness and
                        to ensure a prompt response following HRE actions. The
                        following flowchart illustrates a typical HRE process
                        using the CIRLS, but these steps may not always be
                        sequential depending on the situation i.e., moving
                        directly into an evacuation after a period of
                        hibernation or relocating instead of hibernating.
                      </td>
                      <td>{preparedness?.agency}</td>
                    </tr>
                    <tr></tr>
                  </tbody>
                </table>
              </Col>
              <div>
                <FlowChart />
              </div>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>HIBERNATION</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '18%' }}>
                      <b>Location Name</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Address & GRID </b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>
                        Premises Vulnerability Assessment Completed - Yes / No{' '}
                      </b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Lockdown Capability – Yes / No</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Capacity (No. of pax)</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>72 hours Supplies Yes / No</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hibernation.map(
                    (item, index) =>
                      (item.locationName ||
                        item.address ||
                        item.premisesVulnerability ||
                        item.lockdownCapability ||
                        item.capability ||
                        item.supplies) && (
                        <tr key={index}>
                          <td>{item.locationName}</td>
                          <td>{item.address}</td>
                          <td>{item.premisesVulnerability}</td>
                          <td>{item.lockdownCapability}</td>
                          <td>{item.capability}</td>
                          <td>{item.supplies}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>RELOCATION</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '18%' }}>
                      <b>Location Name</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Address & GRID </b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Point of Contact</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Capacity (PAX)</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>72 hours Supplies Y/N</b>
                    </th>
                    <th style={{ width: '18%' }}>
                      <b>Remarks</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {relocation.map(
                    (item, index) =>
                      (item.locationName ||
                        item.address ||
                        item.poc ||
                        item.capacity ||
                        item.supplies ||
                        item.remarks) && (
                        <tr key={index}>
                          <td>{item.locationName}</td>
                          <td>{item.address}</td>
                          <td>{item.poc}</td>
                          <td>{item.capacity}</td>
                          <td>{item.supplies}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <h4>Routes and Maps for Relocation</h4>
            {relocationRoutes.map((route, index) => (
              <Col key={index} xs={12} className="mt-4">
                <h4>Route Card {index + 1}</h4>
                <table className="w-full colored-table summary-table">
                  <thead>
                    <tr>
                      <th style={{ width: '18%' }}>
                        <b>Start Point Name (Agency / Site location)</b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Destination Name (Safe Haven) </b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Primary / Alternate Route </b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b> Map Reference</b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Directions (If Map is not available)</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {route.map(
                      (item, index) =>
                        (item.startPoint ||
                          item.destination ||
                          item.primaryAlternate ||
                          item.mapReference ||
                          item.directions) && (
                          <tr key={index}>
                            <td>{item.startPoint}</td>
                            <td>{item.destination}</td>
                            <td>{item.primaryAlternate}</td>
                            <td>{item.mapReference}</td>
                            <td>{item.directions}</td>
                          </tr>
                        )
                    )}
                    {relocationRoutesMaps.map((route, i) => (
                      <div key={i}>
                        {route.routemap && i === index && (
                          <tr>
                            <td>
                              <b>Route Maps</b>
                            </td>
                            <td>
                              <img
                                style={{
                                  width: '300px',
                                  height: 'auto',
                                }}
                                src={route?.routemap}
                              />
                            </td>
                          </tr>
                        )}
                      </div>
                    ))}
                  </tbody>
                </table>
              </Col>
            ))}
            <hr />
            <Col xs={12} className="mt-4">
              <h4>EVACUATION </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Location Name</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Address & GRID </b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Point of Contact</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Remarks</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {evacuation.map(
                    (item, index) =>
                      (item.locationName ||
                        item.address ||
                        item.poc ||
                        item.remarks) && (
                        <tr key={index}>
                          <td>{item.locationName}</td>
                          <td>{item.address}</td>
                          <td>{item.poc}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <h4>Routes and Maps for Relocation</h4>
            {evacuationRoutes.map((route, index) => (
              <Col key={index} xs={12} className="mt-4">
                <h4>Route Card {index + 1}</h4>
                <table className="w-full colored-table summary-table">
                  <thead>
                    <tr>
                      <th style={{ width: '18%' }}>
                        <b>Start Point Name (Agency / Site location)</b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Destination Name (Safe Haven) </b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Primary / Alternate Route </b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b> Map Reference</b>
                      </th>
                      <th style={{ width: '18%' }}>
                        <b>Directions (If Map is not available)</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {route.map(
                      (item, index) =>
                        (item.startPoint ||
                          item.destination ||
                          item.primaryAlternate ||
                          item.mapReference ||
                          item.directions) && (
                          <tr key={index}>
                            <td>{item.startPoint}</td>
                            <td>{item.destination}</td>
                            <td>{item.primaryAlternate}</td>
                            <td>{item.mapReference}</td>
                            <td>{item.directions}</td>
                          </tr>
                        )
                    )}
                    {evacuationRoutesMaps.map((route, i) => (
                      <div key={i}>
                        {route.routemap && i === index && (
                          <tr>
                            <td>
                              <b>Route Maps</b>
                            </td>
                            <td>
                              <img
                                style={{
                                  width: '300px',
                                  height: 'auto',
                                }}
                                src={route?.routemap}
                              />
                            </td>
                          </tr>
                        )}
                      </div>
                    ))}
                  </tbody>
                </table>
              </Col>
            ))}
            <hr />
            <Col xs={12} className="mt-4">
              <h4>TRANSPORTATION FOR HRE </h4>
              <h4>Road Travel </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '8%' }}>
                      <b>No.</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Type of Vehicle & Colour</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Vehicle Registration Number</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Drivers Name & Mobile</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Vehicle Owner Name & Mobile</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Number of Available Seats</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Designation / Code</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {roadTravel.map(
                    (item, index) =>
                      (item.vehicleType ||
                        item.registrationNumber ||
                        item.driversName ||
                        item.ownerName ||
                        item.seats) && (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.vehicleType}</td>
                          <td>{item.registrationNumber}</td>
                          <td>{item.driversName}</td>
                          <td>{item.ownerName}</td>
                          <td>{item.seats}</td>
                          <td>A{index + 1}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Air Travel </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '8%' }}>
                      <b>No.</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Aircraft Type</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Aircraft Number</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Operating Company & POC Mobile</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Stand-by Location</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Range</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Capacity</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Designation / Code</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {airTravel.map(
                    (item, index) =>
                      (item.aircraftType ||
                        item.aircraftNumber ||
                        item.operatingCompany ||
                        item.location ||
                        item.range ||
                        item.capacity) && (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.aircraftType}</td>
                          <td>{item.aircraftNumber}</td>
                          <td>{item.operatingCompany}</td>
                          <td>{item.location}</td>
                          <td>{item.range}</td>
                          <td>{item.capacity}</td>
                          <td>B{index + 1}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Sea Travel </h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '8%' }}>
                      <b>No.</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Vessel Type</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Vessel Number</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Operating Company & POC Mobile</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Stand-by Location</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Range</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Capacity</b>
                    </th>
                    <th style={{ width: '13%' }}>
                      <b>Designation / Code</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {seaTravel.map(
                    (item, index) =>
                      (item.vesselType ||
                        item.vesselNumber ||
                        item.operatingCompany ||
                        item.location ||
                        item.range ||
                        item.capacity) && (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.vesselType}</td>
                          <td>{item.vesselNumber}</td>
                          <td>{item.operatingCompany}</td>
                          <td>{item.location}</td>
                          <td>{item.range}</td>
                          <td>{item.capacity}</td>
                          <td>C{index + 1}</td>
                        </tr>
                      )
                  )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Emergency Contacts</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Organisation</b>
                    </th>
                    <th>
                      <b>Point of Contact (if known)</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                    <th>
                      <b>Capabilities / Comments</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts.contacts &&
                    emergencyContacts.contacts.map(
                      (contact) =>
                        (contact.organisation ||
                          contact.pointOfContact ||
                          contact.contactNumber ||
                          contact.capabilities) && (
                          <tr key={contact.id}>
                            <td>{contact.organisation}</td>
                            <td>{contact.pointOfContact}</td>
                            <td>{contact.contactNumber}</td>
                            <td>{contact.capabilities}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>Annexes</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>Annex No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Location of Annex</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Tabledata &&
                    Tabledata.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.docname}</td>
                        <td>
                          <i
                            style={{ fontSize: '20px', marginLeft: '10px' }}
                            role="button"
                            className={`la la-download me-2`}
                            onClick={() => handleDownload(item.file)}
                          />
                        </td>
                      </tr>
                    ))}
                  {annexes.annexes &&
                    annexes.annexes.map(
                      (ann, index) =>
                        (ann.documentName || ann.location) && (
                          <tr key={index}>
                            <td>{index + 7}</td>
                            <td>{ann.documentName}</td>
                            <td>{ann.location}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
