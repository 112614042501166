import axiosInstance from "./AxiosInstance";

export function getAssignment() {
  return axiosInstance.get(`v1/assignment`).then((res) => res.data);
}

export function addAssignment(assignment) {
  return axiosInstance
    .post(`v1/assignment`, assignment)
    .then((res) => res.data);
}

export function deleteAssignment(id) {
  return axiosInstance.delete(`v1/assignment/${id}`).then((res) => res.data);
}

export function editAssignment(id, assignment) {
  return axiosInstance
    .patch(`v1/assignment/${id}`, assignment)
    .then((res) => res.data);
}
