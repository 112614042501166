import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";
import { getAssesmentById } from "../../services/AssesmentService";
const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";
export default function FireSafetyPlanSummary(props) {
  const { location } = props;
  const { assesmentId } = useParams();

  const [downloadingPDF, setDownloadingPDF] = React.useState(false);
  const [downloadingPDFImage, setDownloadingPDFImage] = React.useState(false);

  const exportPdf = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDF(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;

    const maxPageHeight = 10800;
    const maxPageWidth = inputWidth; // Use the width of the PDF page

    const scaleFactorHeight = Math.min(1, maxPageHeight / inputHeight);
    const scaleFactorWidth = Math.min(1, maxPageWidth / inputWidth);

    const scaleFactor = Math.min(scaleFactorHeight, scaleFactorWidth);

    const scaledWidth = inputWidth * scaleFactor;
    const scaledHeight = inputHeight * scaleFactor;

    const pdf = new jsPDF('p', 'px', [scaledWidth, scaledHeight], true);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;

    const pages = scaledHeight / height;

    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);

    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');

      for (let i = 1; i <= pageCount; i++) {
        const pdfStartingHeight = height * (i - 1);
        pdf.addImage(
          imgData,
          'PNG',
          0,
          -pdfStartingHeight,
          scaledWidth,
          scaledHeight,
          '',
          'FAST'
        );

        if (i < pageCount) {
          pdf.addPage();
        }
      }

      setDownloadingPDF(false);
      const fileName = `Fire-Safety-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      pdf.save(`${fileName}.pdf`);
    });
  };

  const exportImage = () => {
    const { planInformation } = assesmentResponse;
    setDownloadingPDFImage(true);
    const input = document.getElementById('capture');
    const inputHeight = input.clientHeight;
    const inputWidth = input.clientWidth;
    const pdf = new jsPDF('p', 'px', [inputWidth, inputHeight]);
    const { width } = pdf.internal.pageSize;
    const { height } = pdf.internal.pageSize;
    const pageHeightInPixels = inputHeight;
    const pages = pageHeightInPixels / height;

    // const roundOff = Number(pages.toString().split('.')[1].substring(0, 1));
    const pageNo = pages;
    const pageCount = pages < 1 ? 1 : Math.trunc(pageNo);
    html2canvas(input, { useCORS: true }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      setDownloadingPDFImage(false);
      const fileName = `Fire-Safety-${
        planInformation?.agency?.value || planInformation?.agency || '-'
      }-${planInformation?.officeName || '-'}-${moment(
        assesment.assessmentDate
      ).format('DD-MM-YYYY')}`;
      link.download = `${fileName}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const [loaded, setLoaded] = React.useState(false);
  const [assesment, setAssesment] = React.useState(undefined);
  const [assesmentResponse, setAssesmentResponse] = React.useState(undefined);

  React.useEffect(async () => {
    if (assesmentId && assesmentId !== 'undefined') {
      const data = await getAssesmentById(assesmentId);
      if (data) {
        const parsedData = JSON.parse(data.assessmentResponse);
        setAssesment(data);
        setAssesmentResponse(parsedData);
      }
    } else if (assesmentId === 'undefined') {
      const data = location.state.assesment;
      const parsedData = JSON.parse(data.assessmentResponse);
      data.assesmentUser = { name: user.displayName, email: user.email };
      setAssesment(data);
      setAssesmentResponse(parsedData);
    }
    setLoaded(true);
  }, [assesmentId]);

  if (!loaded) {
    return <div>Loading...</div>;
  }

  const {
    planInformation,
    introduction,
    organisation,
    premisesInformation,
    fireHazards,
    fireDetection,
    fireSuppression,
    evacuationSystems,
    emergencyResponse,
    fireInstructions,
    alarmInstructions,
    emergencyContacts,
    briefingsSchedule,
    trainingPlan,
    drills,
    inspectionsPlan,
  } = assesmentResponse;

  return (
    <>
      <Row>
        <Col xs={6}>
          <h4>
            Assessment Id:{' '}
            {planInformation?.agency?.value || planInformation?.agency || ''}-
            {planInformation?.officeName || ''}-
            {moment(assesment.assessmentDate).format('DD-MM-YYYY') || ''}
          </h4>
        </Col>
        <Col xs={6} style={{ textAlign: 'right' }}>
          <Button onClick={exportPdf}>
            Download PDF{' '}
            {downloadingPDF && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>{' '}
          &nbsp;
          <Button onClick={exportImage}>
            Download Image
            {downloadingPDFImage && (
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </Button>
        </Col>
      </Row>
      <Row className="mt-4" id="capture">
        <Card>
          <Card.Header>
            <h4>Plan Summary</h4>
          </Card.Header>
          <Card.Body>
            <Col xs={12}>
              <h4>Plan Information</h4>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>PREMISES SECURITY PLAN (First Safety):</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Agency</td>
                    <td>
                      {planInformation?.agency?.value ||
                        planInformation?.agency}
                    </td>
                  </tr>
                  <tr>
                    <td>City & Country</td>
                    <td>{planInformation?.countryLocation}</td>
                  </tr>
                  <tr>
                    <td>Prepared by:</td>
                    <td>{planInformation?.preparedBy}</td>
                  </tr>
                  <tr>
                    <td>Version Date</td>
                    <td>{planInformation?.date}</td>
                  </tr>
                  <tr>
                    <td>Current Version::</td>
                    <td>{planInformation?.currentVersion}</td>
                  </tr>
                  <tr>
                    <td>Revised sections:</td>
                    <td>{planInformation?.revisedSection}</td>
                  </tr>
                  <tr>
                    <td>Reviewed by:</td>
                    <td>{planInformation?.reviwedBy}</td>
                  </tr>
                  <tr>
                    <td>Approved by:</td>
                    <td>{planInformation?.approvedBy}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <h4>INTRODUCTION</h4>
            <Col xs={12} className="mt-4">
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Purpose and Concept</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                    The purpose of the Fire Safety and Emergency Evacuation Plan
                    is to capture the details regarding fire risk management systems, which are collectively
                    designed to:
                    </td>
                    <td>{introduction?.purposeAgency}</td>
                  </tr>
                  <tr>
                    <td>Scope</td>
                    <td>{introduction.scopeAgency}</td>
                  </tr>
                  <tr>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <h4>ORGANISATION</h4>
            <Col xs={12}>
              <b  className="mt-4">Accountability and Responsibility </b>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Designation</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Name</b>
                    </th>
                    <th style={{ width: '15%' }}>
                      <b>Contact Number</b>
                    </th>
                    <th style={{ width: '35%' }}>
                      <b>Responsibility</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{organisation?.seniorManager}</td>
                    <td>{organisation?.seniorManagerName}</td>
                    <td>{organisation?.seniorManagerContact}</td>
                    <td>
                      Accountable for Occupational Safety and Health (OSH) in
                      the workplace, which includes fire safety.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.oshManager}</td>
                    <td>{organisation?.oshManagerName}</td>
                    <td>{organisation?.oshManagerContact}</td>
                    <td>
                      Responsible for Occupational Safety and Health (OSH) in
                      the workplace, which includes fire safety.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.fireMarshal}</td>
                    <td>{organisation?.fireMarshalName}</td>
                    <td>{organisation?.fireMarshalContact}</td>
                    <td>
                      Responsible for fire safety and emergency evacuations and
                      creates, updates and monitors adherence to the Fire Safety
                      and Emergency Evacuation Plan.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.fireWarden1}</td>
                    <td>{organisation?.fireWarden1Name}</td>
                    <td>{organisation?.fireWarden1Contact}</td>
                    <td>
                      Monitors fire related risks and incidents on the 1st
                      floor, in coordination with the OSH manager and Fire
                      Marshal and supports the Fire Marshal in various tasks.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.fireWarden2}</td>
                    <td>{organisation?.fireWarden2Name}</td>
                    <td>{organisation?.fireWarden2Contact}</td>
                    <td>
                      Monitors fire related risks and incidents on the 2nd
                      floor, in coordination with the OSH manager and Fire
                      Marshal and supports the Fire Marshal in various tasks.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.employees}</td>
                    <td>{organisation?.employeesName}</td>
                    <td>{organisation?.employeesContact}</td>
                    <td>Understand and comply with this plan.</td>
                  </tr>
                  <tr>
                    <td>{organisation?.firstAider}</td>
                    <td>{organisation?.firstAiderName}</td>
                    <td>{organisation?.firstAiderContact}</td>
                    <td>
                      Manages medical related risks and incidents at the
                      premises, in coordination with OSH manager, Fire Marshal
                      and any fire wardens.
                    </td>
                  </tr>
                  <tr>
                    <td>{organisation?.other}</td>
                    <td>{organisation?.otherName}</td>
                    <td>{organisation?.otherContact}</td>
                    <td>{organisation?.otherResponsibility}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>PREMISES INFORMATION</h4>

              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Premises description</b>
                    </td>
                    <td>{premisesInformation.premisesDescription}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Premises use</b>
                    </td>
                    <td>{premisesInformation.premisesUse}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Number of staff in premises</b>
                    </td>
                    <td>{premisesInformation.numberOfStaffInPremises}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Premises working hours</b>
                    </td>
                    <td>{premisesInformation.premisesWorkingHours}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Premises HAZMAT and location</b>
                    </td>
                    <td>{premisesInformation.premisesHazmatAndLocation}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Water storage</b>
                    </td>
                    <td>{premisesInformation.waterStorage}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Dry Risers / Wet Risers</b>
                    </td>
                    <td>{premisesInformation.risers}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Power Supply</b>
                    </td>
                    <td>{premisesInformation.powerSupply}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Back-up power supply / generators</b>
                    </td>
                    <td>{premisesInformation.backUpPower}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Smoking Areas</b>
                    </td>
                    <td>{premisesInformation.smokingAreas}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12}>
              <h4>FIRE HAZARDS </h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>No.</b>
                    </th>
                    <th>
                      <b>Document Name</b>
                    </th>
                    <th>
                      <b>Annex location (C, D, E, etc) </b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fireHazards.fireHazards &&
                    fireHazards.fireHazards.map(
                      (fireHazard, index) =>
                        (fireHazard.documentName || fireHazard.location) && (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{fireHazard.documentName}</td>
                            <td>{fireHazard.location}</td>
                          </tr>
                        )
                    )}
                </tbody>
              </table>
            </Col>
            <hr />
            <h4> FIRE DETECTION, SUPPRESSION AND EVACUATION SYSTEMS</h4>
            <Col xs={12} className="mt-4">
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>FIRE DETECTION</th>
                  </tr>
                </thead>
                <tbody>
                  <tr><td>{fireDetection.position}</td>
                  <td>                    is responsible for ensuring all fire safety systems and
                    equipment maintenance is carried out in accordance with this
                    schedule.</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Fire Detection Equipment </h4>
              {/* <p>
                The following fire detection systems are located at the
                premises.
              </p> */}
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>System / Equipment</b>
                    </th>
                    <th>
                      <b>Number and Location</b>
                    </th>
                    <th>
                      <b>Inspection frequency</b>
                    </th>
                    <th>
                      <b>Service company /persons (name and contact details)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fireDetection.fireDetection.map((item, index) => (
                    <tr key={index}>
                      <td>{item.system}</td>
                      <td>{item.inputs.numberAndLocation}</td>
                      <td>{item.inputs.inspectionFrequency}</td>
                      <td>{item.inputs.serviceCompany}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Fire Suppression Equipment </h4>
              {/* <p>
                The following fire suppression systems are located at the
                premises.
              </p> */}
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th>
                      <b>System / Equipment</b>
                    </th>
                    <th>
                      <b>Number and Location</b>
                    </th>
                    <th>
                      <b>Inspection frequency</b>
                    </th>
                    <th>
                      <b>Service company /persons (name and contact details)</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fireSuppression.fireSuppression.map((item, index) => (
                    <tr key={index}>
                      <td>{item.system}</td>
                      <td>{item.inputs.numberAndLocation}</td>
                      <td>{item.inputs.inspectionFrequency}</td>
                      <td>{item.inputs.serviceCompany}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Emergency Evacuation Supporting Systems</h4>
              {/* <p>
                The following systems are located at the premises to support an
                emergency evacuation.{' '}
              </p> */}
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <b>System</b>
                    </th>
                    <th>
                      <b>Detail</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Emergency Exits</b>
                    </td>
                    <td>{evacuationSystems.emergencyExits}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Evacuation routes to emergency exits</b>
                    </td>
                    <td>{evacuationSystems.evacuationRoutes}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Evacuation Plans / Maps</b>
                    </td>
                    <td>{evacuationSystems.evacuationPlans}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Routine Evacuation Route and Exit Inspections</b>
                    </td>
                    <td>{evacuationSystems.routineEvacuation}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Assembly Points</b>
                    </td>
                    <td>{evacuationSystems.assemblyPoints}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Fire Marshals and Fire Wardens</b>
                    </td>
                    <td>{evacuationSystems.fireMarshalsAndFireWardens}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>First Aiders</b>
                    </td>
                    <td>{evacuationSystems.firstAiders}</td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <hr />
            <h4>EMERGENCY RESPONSE AND EVACUATION PROCEDURES</h4>
            <Col xs={12} >
              <b className="mt-4">Key Emergency Response Details</b>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <b>Emergency Response </b>
                    </th>
                    <th>
                      <b>Detail</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Primary means of alerting staff to a fire</b>
                    </td>
                    <td>{emergencyResponse?.primaryAlertStaffToFire}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Secondary means of alerting staff to a fire</b>
                    </td>
                    <td>{emergencyResponse?.secondaryAlertStaffToFire}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Primary Number for Emergency Services support </b>
                    </td>
                    <td>
                      {emergencyResponse?.primaryEmergencyServicesSupport}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <b>Secondary Number for Emergency Services support</b>
                    </td>
                    <td>
                      {emergencyResponse?.secondaryEmergencyServicesSupport}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
            <h4 className="mt-4">If you discover fire or smoke</h4>
            <Col xs={12} >
              <table className="w-full colored-table summary-table half-table">
                <thead>
                </thead>
                <tbody>
                  <ul
                    className="custom-bullet"
                    style={{
                      paddingLeft: '2rem',
                      listStyle: 'none',
                    }}
                  >
                    {fireInstructions.map((instruction, index) => (
                      <tr>
                        <li key={index}>
                          {instruction}
                        </li>
                      </tr>
                    ))}
                  </ul>
                </tbody>
              </table>
            </Col>
            <h4 className="mt-4">Accountability</h4>
            <Col xs={12} >
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Accountability</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{emergencyResponse.accountability}</tr>
                </tbody>
              </table>
            </Col>
           <h4 className='mt-4'> When the fire alarm sounds in your building</h4>
            <Col xs={12} >
              <table className="w-full colored-table summary-table half-table">
                <thead>
                </thead>
                <tbody>
                  <ul
                    className="custom-bullet"
                    style={{
                      paddingLeft: '2rem',
                      listStyle: 'none',
                    }}
                  >
                    {alarmInstructions.map((instruction, index) => (
                      <tr>
                        <li key={index}>
                         {instruction}
                        </li>
                      </tr>
                    ))}
                  </ul>
                </tbody>
              </table>
            </Col>
            <h4  className="mt-4">Special considerations</h4>
            <Col xs={12}>
              <table className="w-full colored-table summary-table half-table">
                <thead>
                  <tr>
                    <th colSpan={2}>Special considerations:</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>{emergencyResponse.specialConsiderations}</tr>
                </tbody>
              </table>
            </Col>
            <Col xs={12} className="mt-4">
              <h4>Emergency Contact Details </h4>
              {/* <p>
                The following contact details for use in a fire emergency are
                updated on a regular basis and posted in the workplace for easy
                access during a fire{' '}
              </p> */}
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th>
                      <b>Organisation</b>
                    </th>
                    <th>
                      <b>Name of Point of Contact</b>
                    </th>
                    <th>
                      <b>Contact Number</b>
                    </th>
                    <th>
                      <b>Capabilities / Comments</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emergencyContacts &&
                    emergencyContacts.contacts.map((item, index) => (item.organisation ||
                      item.pointOfContact ||
                      item.contactNumber ||
                      item.capabilities ) &&(
                      <tr key={index}>
                        <td>{item.organisation}</td>
                        <td>{item.pointOfContact}</td>
                        <td>{item.contactNumber}</td>
                        <td>{item.capabilities}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Col>
            <hr />
            <h4 className="my-3">TRAINING AND DRILLS</h4>
            <Col xs={12} className="mt-4">
              <h4>Briefings Schedule</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Recipients</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Core Subjects</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Frequency</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Responsible Person</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {briefingsSchedule.map((schedule, index) =>(schedule.recipients || schedule.coreSubjects ||schedule.frequency || schedule.responsiblePerson) && (
                    <tr key={index}>
                      <td>{schedule.recipients}</td>
                      <td>{schedule.coreSubjects}</td>
                      <td>{schedule.frequency}</td>
                      <td>{schedule.responsiblePerson}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            <Col xs={12} className="mt-4">
              <h4>Training Plan</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Recipients</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Core Subjects</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Frequency</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Responsible Person</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {trainingPlan.map((tplan, index) => (
                    tplan.recipients || tplan.coreSubjects || tplan.frequency || tplan.responsiblePerson
                  ) && (
                    <tr key={index}>
                      <td>{tplan.recipients}</td>
                      <td>{tplan.coreSubjects}</td>
                      <td>{tplan.frequency}</td>
                      <td>{tplan.responsiblePerson}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            <Col xs={12} className="mt-4">
              <h4>Drills and Exercises</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr style={{ width: '25%' }}>
                    <th>
                      <b>Recipients</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Type</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Frequency</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Responsible Person</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {drills.map((drill, index) =>(drill.recipients ||drill.type || drill.frequency || drill.responsiblePerson ) && (
                    <tr key={index}>
                      <td>{drill.recipients}</td>
                      <td>{drill.type}</td>
                      <td>{drill.frequency}</td>
                      <td>{drill.responsiblePerson}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>

            <Col xs={12} className="mt-4">
              <h4>Inspections Plan</h4>
              <table className="w-full colored-table summary-table ">
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>
                      <b>Responsible Person</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Type</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Frequency</b>
                    </th>
                    <th style={{ width: '25%' }}>
                      <b>Inspection report recipient</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {inspectionsPlan.map((inspection, index) => (
                    inspection.responsiblePerson || inspection.type ||inspection.frequency ||inspection.inspectionReportRecipient ) && (
                    <tr key={index}>
                      <td>{inspection.responsiblePerson}</td>
                      <td>{inspection.type}</td>
                      <td>{inspection.frequency}</td>
                      <td>{inspection.inspectionReportRecipient}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Col>
            <hr />
            <Col xs={12} className="mt-4">
              <h4>ANNEXES</h4>
              <table className="w-full colored-table summary-table">
                <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <b>Category</b>
                    </th>
                    <th>
                      <b>Description</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <b>Annex A – Site Plan</b>
                    </td>
                    <td>
                      {' '}
                      <img
                        style={{
                          width: '300px',
                          height: 'auto',
                        }}
                        src={premisesInformation?.sitePlan}
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Annex B – Floor Plans</b>
                    </td>
                    <td>
                      {' '}
                      <img
                        style={{
                          width: '300px',
                          height: 'auto',
                        }}
                        src={premisesInformation?.floorPlan}
                      ></img>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </>
  );
}
